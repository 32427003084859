<article class="content items-list-page">
    <!-- <div class="title-search-block">
    <div class="title-block">
      <div class="row">
        <div class="col-md-6">
          <h3 class="title">
            <h3 class="title">Prestamo Activo</h3>
          </h3>
        </div>
      </div>
    </div>
  </div> -->

    <!-- <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data> -->

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast">
        <div class="row mb-2 mt-2 ">
            <div class="col-12 text-center">
                <h4>
                    <p>Estado de cuenta préstamo personal</p>
                </h4>
            </div>
        </div>

        <div class="row mb-2 mt-2 ">
            <div class="col-2">
                Préstamo:
            </div>
            <div class="col-2">
                {{ loan?.amount | currency }}
            </div>

            <div class="col-2">
                Qna inicial:
            </div>
            <div class="col-2">
                {{ loan?.fortnightStart }}
            </div>

            <div class="col-2">
                Pago quincenal:
            </div>
            <div class="col-2">
                {{ loan?.discount | currency }}
            </div>

        </div>

        <div class="row mb-2 mt-2 ">
            <div class="col-2">
                Tasa de interés:
            </div>
            <div class="col-2">
                {{ loan?.loanParameter.percentageInterest }} %
            </div>

            <div class="col-2">
                Qna final:
            </div>
            <div class="col-2">
                {{ loan?.fortnightEnd }}
            </div>

            <div class="col-2">
                Monto total del adeudo:
            </div>
            <div class="col-2">
                {{ (loan?.loanParameter.amount + loan?.loanParameter.interests + loan?.loanParameter.iva + loan?.loanParameter.lifeInsurance) | currency }}
            </div>

        </div>

        <div class="row mb-2 mt-2 ">
            <div class="col-2">
                No Hoja:
            </div>
            <div class="col-2">
                {{ loan?.loanNumber }}
            </div>

            <div class="col-2">
                N° Abonos realizados:
            </div>
            <div class="col-2">
                {{ fullPayments }}
            </div>

            <div class="col-2">
                Importe abonos realizados:
            </div>
            <div class="col-2">
                {{ amountOfPayments | currency }}
            </div>

        </div>

        <div class="row mb-2 mt-2 ">
            <div class="col-2">
                No. Cheque o transferencia:
            </div>
            <div class="col-2">
                {{ loan?.checkNumber }}
            </div>

            <div class="col-2">
                N° Abonos incompletos:
            </div>
            <div class="col-2">
                {{ incompletePayments }}
            </div>

            <div class="col-2">
                Importe abonos incompletos:
            </div>
            <div class="col-2">
                {{ amountIncompletePayments | currency }}
            </div>

        </div>

        <div class="row mb-2 mt-2 ">
            <div class="col-2">
                Plazo:
            </div>
            <div class="col-2">
                {{ loan?.loanParameter.fortnight.numberFortnight }}
            </div>

            <div class="col-2">
                N° Abonos pendientes:
            </div>
            <div class="col-2">
                {{ loan?.loanParameter.fortnight.numberFortnight - (fullPayments + incompletePayments) }}
            </div>

            <div class="col-2">
                Saldo pendiente por pagar:
            </div>
            <div class="col-2">
                {{ (loan?.loanParameter.amount + loan?.loanParameter.interests + loan?.loanParameter.iva + loan?.loanParameter.lifeInsurance) - (amountOfPayments + amountIncompletePayments) | currency }}
            </div>
        </div>
    </div>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Detalle movimientos</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="card items animated fadeIn fast" *ngIf="!loadingData && loan?.payments.length > 0">
        <table *ngIf="detailed && loan?.payments.length" class="table table-striped">
            <thead>
                <tr class="text-right">
                    <th scope="col">Año</th>
                    <th scope="col">Qna 1<br />Qna 13</th>
                    <th scope="col">Qna 2<br />Qna 14</th>
                    <th scope="col">Qna 3<br />Qna 15</th>
                    <th scope="col">Qna 4<br />Qna 16</th>
                    <th scope="col">Qna 5<br />Qna 17</th>
                    <th scope="col">Qna 6<br />Qna 18</th>
                    <th scope="col">Qna 7<br />Qna 19</th>
                    <th scope="col">Qna 8<br />Qna 20</th>
                    <th scope="col">Qna 9<br />Qna 21</th>
                    <th scope="col">Qna 10<br />Qna 22</th>
                    <th scope="col">Qna 11<br />Qna 23</th>
                    <th scope="col">Qna 12<br />Qna 24</th>
                </tr>
            </thead>

            <tbody>
                <tr class="text-right" *ngFor="let item of getPayments(loan?.payments); let i = index">
                    <td>{{ item.year }}</td>
                    <td>{{ item.fortnight1 | currency }}<br />{{ item.fortnight13 | currency }}</td>
                    <td>{{ item.fortnight2 | currency }}<br />{{ item.fortnight14 | currency }}</td>
                    <td>{{ item.fortnight3 | currency }}<br />{{ item.fortnight15 | currency }}</td>
                    <td>{{ item.fortnight4 | currency }}<br />{{ item.fortnight16 | currency }}</td>
                    <td>{{ item.fortnight5 | currency }}<br />{{ item.fortnight17 | currency }}</td>
                    <td>{{ item.fortnight6 | currency }}<br />{{ item.fortnight18 | currency }}</td>
                    <td>{{ item.fortnight7 | currency }}<br />{{ item.fortnight19 | currency }}</td>
                    <td>{{ item.fortnight8 | currency }}<br />{{ item.fortnight20 | currency }}</td>
                    <td>{{ item.fortnight9 | currency }}<br />{{ item.fortnight21 | currency }}</td>
                    <td>
                        {{ item.fortnight10 | currency }}<br />{{ item.fortnight22 | currency }}
                    </td>
                    <td>
                        {{ item.fortnight11 | currency }}<br />{{ item.fortnight23 | currency }}
                    </td>
                    <td>
                        {{ item.fortnight12 | currency }}<br />{{ item.fortnight24 | currency }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</article>