import { Component, OnInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { OpenDirective } from '../../modals/open.directive';
import { LoanService } from './services/loan.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertComponent } from '../../modals/alert.component';
import { LoanInterface } from './interfaces/loan.interface';
import { DrawService } from '../draws/services/draw.service';
import { DrawInterface } from '../draws/interfaces/draw.interface';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AccountService } from '../../account/login/services/account.service';

@Component({
  selector: 'app-loan-list',
  templateUrl: './loan-list.component.html',
  styles: []
})
export class LoanListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  drawSelected = '';
  draws: DrawInterface[] = [];
  jsonArray: any[] = [];
  pending: any[] = [];
  applications: ApplicationInterface[];
  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;

  constructor( private componentFactoryResolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute, private loanService: LoanService,
    private drawService: DrawService, public accountService: AccountService,
    private router: Router ) { }

  ngOnInit() {
    this.loadingData = true;
    this.accountService.getApplications('administration', 'loans')
      .subscribe(applications => {
        this.applications = applications;
        this.drawService.get('null')
          .subscribe(data => {
            this.draws = data;
            this.drawSelected = this.draws[0].id;
            this.activatedRoute.params.subscribe(params => {
              if (params['searchValue']) {
                this.searchValue = params['searchValue'];
                this.loadData(this.searchValue, this.drawSelected);
              } else {
                this.loadData('null', this.drawSelected);
              }
            });
          }, error => this.errorMessage(error));
      }, error => this.errorMessage(error));
  }

  errorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.message);
      this.loadingData = false;
    }
  }

  loadData(value: string, drawId: string) {
    this.pending = [];
    this.loanService.get(value, drawId)
    .subscribe(data => {
      this.jsonArray = data;
      this.loanService.havePending( drawId )
      .subscribe(pending => {
        this.pending = pending
        this.loadingData = false;
      }, 
      error => this.errorMessage(error));
    },
    error => this.errorMessage(error));
  }

  search() {
    this.loadingData = true;
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search, this.drawSelected );
  }

  deleteItem(id: string, loan: LoanInterface) {
    loan.status = 'Cancelado';
    //loan.active = false;
    this.loanService.update(id, loan).subscribe(
      success => {
        this.loadData('null', this.drawSelected);
      },
      error => this.errorMessage(error)
    );
  }

  generateURL() {
    if ( this.searchValue !== '') {
      this.router.navigate( ['administration/loans/pending', this.drawSelected] );
    } else {
      this.router.navigate( ['administration/loans/pending', this.drawSelected] );
    }
  }

  showConfirm(id: string, numero: string, name: string, loan: LoanInterface) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      AlertComponent
    );

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<AlertComponent>componentRef.instance).title = 'Alerta';
    (<AlertComponent>(componentRef.instance)).message = `Seguro que desea eliminar el prestamo ${ numero } ( ${ name } ).`;
    (<AlertComponent>componentRef.instance).open();
    (<AlertComponent>componentRef.instance).alertOutput.subscribe(
      success => {
        this.deleteItem(id, loan);
      },
      error => this.errorMessage(error)
    );
  }
}
