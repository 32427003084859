import { Component, OnInit } from '@angular/core';
import { AccountService } from '../account/login/services/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {
  loading = false;
  userName = '';
  RFC = '';
  userProfile = '';
  constructor(private accountService: AccountService ) {
    }

  logout() {
    this.loading = true;
    this.accountService.logout();
      //this.router.navigate(["/"]);
      window.location.reload();

  }

  ngOnInit() {
    this.userName = this.accountService.getUserName();
    this.userProfile = this.accountService.getUserProfile();
    this.RFC = this.accountService.getRfc();
  }

}
