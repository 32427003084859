import { Component, OnInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { OpenDirective } from '../../modals/open.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { SavingService } from './services/saving.service';
import { AlertComponent } from '../../modals/alert.component';
import { SavingInterface } from './interfeces/saving.interface';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AccountService } from '../../account/login/services/account.service';

@Component({
  selector: 'app-saving-list',
  templateUrl: './saving-list.component.html',
  styles: []
})
export class SavingListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  arrayYears: number[] = [];
  yearSelected = 0;
  jsonArray = [];
  applications: ApplicationInterface[];
  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;

  constructor( private componentFactoryResolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute, private router: Router,
    private savingService: SavingService, private accountService: AccountService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.accountService.getApplications('administration', 'savings')
      .subscribe(applications => {
        this.applications = applications;
        this.savingService.getYears()
          .subscribe(data => {
            this.arrayYears = data;
            this.yearSelected = this.arrayYears[0];
            this.activatedRoute.params.subscribe(params => {
              if (params['searchValue']) {
                this.searchValue = params['searchValue'];
                this.loadData(this.searchValue, this.yearSelected);
              } else {
                this.loadData('null', this.yearSelected);
              }
            });
          }, error => this.errorMessage(error));
      }, error => this.errorMessage(error));
  }

  errorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.message);
      this.loadingData = false;
    }
  }

  loadData(value: string, year: number) {
    this.savingService.get(value, year)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
    },
    error => this.errorMessage(error));
  }

  search() {
    this.loadingData = true;
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search, this.yearSelected );
  }

  deleteItem(id: string) {
    this.savingService.delete(id).subscribe(
      success => {
        this.loadData('null', this.yearSelected );
      },
      error => this.errorMessage(error)
    );
  }

  showConfirm(id: string, name: string) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      AlertComponent
    );

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<AlertComponent>componentRef.instance).title = 'Alerta';
    (<AlertComponent>(componentRef.instance)).message = `Seguro que desea eliminar el ahorro del maestro ( ${ name } ).`;
    (<AlertComponent>componentRef.instance).open();
    (<AlertComponent>componentRef.instance).alertOutput.subscribe(
      success => {
        this.deleteItem(id);
      },
      error => this.errorMessage(error)
    );
  }

  calculateAamount( item: SavingInterface ): number {
    return item.fortnight1 + item.fortnight2 + item.fortnight3 + item.fortnight4 + item.fortnight5 + item.fortnight6 +
      item.fortnight7 + item.fortnight8 + item.fortnight9 + item.fortnight10 + item.fortnight11 + item.fortnight12 +
      item.fortnight13 + item.fortnight14 + item.fortnight15 + item.fortnight16 + item.fortnight17 + item.fortnight18 +
      item.fortnight19 + item.fortnight20 + item.fortnight21 + item.fortnight22 + item.fortnight23 + item.fortnight24;
  }

}
