import { Component, OnInit, EventEmitter, Output } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-add-unsubscribe',
  template: `
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"><i class="fas fa-exclamation-triangle"></i> Baja</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ message }}</p>
            <div class="row form-group">
              <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                  <label class="control-label">Motivo</label>
                  <select #statusPercentage class="custom-select" (change)="statusChanged( statusPercentage.value )">
                    <option [value]="item.id" *ngFor="let item of statusArr">{{ item.name }}</option>
                  </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                  <label class="control-label">Fecha</label>
                  <input type="date" class="form-control underlined" (change)="movementDateChanged( movement.value )" #movement [value]="movementDate" >
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="ok()" >
              Si
            </button>
            <button type="button" class="btn btn-secondary" (click)="cancel()" >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class AddUnsubscribeComponent implements OnInit {
  statusArr: any[] = [{
    id: 1,
    name: 'Baja Voluntaria',
    percentage: 1.00167,
    date: ''
  }, {
    id: 2,
    name: 'Jubilación',
    percentage: 1.00208,
    date: ''
  }, {
    id: 3,
    name: 'Renuncia',
    percentage: 1.00167,
    date: ''
  }, {
    id: 4,
    name: 'Defunción',
    percentage: 1.00208,
    date: ''
  }];
  public message: string;
  statusId = 1;
  movementDate = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;

  @Output() public alertOutput = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  statusChanged(value: any): void {
    this.statusId = value;
  }

  movementDateChanged(value: any): void {
    this.movementDate = value;
  }

  open() {
    $('#myModal').modal();
  }

  ok() {
    var statusSelected = this.statusArr.find(x => x.id == this.statusId)
    statusSelected.date = this.movementDate;
    $('#myModal').modal('hide');
    this.alertOutput.emit(statusSelected);
  }

  cancel() {
    $('#myModal').modal('hide');
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }
}
