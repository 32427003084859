import { Component, OnInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { OpenDirective } from '../../modals/open.directive';
import { TeacherService } from './services/teacher.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AddUnsubscribeComponent } from './add-unsubscribe.component';
import { LowStaffService } from '../low-staffing/services/low-staff.service';
import { LowStaffInterfaces } from '../low-staffing/interfaces/low-staff.interfaces';
import { AccountService } from '../../account/login/services/account.service';
import { SavingReportService } from '../../reports/savings/service/saving-report.service';
import { TeacherInterface } from './interface/teacher.interface';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { ReentryComponent } from './reentry.component';

@Component({
  selector: 'app-teacher-list',
  templateUrl: './teacher-list.component.html',
  styles: []
})
export class TeacherListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];
  applications: ApplicationInterface[];
  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;
  lowStaff: LowStaffInterfaces = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date,
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    active: true,
    teacherId: '00000000-0000-0000-0000-000000000000',
    checkNumber: 0,
    amountSavings: 0,
    amountInterests: 0,
    amountCredit: 0,
    amount: 0,
    status: 'Pendiente',
    type: 'Baja',
    movementDate : new Date(),
    teacher: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      regionId: '00000000-0000-0000-0000-000000000000',
      rfc: 'N/A',
      name: 'N/A',
      street: 'N/A',
      number: '',
      colony: 'N/A',
      zipCode: 0,
      locality: '',
      email: '',
      admission: '',
      birthdate: '1900-01-01',
      contributionId: '00000000-0000-0000-0000-000000000000',
      gender: 'N/A',
      lastUpdated: new Date,
      userIdLastUpdated: this.accountService.getUserId(),
      identifier: 0,
      lastStatus: 'Activo',
      amountSavings: 0,
      credit: 0,
      type: '',
      teacherStatus: [],
      beneficiaries: [],
      teacherPhones: [],
      loans: [],
      savings: []
    }
  };

  constructor( private componentFactoryResolver: ComponentFactoryResolver,
    private teacherService: TeacherService, public accountService: AccountService,
    public activatedRoute: ActivatedRoute, private reportService: SavingReportService,
    private router: Router, private lowStaffService: LowStaffService ) { }

  ngOnInit() {
    this.accountService.getApplications('administration', 'teachers')
      .subscribe(applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if (params['searchValue']) {
            this.searchValue = params['searchValue'];
            this.loadData(this.searchValue);
          } else {
            this.loadData('null');
          }
        });
      }, error => this.errorMessage(error));
  }

  errorMessage(error) {
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.loadingData = false;
    }
  }

  loadData(value: string) {
    this.loadingData = true;
    this.teacherService.get(value)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
    },
    error => this.errorMessage(error));
  }

  search() {
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search );
  }

  agregar() {
    const text = this.searchValue;
    this.router.navigate( ['administration/teachers/add', '', text] );
  }

  showConfirmReentry( id: string, rfc: string, lastStatus: string ) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ReentryComponent);

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<ReentryComponent>componentRef.instance).message = `Seguro que desea reingresar el maestro ${rfc}`;
    (<ReentryComponent>componentRef.instance).open();
    (<ReentryComponent>componentRef.instance).alertOutput
      .subscribe(data => {
        this.loadingData = true;
        var type = 'Reingreso';
        if( lastStatus === 'Jubilación' ) {
          type = 'Alta Jubilación'
        }
        this.teacherService.reentry(id, type)
        .subscribe(result => {
          console.log(result);
          this.search();
          this.loadingData = false;
        });
      }, error => this.errorMessage(error));
  }

  showConfirm(id: string, rfc: string) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AddUnsubscribeComponent);

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<AddUnsubscribeComponent>componentRef.instance).message = `Seguro que desea dar de baja el maestro ${rfc}`;
    (<AddUnsubscribeComponent>componentRef.instance).open();
    (<AddUnsubscribeComponent>componentRef.instance).alertOutput
      .subscribe(statusSelected => {
        this.loadingData = true;
        this.lowStaff.teacherId = id;
        this.reportService.get( rfc )
          .subscribe((data) => {
            this.lowStaff.type = statusSelected.name;
            this.lowStaff.movementDate = new Date(statusSelected.date);
            this.calculateAmount(data, statusSelected.percentage);
            this.lowStaffService.create(this.lowStaff)
              .subscribe(request => {
                this.search();
                this.loadingData = false;
              }, err => this.errorMessage(err));
          }, error => this.errorMessage(error));
      }, error => this.errorMessage(error));
  }

  calculateAmount(data: any[], percentage: number) {
    data.forEach(year => {
      let interests = 0.00;
      const arrayYear = Object.keys(year).map(function (key) {
        return [String(key), year[key]];
      });
      for (let index = 0; index < arrayYear.length; index++) {
        if (arrayYear[index][0].slice(0, 3).toUpperCase() === 'QNA') {
          interests = (interests + arrayYear[index][1]) * percentage;
        }
      }
      year.interests = parseFloat(interests.toFixed(2)) - year.total;
      this.lowStaff.amountInterests += year.interests;
      this.lowStaff.amountSavings += year.total;
      this.lowStaff.amount = year.interests + year.total;
    });
  }
}
