import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationInterface } from '../interface/application.interface';
import { Observable } from 'rxjs/Observable';
import { PermmisionsAloneInterface } from '../../permissions/interfaces/permmisions-alone.interface';

@Injectable()
export class ApplicationService {
  private apiURL = 'api/Configuration/Applications';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }
    create( application: ApplicationInterface ): Observable<any> {
      return this.http.post<any>(this.apiURL + '/Create', application);
    }

    get( searchValue: string ) {
      return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
    }

    getById( id: string ) {
      return this.http.get<any>( this.apiURL + '/' + id );
    }

    update( id: string, application: ApplicationInterface ) {
      return this.http.put<any>( this.apiURL + '/' + id, application );
    }

    delete( id: string ) {
      return this.http.delete<any>( this.apiURL + '/' + id );
    }

    getApplicationsAccess( profileId: string ) {
      return this.http.get<any>(this.apiURL + '/Profile/' + profileId);
    }

    setPermission( permission: PermmisionsAloneInterface ) {
      return this.http.post<any>(this.apiURL + '/AddPermission/', permission);
    }

    removePermission( permission: PermmisionsAloneInterface ) {
      return this.http.post<any>(this.apiURL + '/RemovePermission/', permission);
    }
}
