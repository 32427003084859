import { Component, OnInit } from '@angular/core';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AccountService } from '../../account/login/services/account.service';
import { PaymentService } from './services/payment.service';
import { ActivatedRoute } from '@angular/router';
import { PaymentInterface } from './interfaces/payment.interface';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styles: []
})
export class PaymentListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  arrayYears: number[] = [];
  yearSelected = 0;
  jsonArray = [];
  applications: ApplicationInterface[];

  constructor( private activatedRoute: ActivatedRoute, private accountService: AccountService,
    private paymentService: PaymentService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.accountService.getApplications('administration', 'payments')
      .subscribe(applications => {
        this.applications = applications;
        this.paymentService.getYears()
          .subscribe(data => {
              this.arrayYears = data;
              this.yearSelected = this.arrayYears[0];
              this.activatedRoute.params.subscribe(params => {
                if (params['searchValue']) {
                  this.searchValue = params['searchValue'];
                  this.loadData(this.searchValue, this.yearSelected);
                } else {
                  this.loadData('null', this.yearSelected);
                }
              });
          }, error => this.setErrorMessage(error));
      }, error => this.setErrorMessage(error));
  }

  setErrorMessage(error) {
    if (error && error.error) {
      console.error(error);
      console.error(error.message);
      this.loadingData = false;
    }
  }

  loadData(value: string, year: number) {
    this.paymentService.get(value, year)
      .subscribe(data => {
        this.jsonArray = data;
        this.loadingData = false;
    },
    error => this.setErrorMessage(error));
  }

  search() {
    this.loadingData = true;
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search, this.yearSelected );
  }

  calculateAamount( item: PaymentInterface ): number {
    return item.fortnight1 + item.fortnight2 + item.fortnight3 + item.fortnight4 + item.fortnight5 + item.fortnight6 +
      item.fortnight7 + item.fortnight8 + item.fortnight9 + item.fortnight10 + item.fortnight11 + item.fortnight12 +
      item.fortnight13 + item.fortnight14 + item.fortnight15 + item.fortnight16 + item.fortnight17 + item.fortnight18 +
      item.fortnight19 + item.fortnight20 + item.fortnight21 + item.fortnight22 + item.fortnight23 + item.fortnight24;
  }

}
