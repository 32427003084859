import { Routes } from '@angular/router';
import { DrawComponent } from './draw.component';
import { DrawListComponent } from './draw-list.component';

export const DRAWS_ROUTES: Routes = [
    { path: 'add/:id', component: DrawComponent },
    { path: 'add/:id/:searchValue', component: DrawComponent },
    { path: 'edit/:id', component: DrawComponent },
    { path: 'edit/:id/:searchValue', component: DrawComponent },
    { path: '', component: DrawListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
