import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TeacherInterface } from '../interface/teacher.interface';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class TeacherService {
  private apiURL = 'api/Administration/Teachers';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    create( teacher: TeacherInterface ): Observable<any> {
      return this.http.post<any>(this.apiURL + '/Create', teacher);
    }

    get( searchValue: string ) {
      return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
    }

    getIdentifier() {
      return this.http.get<any>(this.apiURL + '/Identifier');
    }

    getById( id: string ) {
      return this.http.get<any>( this.apiURL + '/' + id );
    }

    getByIdWithSavings( id: string ) {
      return this.http.get<any>( this.apiURL + '/Savings/' + id );
    }

    update( id: string, teacher: TeacherInterface ) {
      return this.http.put<any>( this.apiURL + '/' + id, teacher  );
    }

    delete( id: string ) {
      return this.http.delete<any>( this.apiURL + '/' + id );
    }

    reentry(id: string, type: string) {
      return this.http.delete<any>( this.apiURL + '/' + id + '/' + type);
    }
}
