import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IProfile } from '../interfaces/iprofile';

@Injectable()
export class ProfilesService {
  private apiURL = 'api/Configuration/Profiles';
  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    create( profile: IProfile ) {
      return this.http.post<any>(this.apiURL + '/Create', profile);
    }

    get( searchValue: string ) {
      return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
    }

    getById( id: string ) {
      return this.http.get<any>( this.apiURL + '/' + id );
    }

    update( id: string, profile: IProfile ) {
      return this.http.put<any>( this.apiURL + '/' + id, profile );
    }

    delete( id: string ) {
      return this.http.delete<any>( this.apiURL + '/' + id );
    }
}
