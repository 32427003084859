<article class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Configuración</li>
            <li class="breadcrumb-item"><a [routerLink]="[returnPath, searchValue]" routerLinkActive="router-link-active">Módulos</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">{{ titulo }}</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="loadingData" class="alert alert-warning" role="alert">
        <i class="fas fa-sync-alt fa-spin"></i> Obteniendo información...
    </div>

    <div class="card card-block animated fadeIn fast">
        <form [formGroup]="forma" (ngSubmit)="sendForm()">
            <div class="form-group" [ngClass]="{'has-error': !forma.controls['name'].valid && forma.controls['name'].touched, 'has-success': forma.controls['name'].valid && forma.controls['name'].touched }">
                <label class="control-label"><i class="fas fa-asterisk required"></i> Nombre</label>
                <input type="text" maxlength="50" class="form-control underlined" formControlName="name">
                <span *ngIf="forma.controls['name'].touched && forma.controls['name'].errors?.required" class="has-error ">
                    El campo nombre es requerido.
                </span>
                <span *ngIf="forma.controls['name'].touched && forma.controls['name'].errors?.minlength" class="has-error ">
                    El campo nombre debe tener almenos {{ forma.controls['name'].errors.minlength.requiredLength }}
                    caracteres.
                </span>
            </div>

            <div class="form-group text-center">
                <button [disabled]="!forma.valid ||sendData" class="btn" [ngClass]="{'btn-outline-success': titulo=='Agregar', 'btn-outline-warning': titulo=='Editar' }">
                    <i [ngClass]="{ 'far fa-save': !sendData, 'fas fa-sync-alt fa-spin': sendData }"></i>
                    <span *ngIf="!sendData">Guardar</span>
                    <span *ngIf="sendData">Guardando...</span>
                </button>
            </div>

            <div class="alert alert-danger" role="alert" *ngIf="message">
                {{ message }}
            </div>
        </form>

        <div class="form-group">
            <a class="btn btn-outline-info" [routerLink]="[returnPath, searchValue]"><i class="fas fa-arrow-left"></i> Regresar</a>
        </div>
    </div>
</article>