import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserAlonInterface } from '../interfeces/user-alon.interface';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class UsersService {
  private apiURL = 'api/Configuration/Users';
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

    create( user: UserAlonInterface ): Observable<any> {
      return this.http.post<any>(this.apiURL + '/Create', user);
    }

    get( searchValue: string ) {
      return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
    }

    getById( id: string ) {
      return this.http.get<any>( this.apiURL + '/' + id );
    }

    update( id: string, user: UserAlonInterface ) {
      return this.http.put<any>( this.apiURL + '/' + id, user );
    }

    delete( id: string ) {
      return this.http.delete<any>( this.apiURL + '/' + id );
    }
}
