import { Component, OnInit, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LowStaffService } from './services/low-staff.service';
import { AlertComponent } from '../../modals/alert.component';
import { OpenDirective } from '../../modals/open.directive';
import { LowStaffInterfaces } from './interfaces/low-staff.interfaces';
declare let jsPDF;
declare var require: any;

@Component({
  selector: 'app-low-staff',
  templateUrl: './low-staff.component.html',
  styles: []
})
export class LowStaffComponent implements OnInit {
  searchValue = '';
  working = false;
  loadingData = false;
  currentPage = 1;
  itemsPerPage = 10;
  errorMessage = '';
  jsonArray: any[] = [];
  @ViewChild( OpenDirective, { static: true } ) adHost: OpenDirective;

  constructor( private activatedRoute: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private lowstaffService: LowStaffService, private router: Router ) {
  }

  ngOnInit() {
    this.loadingData = true;
    this.activatedRoute.params.subscribe(params => {
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }
      this.loadData();
    });
  }

  loadData() {
    this.lowstaffService.getPending( )
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
    },
    error => this.setErrorMessage(error));
  }

  setErrorMessage(error) {
    if (error && error.error) {
      this.errorMessage = error.message;
      this.loadingData = false;
    }
  }

  generateCheck() {
    this.generateDocument();

    this.lowstaffService.apply()
      .subscribe(response => {
        this.router.navigate( ['administration/lowstaffing', this.searchValue] );
    }, err => this.setErrorMessage(err));
  }

  generateDocument(): void {
    const doc = new jsPDF();
    doc.setFontSize(12);
    let ubicationStart = 13;
    const num2Let = require('numalet')();
    let contador = 1;
    this.jsonArray.forEach(element => {
      const amount = element.amountSavings + element.amountInterests - element.amountCredit;
      doc.text(`${ this.formatDate(new Date().getDate()) }/${ this.formatDate(new Date().getMonth()) }/${ new Date().getFullYear() }`,
      doc.internal.pageSize.width - 60, ubicationStart);
      doc.text(element.teacher.name, 58 , ubicationStart + 15);
      doc.text(amount.toString(), doc.internal.pageSize.width - 30 , ubicationStart + 15);
      doc.text(num2Let(amount), 58 , ubicationStart + 25);
      // doc.text('$$$$', 58 , ubicationStart + 25);
      contador += 1;
      if ( contador === 5 ) {
        contador = 1;
        ubicationStart = 13;
        doc.addPage();
      } else {
        ubicationStart += 70;
      }
    });

    doc.save(`Cheques.pdf`);
  }

  showConfirm( id: string, lowstaff: LowStaffInterfaces ) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      AlertComponent
    );

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<AlertComponent>componentRef.instance).title = 'Alerta';
    (<AlertComponent>componentRef.instance).message = `Seguro que desea remover al maestro ${ lowstaff.teacher.rfc }.`;
    (<AlertComponent>componentRef.instance).open();
    (<AlertComponent>componentRef.instance).alertOutput.subscribe(
      success => {
        this.deleteItem(id);
      },
      error => this.setErrorMessage(error)
    );
  }

  deleteItem(id: string) {
    this.lowstaffService.delete(id).subscribe(
      success => {
        this.loadData();
      },
      error => this.setErrorMessage(error)
    );
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

}
