<article class="content items-list-page">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page">Administración</li>
      <li class="breadcrumb-item"><a [routerLink]="[returnPath, searchValue]" routerLinkActive="router-link-active">Prestamos</a></li>
    </ol>
  </nav>

  <div class="title-search-block">
    <div class="title-block">
        <div class="row form-group">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <h3 class="title">
                    Pendientes <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                </h3>
            </div>
        </div>
    </div>
  </div>

  <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

  <app-loading-data *ngIf="loadingData"></app-loading-data>

  <div class="card card-block animated fadeIn fast">
    <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
      <ul class="item-list striped">
        <li class="item item-list-header">
          <div class="row">
            <div class="item-col item-col-header item-col-header">
              <div class="no-overflow">
                <span>Nombre</span>
              </div>
            </div>
            <div class="item-col item-col-header item-col-header">
              <div>
                <span>R.F.C.</span>
              </div>
            </div>
            <div class="item-col item-col-header">
              <div>
                <span>Prestamo</span>
              </div>
            </div>
            <div class="item-col item-col-header">
              <div>
                <span>Monto</span>
              </div>
            </div>
            <div class="item-col item-col-header fixed item-col-actions-dropdown"></div>
          </div>
        </li>
  
        <li class="item" *ngFor="let item of jsonArray; let i = index">
          <div class="item-row">
            <div class="item-col fixed pull-left item-col-title">
              <div class="item-heading">Nombre</div>
              <div>
                <span>{{ item.teacher.name }}</span>
              </div>
            </div>
  
            <div class="item-col item-col-item">
              <div class="item-heading">R.F.C.</div>
              <div>
                <span>{{ item.teacher.rfc }}</span>
              </div>
            </div>
  
            <div class="item-col item-col-item text-right">
              <div class="item-heading">Prestamo</div>
              <div> {{ item.loanNumber }} </div>
            </div>
  
            <div class="item-col item-col-sales">
              <div class="item-heading">Monto</div>
              <div> {{ item.amount | currency }} </div>
            </div>
  
            <div class="item-col fixed item-col-actions-dropdown">
              <div class="item-list-actions text-center">
                <span *ngIf="accountService.havePermission(applications, 'Prestamos Eliminar')">
                  <a class="required" (click)="showConfirm( item )" href="" data-toggle="modal">
                    <i class="far fa-trash-alt"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="form-group text-center">
        <button class="btn btn-outline-warning" (click)="apply()" ><i class="far fa-file-pdf"></i>
          Generar PDF</button>
      </div>
  
    <div class="form-group">
      <a class="btn btn-outline-info " [routerLink]="[returnPath, searchValue]"><i class="fas fa-arrow-left "></i>
        Regresar</a>
    </div>
  </div>

<ng-template appOpen></ng-template>

</article>
