import { Component, OnInit } from '@angular/core';
import { IProfile } from '../profiles/interfaces/iprofile';
import { ModulesService } from '../modules/services/modules.service';

import { UntypedFormGroup, FormControl, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { PermissionClientInterface } from './interfaces/permission-client.interface';
import { ModulesInterface } from '../modules/interfaces/modules.interface';
import { NavMenuComponent } from '../../nav-menu/nav-menu.component';
import { ApplicationService } from '../applications/services/application.service';
import { PermmisionsAloneInterface } from './interfaces/permmisions-alone.interface';
import { AccountService } from '../../account/login/services/account.service';
import { ProfilesService } from '../profiles/services/profiles.service';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styles: []
})
export class PermissionsComponent implements OnInit {
  loadingData = false;
  message: string;
  profiles: IProfile[];
  modules: ModulesInterface[];
  forma: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private modulesService: ModulesService,
    private profilesServices: ProfilesService, private applicationService: ApplicationService,
    private accountService: AccountService ) {
  }

  ngOnInit() {
    this.loadingData = true;
    this.forma = this.formBuilder.group({
      profileId: ['00000000-0000-0000-0000-000000000000'],
      applications: this.formBuilder.array([])
    });

    const applications = this.forma.get('applications') as UntypedFormArray;

    this.modulesService.getIncludeApplication('null')
      .subscribe(data => {
        data.forEach(module => {
          module.applications.forEach(app => {
            const application = this.formBuilder.group({
              id: app.id,
              moduleId: app.moduleId,
              name: app.name,
              haveAccess: false
            });
            applications.push(application);
          });
        });
        this.modules = data;
      }, error => this.errorMessage(error));

    this.profilesServices.get('null')
      .subscribe(data => {
        this.profiles = data;
      }, error => this.errorMessage(error));

    this.loadingData = false;
  }

  errorMessage(error) {
    console.log(error);
    if (error && error.error) {
      this.message = error.error[''][0];
      this.loadingData = false;
      console.clear();
    }
  }

  changeProfile(event: Event): void {
    this.loadingData = true;
    const value: string = (<HTMLSelectElement>event.srcElement).value;
    this.clearSeleccion();
    if (value !== '00000000-0000-0000-0000-000000000000') {
      this.applicationService.getApplicationsAccess(value)
        .subscribe(applications => {
          applications.forEach(application => {
            this.setAcces(application.id);
          });
          this.loadingData = false;
        }
          , error => this.errorMessage(error));
    } else {
      this.loadingData = false;
    }
  }

  clearSeleccion() {
    this.message = '';
    const applicationsForms = this.forma.get('applications') as UntypedFormArray;
    applicationsForms.controls.forEach(application => {
      application.get('haveAccess').setValue(false);
    });
  }

  setAcces(id: string) {
    const applications = this.forma.get('applications') as UntypedFormArray;
    applications.controls.forEach(application => {
      if (application.get('id').value === id) {
        application.get('haveAccess').setValue(true);
      }
    });
  }

  setPermission(checked: boolean, id: string): void {
    const permission: PermmisionsAloneInterface = {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      applicationId: id,
      profileId: this.forma.controls['profileId'].value
    };

    if ( checked ) {
      this.applicationService.setPermission(permission)
        .subscribe(success => {}, error => this.errorMessage(error));
    } else {
      this.applicationService.removePermission(permission)
        .subscribe(success => {}, error => this.errorMessage(error));
    }
  }

}
