import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modules',
  template:  `
  <router-outlet></router-outlet>
  `,
  styles: []
})
export class ModulesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
