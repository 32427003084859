import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { TeacherInterface } from './interface/teacher.interface';
import { TeacherService } from './services/teacher.service';
import { AccountService } from '../../account/login/services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PhoneTypeService } from '../../catalogs/phone-types/services/phone-type.service';
import { PhoneTypeInterface } from '../../catalogs/phone-types/interfaces/phone-type.interface';
import { RegionInterface } from '../../catalogs/regions/interfaces/region.interface';
import { RegionService } from '../../catalogs/regions/services/region.service';
import { RelationshipInterface } from '../../catalogs/relationships/interfaces/relationship.interface';
import { RelationshipService } from '../../catalogs/relationships/services/relationship.service';
import { ContributionService } from '../../catalogs/contributions/services/contribution.service';
import { ContributionInterface } from '../../catalogs/contributions/interfaces/contribution.interface';

@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styles: []
})
export class TeacherComponent implements OnInit {
  title = 'Agregar';
  returnPath = '../../../';
  forma: UntypedFormGroup;
  message: string;
  loadingData = false;
  sendData = false;
  searchValue = '';
  phoneTypes: PhoneTypeInterface[] = [];
  relationships: RelationshipInterface[] = [];
  regions: RegionInterface[] = [];
  contributions: ContributionInterface[] = [];
  teacher: TeacherInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    rfc: '',
    name: '',
    street: '',
    number: '',
    colony: '',
    zipCode: 0,
    locality: '',
    email: '',
    admission: '',
    birthdate: '',
    contributionId: '00000000-0000-0000-0000-000000000000',
    gender: '',
    identifier: 0,
    amountSavings: 0,
    lastStatus: 'Activo',
    credit: 0,
    lastUpdated: new Date,
    type: '',
    userIdLastUpdated: this.accountService.getUserId(),
    regionId: '00000000-0000-0000-0000-000000000000',
    teacherStatus: [],
    beneficiaries: [],
    teacherPhones: [],
    loans: [],
    savings: []
  };

  constructor(
    private teacherService: TeacherService,
    private accountService: AccountService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private phoneTypeService: PhoneTypeService,
    private formBuilder: UntypedFormBuilder,
    private regionService: RegionService,
    private relationshipService: RelationshipService,
    private contributionService: ContributionService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.loadingData = true;
      this.buildForm();

      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }

      this.phoneTypeService.get('null').subscribe(
        phones => {
          this.phoneTypes = phones;
          this.regionService.get('null').subscribe(
            regions => {
              this.regions = regions;
              this.relationshipService.get('null').subscribe(
                relationships => {
                  this.relationships = relationships;
                  this.contributionService.get('null').subscribe(contributions => {
                    this.contributions = contributions;
                    if (params['id']) {
                      this.title = 'Editar';
                      this.teacher.id = params['id'];
                      this.teacherService.getById(params['id']).subscribe(
                        teacher => {
                          this.returnPath = '../../';
                          this.teacher = Object.assign({}, teacher);
                          if ( this.teacher.admission ) {
                            this.teacher.admission = this.teacher.admission.substr(0, 10);
                          }
                          this.teacher.birthdate = this.teacher.birthdate.substr(0, 10);
                          this.forma.patchValue(this.teacher);
                          this.setTeacherPhones();
                          this.setBeneficiaries();
                          this.loadingData = false;
                        },
                        error => this.errorMessage(error)
                      );
                    } else {
                      this.teacherService.getIdentifier().subscribe( identifier => {
                        this.teacher.identifier = identifier;
                        this.forma.patchValue(this.teacher);
                        this.loadingData = false;
                      } , error => this.errorMessage(error));
                    }
                  }, error => this.errorMessage(error));
                }, error => this.errorMessage(error)
              );
            }, error => this.errorMessage(error)
          );
        }, error => this.errorMessage(error)
      );
    });
  }

  setFormatDate( fecha: string ): string {
    return fecha.substr(0, 10);
  }

  setTeacherPhones() {
    const phonesArr = this.forma.get('teacherPhones') as UntypedFormArray;
    this.teacher.teacherPhones.forEach(phone => {
      const phoneToAdd = this.formBuilder.group({
        id: phone.id,
        userId: phone.userId,
        date: phone.date,
        active: phone.active,
        teacherId: phone.teacherId,
        phoneTypeId: [phone.phoneTypeId, Validators.required],
        phone: [phone.phone, Validators.minLength(10)]
      });
      phonesArr.push(phoneToAdd);
    });
  }

  setBeneficiaries() {
    const phonesArr = this.forma.get('beneficiaries') as UntypedFormArray;
    this.teacher.beneficiaries.forEach(beneficiary => {
      const phoneToAdd = this.formBuilder.group({
        id: beneficiary.id,
        userId: beneficiary.userId,
        date: beneficiary.date,
        active: beneficiary.active,
        lastUpdated: beneficiary.lastUpdated,
        userIdLastUpdated: beneficiary.userIdLastUpdated,
        teacherId: beneficiary.teacherId,
        relationshipId: beneficiary.relationshipId,
        name: beneficiary.name,
        percentage: beneficiary.percentage
      });
      phonesArr.push(phoneToAdd);
    });
  }

  buildForm() {
    this.forma = this.formBuilder.group({
      id: [''],
      userId: [''],
      date: [''],
      active: [''],
      name: ['', [Validators.required, Validators.minLength(3)]],
      regionId: ['', [Validators.required]],
      rfc: ['', [Validators.required, Validators.minLength(3)]],
      street: ['', [Validators.required, Validators.minLength(3)]],
      number: ['', [Validators.required]],
      colony: ['', [Validators.required, Validators.minLength(3)]],
      zipCode: ['', [Validators.required, Validators.minLength(5)]],
      locality: ['', [Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      admission: [''],
      birthdate: ['', [Validators.required]],
      contributionId: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      identifier: ['', [Validators.required]],
      amountSavings: [ '' ],
      lastStatus: [''],
      credit: [ '' ],
      type: ['', [Validators.required]],
      teacherStatus: [],
      teacherPhones: this.formBuilder.array([]),
      loans: [],
      savings: [],
      beneficiaries: this.formBuilder.array([]),
    });
  }

  get teacherPhones() {
    const arr = this.forma.get('teacherPhones') as UntypedFormArray;
    return arr;
  }

  sendForm() {
    this.sendData = true;
    this.teacher = Object.assign({}, this.forma.value);
    this.teacher.date = new Date();
    if (this.title === 'Agregar') {
      this.teacherService
        .create(this.teacher)
        .subscribe(
          success => {
            this.router.navigate(['administration/teachers', this.searchValue]);
          }, error => this.errorMessage(error)
        );
    } else {
      this.teacher.userIdLastUpdated = this.accountService.getUserId();
      this.teacherService
        .update( this.teacher.id, this.teacher)
        .subscribe(
          success =>
            this.router.navigate(['administration/teachers', this.searchValue]),
          error => this.errorMessage(error)
        );
    }
  }

  addPhone() {
    const phonesArr = this.forma.get('teacherPhones') as UntypedFormArray;
    const phoneFG = this.BuildPhone();
    phonesArr.push(phoneFG);
  }

  addBeneficiary() {
    const beneficiariesArr = this.forma.get('beneficiaries') as UntypedFormArray;
    const beneficiariesFG = this.BuildBeneficiary();
    beneficiariesArr.push(beneficiariesFG);
  }

  BuildBeneficiary() {
    return this.formBuilder.group({
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      lastUpdated: new Date(),
      userIdlastUpdated: this.accountService.getUserId(),
      teacherId: '00000000-0000-0000-0000-000000000000',
      relationshipId: ['', Validators.required],
      name: ['', Validators.minLength(10)],
      percentage: ['', Validators.required]
    });
  }

  BuildPhone() {
    return this.formBuilder.group({
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      teacherId: '00000000-0000-0000-0000-000000000000',
      lastUpdated: new Date(),
      userIdlastUpdated: this.accountService.getUserId(),
      phoneTypeId: ['', Validators.required],
      phone: ['', Validators.minLength(10)]
    });
  }

  deletePhone(index: number) {
    const phonesArr = this.forma.get('teacherPhones') as UntypedFormArray;
    const phoneDelete = phonesArr.at(index) as UntypedFormGroup;
    if (phoneDelete.controls['id'].value !== '00000000-0000-0000-0000-000000000000') {
      phoneDelete.controls['lastUpdated'].patchValue(new Date());
      phoneDelete.controls['userIdlastUpdated'].patchValue(this.accountService.getUserId());
      phoneDelete.controls['active'].patchValue(false);
    } else {
      phonesArr.removeAt(index);
    }
  }

  deleteBeneficiary(index: number) {
    const beneficiariesArr = this.forma.get('beneficiaries') as UntypedFormArray;
    const beneficiaryDelete = beneficiariesArr.at(index) as UntypedFormGroup;
    if (beneficiaryDelete.controls['id'].value !== '00000000-0000-0000-0000-000000000000') {
      beneficiaryDelete.controls['lastUpdated'].patchValue(new Date());
      beneficiaryDelete.controls['userIdlastUpdated'].patchValue(this.accountService.getUserId());
      beneficiaryDelete.controls['active'].patchValue(false);
    } else {
      beneficiariesArr.removeAt(index);
    }
  }

  errorMessage( error ) {
    if (error && error.error) {
      console.log(error);
      this.message = error.message;
      this.sendData = false;
    }
  }
}
