import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FinancialLiquidityService {
  private apiURL = 'api/Administration/FinancialLiquidity';
  constructor( private http: HttpClient ) { }

    getLastTwoYears( year: number ) {
      return this.http.get<any>(this.apiURL + '/LastYears');
    }
}
