<header class="header">
    <div class="header-block header-block-collapse d-lg-none d-xl-none">
        <button class="collapse-btn" id="sidebar-collapse-btn">
            <i class="fas fa-bars"></i>
        </button>
    </div>

    <div class="header-block header-block-search">
        <form role="search">
            <div class="input-container">
                <h4><b>{{ userName }}</b> <small>({{ RFC }})</small></h4>
            </div>
        </form>
    </div>

    <div class="header-block header-block-nav">
        <button [disabled]="loading" class="btn btn-outline-danger" (click)="logout()">
          <i class="fas" [ngClass]="{ 'fa-power-off': !loading, 'fa-sync-alt fa-spin':loading }" ></i> Salir </button>
    </div>
</header>