import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class BulkLoadService {
  private apiURL = 'api/Administration/BulkLoad';

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  uploadFiles(formData: FormData): Observable<any> {
    return this.http.post<any>(this.apiURL + '/ProcessSavingsFiles', formData);
  }

  allowReport() {
    return this.http.get<any>(this.apiURL + '/AllowReport');
  }

  getDataReport() {
    return this.http.get<any>(this.apiURL + '/GetDataReport');
  }
}
