import { Routes } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { ProfileListComponent } from './profile-list.component';

export const PROFILES_ROUTES: Routes = [
    { path: 'profile', component: ProfileComponent },
    { path: 'profile/:id', component: ProfileComponent },
    { path: 'profile/:id/:searchValue', component: ProfileComponent },
    { path: '', component: ProfileListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
