import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, Validators, UntypedFormControl } from "@angular/forms";
import { AccountService } from "./services/account.service";
import { Router } from "@angular/router";
import { IUserInfo } from "./interfaces/iUserInfo";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styles: []
})
export class LoginComponent implements OnInit {
  forma: UntypedFormGroup;
  registerForm: UntypedFormGroup;
  recoveryForm: UntypedFormGroup;
  passwordRecoveryForm: UntypedFormGroup;
  loginModel: IUserInfo = {
    email: "",
    password: ""
  };
  registerModel: IUserInfo = {
    password: "",
    email: "",
  };
  recoveryModel: IUserInfo = {
    email: "",
  };
  passwordRecoveryModel : IUserInfo = {
    password: "",
    confirmPassword: ''
  };
  message: string;
  loading = false;
  isRegister: boolean = false;
  isRecovery: boolean = false;
  isRecoveryPassword: boolean = false;
  constructor(
    private accountService:
    AccountService,
    private router: Router
  ) {
    const url = window.location.pathname;
    this.isRecoveryPassword = /\password/.test(url);
    if(this.isRecoveryPassword) this.setPasswordrecoveryForm();
  }

  ngOnInit() {
    this.forma = new UntypedFormGroup({
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(6)
      ]),
      password: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(3)
      ])
    });

    this.forma.setValue(this.loginModel);
    this.setRegisterForm();
    this.setRecoveryForm();
    this.setPasswordrecoveryForm();
  }

  validateCredentials() {
    this.loading = true;
    const model: IUserInfo = this.isRegister ? Object.assign({}, this.registerForm.value) : Object.assign({}, this.forma.value);
    console.log(`Esta registrado ${ this.isRegister }`);
    console.log( model );
    if (this.isRegister) {
      this.accountService.register(model).subscribe(
        response => {
          this.loading = false;
          this.loginModel.username = response.result.username;
          this.isRegister = false;
          delete model.confirmPassword;
          this.accountService.login(model).subscribe(
            token => {console.log(token);this.toReceiveToken(token)},
          error => this.errorMessage(error)
          )
        },
        error => this.errorMessage(error)
      );
    }
    else if(this.isRecoveryPassword) {
      const model = this.passwordRecoveryForm.value;
      model.token = this.router.url.split('/')[this.router.url.split('/').length - 1];
      this.accountService.updatePassword(model).subscribe(resp => {
        console.log(resp);
        this.message = resp.msg;
        // this.ngFlashMessageService.showFlashMessage({
        //   dismissible: false,
        //   messages: [resp.msg],
        //   type: 'info',
        //   timeout: 5000
        // });
        this.isRecoveryPassword = false;
        this.loading = false;
        this.router.navigate(["/home"]);
      }, error => console.log(error));
      console.log(model);
    }
    else {
      this.accountService
        .login(model)
        .subscribe(
          token => {console.log(token);this.toReceiveToken(token)},
          error => this.errorMessage(error)
        );
    }
  }

  toReceiveToken(token) {
    localStorage.setItem("token", token.token);
    localStorage.setItem("tokenExpiration", token.expiration);
    const userId = this.accountService.getUserId();
    this.router.navigate([""]);
    this.loading = false;
  }

  errorMessage(error) {
    if (error && error.error) {
      console.log(error);
      this.message = error.error.msg || error.error || "Error";
      this.loading = false;
    }
  }
  private setRegisterForm(){
    this.registerForm = new UntypedFormGroup({

      password: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(3)
      ]),
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.email
      ]),
    });
    this.registerForm.setValue(this.registerModel);
  }

  private setRecoveryForm(){
    this.recoveryForm = new UntypedFormGroup({
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.email
      ]),
    });
    this.registerForm.setValue(this.recoveryModel);
  }

  private setPasswordrecoveryForm(){
    this.passwordRecoveryForm = new UntypedFormGroup({
      password: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(3)
      ]),
      confirmPassword: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(3)
      ]),
     }//,(rfm: FormGroup) => {
    //   return rfm.controls['password'].value === rfm.controls['confirmPassword'].value ? null : {'mismatch': true};
    // }
    );
    this.passwordRecoveryForm.setValue(this.passwordRecoveryModel);
  }

  register() {
    this.isRegister = !this.isRegister;
    //this.setRegisterForm();
  }
  Acceder() {
    this.isRegister = !this.isRegister;
  }
  cancelar() {
    this.isRecovery = !this.isRecovery;
  }
  goToRecovery(){
    this.isRecovery = !this.isRecovery;
  }
  recovery(){
    this.loading = true;
    var value = this.recoveryForm.value;
    // this.accountService.setRecovery();
    this.accountService.recovery(value)
    .subscribe(resp => {
      console.log(resp)
      this.loading = false;
      this.message = resp.msg;
      // this.ngFlashMessageService.showFlashMessage({
      //   dismissible: false,
      //   messages: [resp.msg],
      //   type: 'info'
      // });
    }, error => console.log(error));
  }
}
