import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AccountService } from '../../account/login/services/account.service';
import { LoanInterface } from './interfaces/loan.interface';
import { DrawService } from '../draws/services/draw.service';
import { TeacherService } from '../teachers/services/teacher.service';
import { LoanService } from './services/loan.service';
import { LoanParameterService } from '../../catalogs/loan-parameter/services/loan-parameter.service';
import { LoanParameterInterface } from '../../catalogs/loan-parameter/interfaces/loan-parameter.interface';
import { RegionService } from '../../catalogs/regions/services/region.service';
import { RegionInterface } from '../../catalogs/regions/interfaces/region.interface';
import { FinancialLiquidityService } from '../financial-liquidity/services/financial-liquidity.service';
import { FinancialLiquidityInterface } from '../financial-liquidity/interfaces/financial-liquidity.interface';

@Component({
  selector: 'app-loan',
  templateUrl: './loan.component.html',
  styles: []
})
export class LoanComponent implements OnInit {
  title = 'Agregar';
  haveAdmission = false;
  editing = false;
  returnPath = '../../';
  forma: UntypedFormGroup;
  message: string;
  LoadingData = false;
  sendingData = false;
  searchValue = '';
  loanParameters: LoanParameterInterface[] = [];
  fortnightsStart: string[] = [];
  fortnightsEnd: string[] = [];
  numberFortnights: number = 0;
  regions: RegionInterface[] = [];
  financialLiquidity: FinancialLiquidityInterface[] = [];
  loan: LoanInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    regionId: '00000000-0000-0000-0000-000000000000',
    date: new Date(),
    active: true,
    drawId: '',
    teacherId: '',
    LoanParameterId: '',
    individualAccount: '',
    checkNumber: '0',
    amount: 0,
    discount: 0,
    interests: 0,
    comment: '',
    loanNumber: '',
    fortnightStart: '',
    fortnightEnd: '',
    status: '',
    openingBonus: 0,
    remainingFortnightly: 0,
    warranty: 0,
    applied: false,
    haveFinancialLiquidity: false,
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    draw: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: true,
      name: 'N/A',
      openDate: new Date(),
      closeDate: new Date(),
      status: '',
      lastUpdated: new Date,
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      loans: []
    },
    teacher: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      regionId: '00000000-0000-0000-0000-000000000000',
      rfc: '',
      name: '',
      street: '',
      number: '',
      colony: '',
      zipCode: 0,
      locality: '',
      email: '',
      admission: '',
      birthdate: '',
      contributionId: '00000000-0000-0000-0000-000000000000',
      gender: '',
      lastStatus: 'Activo',
      lastUpdated: new Date,
      userIdLastUpdated: this.accountService.getUserId(),
      identifier: 0,
      amountSavings: 0,
      credit: 0,
      type: '',
      teacherStatus: [],
      beneficiaries: [],
      teacherPhones: [],
      loans: [],
      savings: []
    },
    region: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date,
      active: true,
      lastUpdated: new Date,
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: 'N/A'
    }
  };
  indexFortnightsStart = 0;

  constructor( private accountService: AccountService, private formBuilder: UntypedFormBuilder,
    private router: Router, private activatedRoute: ActivatedRoute,
    private drawService: DrawService, private loanParameterService: LoanParameterService,
    private teacherService: TeacherService, private loanService: LoanService,
    private regionService: RegionService, private financialLiquidityService: FinancialLiquidityService ) {
  }

  buildForm(): void {
    this.forma = this.formBuilder.group({
      drawId: ['', [Validators.required]],
      LoanParameterId: ['', [Validators.required]],
      individualAccount: ['', [Validators.required]],
      checkNumber: [''],
      amount: ['', [Validators.required]],
      discount: ['', [Validators.required]],
      interests: ['', [Validators.required]],
      comment: [''],
      loanNumber: ['', [Validators.required]],
      fortnightStart: ['', [Validators.required]],
      fortnightEnd: ['', [Validators.required]],
      status: ['', [Validators.required]],
      regionId: ['', [Validators.required]],
      teacherAdmission: ['', [Validators.required]],
      haveFinancialLiquidity: ['']
    });
  }

  ngOnInit() {
    this.LoadingData = true;
    this.buildForm();

    this.drawService.getOpen()
      .subscribe(
        draw => {
          if (draw) {
            this.loan.draw = draw;
            this.loan.drawId = draw.id;
            this.loanParameterService.get('null')
              .subscribe(
                parameters => {
                  this.loanParameters = parameters;
                  this.regionService.get('null')
                    .subscribe(
                      regions => {
                        this.regions = regions;
                        this.fortnightsStart = this.getFortnights( 5 );
                        this.fortnightsEnd = this.getFortnights( 53);
                        this.financialLiquidityService.getLastTwoYears(new Date().getFullYear())
                          .subscribe( financialLiquidity => {
                            this.financialLiquidity = financialLiquidity;
                            this.activatedRoute.params.subscribe(params => {
                              if (params['searchValue']) {
                                this.searchValue = params['searchValue'];
                              }

                              if (params['id']) {
                                this.title = 'Editar';
                                this.editing = true;
                                this.loanService.getById(params['id'])
                                  .subscribe(data => {
                                    this.loan = data;
                                    this.loan.teacher = data.teacher;
                                    if ( this.loan.teacher.admission ) {
                                      this.haveAdmission = true;
                                    }
                                    this.setValues(this.loan);
                                    this.LoadingData = false;
                                  }, error => this.errorMessage(error));
                              } else if (params['teacher']) {
                                this.loan.fortnightStart = this.fortnightsStart[0];
                                this.loan.teacherId = params['teacher'];
                                this.teacherService.getById(params['teacher'])
                                  .subscribe(teacher => {
                                    this.loan.regionId = teacher.regionId;
                                    this.loan.teacher = teacher;
                                    if ( this.loan.teacher.admission ) {
                                      this.haveAdmission = true;
                                    }
                                    this.setValues(this.loan);
                                    this.LoadingData = false;
                                    // this.forma.setValue(this.loan);
                                  }, error => this.errorMessage(error));
                              }
                            });
                          }, error => this.errorMessage(error));
                      }, error => this.errorMessage(error));
                }, error => this.errorMessage(error));
          } else {
            this.message = 'No se encuentra ningún sorteo abierto.';
            this.LoadingData = false;
          }
        },
        error => this.errorMessage(error)
      );
  }

  setValues( loan: LoanInterface ): void {
    this.forma.controls['drawId'].patchValue( loan.drawId );
    this.forma.controls['LoanParameterId'].patchValue( loan.LoanParameterId );
    this.forma.controls['individualAccount'].patchValue( loan.individualAccount );
    this.forma.controls['checkNumber'].patchValue( loan.checkNumber );
    this.forma.controls['amount'].patchValue( loan.amount );
    this.forma.controls['discount'].patchValue( loan.discount );
    this.forma.controls['interests'].patchValue( loan.interests );
    this.forma.controls['comment'].patchValue( loan.comment );
    this.forma.controls['loanNumber'].patchValue( loan.loanNumber );
    this.forma.controls['fortnightStart'].patchValue( loan.fortnightStart );
    this.forma.controls['fortnightEnd'].patchValue( loan.fortnightEnd );
    this.forma.controls['status'].patchValue( loan.status );
    this.forma.controls['regionId'].patchValue( loan.teacher.regionId );
    this.forma.controls['teacherAdmission'].patchValue( loan.teacher.admission );
    this.forma.controls['haveFinancialLiquidity'].patchValue( loan.haveFinancialLiquidity );
  }

  setFormatCheck(value: string): string {
    let checkWithFormat = value.toString();
    while (checkWithFormat.length < 7) {
      checkWithFormat = `0${ checkWithFormat }`;
    }
    return checkWithFormat;
  }

  sendForm(): void {
    this.sendingData = true;
    this.getValues(this.loan);
    this.loan.date = new Date();
    if ( this.haveAdmission ) {
      this.loanService.create(this.loan)
        .subscribe(() => {
          this.router.navigate(['administration/loans', this.searchValue]);
        }, error => this.errorMessage(error));
    } else {
      this.teacherService.update(this.loan.teacherId, this.loan.teacher)
        .subscribe( () => {
          this.loanService.create(this.loan)
            .subscribe(() => {
              this.router.navigate(['administration/loans', this.searchValue]);
            }, error => this.errorMessage(error));
        }, error => this.errorMessage(error));
    }
  }

  getValues( loan: LoanInterface ) {
    loan.drawId = this.forma.controls['drawId'].value;
    loan.LoanParameterId = this.forma.controls['LoanParameterId'].value;
    loan.individualAccount = this.forma.controls['individualAccount'].value;
    loan.checkNumber = this.forma.controls['checkNumber'].value;
    loan.amount = this.forma.controls['amount'].value;
    loan.discount = this.forma.controls['discount'].value;
    loan.interests = this.forma.controls['interests'].value;
    loan.comment = this.forma.controls['comment'].value;
    loan.loanNumber = this.forma.controls['loanNumber'].value;
    loan.fortnightStart = this.forma.controls['fortnightStart'].value;
    loan.fortnightEnd = this.forma.controls['fortnightEnd'].value;
    loan.status = this.forma.controls['status'].value;
    loan.regionId = this.forma.controls['regionId'].value;
    loan.haveFinancialLiquidity = this.forma.controls['haveFinancialLiquidity'].value;
  }

  getFortnights(numberFortnights: number): string[] {
    const arrFortnight = [];
    let counter = 1;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    let year = date.getFullYear();
    do {
      if ( date >= new Date() ) {
        if(numberFortnight !== 24) {
          arrFortnight.push( date.getFullYear().toString() + '-' + this.setFormatDay(numberFortnight) );
        } else {
          arrFortnight.push( (date.getFullYear() - 1 ).toString() + '-' + this.setFormatDay(numberFortnight) );
        }
        counter++;
      } 
      numberFortnight++;
      if ( year !== date.getFullYear() ) {
        numberFortnight = 1;
        year = date.getFullYear();
      }
      this.getDateFortnight(date);
    } while ( counter <= numberFortnights );
    return arrFortnight;
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
    } else {
      date.setDate( date.getDate() + 14 );
    }
  }

  setFormatDay(day: number): string {
    if ( day.toString().length === 1 ) {
      return '0' + day.toString();
    }
    return day.toString();
  }

  changeParameter( ): void {
    const fortnightId = this.forma.controls['LoanParameterId'].value;
    this.loan.haveFinancialLiquidity = this.forma.controls['haveFinancialLiquidity'].value;
    this.setParameters(fortnightId);
  }

  setParameters(id: string): void {
    let reset = true;
    this.message = '';
    this.loanParameters.forEach(parameter => {
      if ( parameter.id === id ) {
        this.numberFortnights = parameter.fortnight.numberFortnight - 1;
        console.log(`numero de quincenas ${ this.numberFortnights } quincena inicial ${ this.indexFortnightsStart }`)
        this.forma.controls['fortnightEnd'].patchValue( this.fortnightsEnd[this.numberFortnights + this.indexFortnightsStart] );
        this.loan.discount = parameter.discount;
        this.loan.interests = parameter.interests;
        this.loan.amount = parameter.amount - parameter.openingBonus - (parameter.amount * (parameter.guaranteedPercentage / 100));
        this.loan.openingBonus = parameter.openingBonus;
        this.loan.warranty = parameter.amount * (parameter.guaranteedPercentage / 100);
        this.loan.remainingFortnightly = parameter.fortnight.numberFortnight;
        this.loan.status = this.evaluteLoan(parameter);
        this.forma.controls['interests'].patchValue( parameter.interests );
        this.forma.controls['discount'].patchValue( parameter.discount );
        this.forma.controls['amount'].patchValue( parameter.amount );
        this.forma.controls['comment'].patchValue( this.loan.comment );
        this.forma.controls['status'].patchValue( this.loan.status );
        reset = false;
      }
    });
    if ( reset ) {
      this.loan.discount = 0;
      this.loan.interests = 0;
      this.loan.openingBonus = 0;
      this.loan.warranty = 0;
      this.loan.remainingFortnightly = 0;
      this.forma.controls['fortnightEnd'].patchValue( '' );
      this.forma.controls['interests'].patchValue( 0 );
      this.forma.controls['amount'].patchValue( 0 );
      this.forma.controls['discount'].patchValue( 0 );
      this.forma.controls['status'].patchValue( '' );
      this.forma.controls['comment'].patchValue( '' );
    }
  }

  evaluteLoan( loanParameter: LoanParameterInterface ): string {
    let status = '';
    this.loan.comment = '';
    this.message = '';
    if ( this.loan.teacher.amountSavings >= loanParameter.saving ) {
      if ( this.loan.teacher.credit === 0 ) {
        if ( this.loan.teacher.birthdate && this.loan.teacher.admission ) {
          if ( this.loan.haveFinancialLiquidity.toString() === 'true' ) {
            status = 'Activo';
          } else {
            this.setErrorEvaluate('El maestro no cuenta con la suficiente liquidez financiera.');
            status = 'Cancelado';
          }
        } else {
          this.setErrorEvaluate('El maestro no cuenta con las fechas para el cálculo.');
          status = 'Cancelado';
        }
      } else {
        this.setErrorEvaluate('El maestro cuenta con un prestamo activo.');
        status = 'Cancelado';
      }
    } else {
      this.setErrorEvaluate('El maestro no cuenta con los ahorros necesarios para el prestamos.');
      status = 'Cancelado';
    }
    return status;
  }

  blurAdmission( value: string ): void {
    if ( value ) {
      this.loan.teacher.admission = value;
      this.forma.controls['teacherAdmission'].patchValue( this.loan.teacher.admission );
      this.changeParameter();
    }
  }

  setErrorEvaluate( message: string): void {
    this.loan.comment = message;
    this.message = message;
  }

  getNumberFortnight(id: string): number {
    let numberFortnight = 0;
    this.loanParameters .forEach(parameter => {
      if ( parameter.id === id ) {
        numberFortnight = parameter.fortnight.numberFortnight;
      }
    });
    return numberFortnight;
  }

  errorMessage( error: any ) {
    console.error(error);
    if (error && error.error) {
      this.message = error.message;
      this.LoadingData = false;
      this.sendingData = false;
    }
  }

  changeFortnightStart() : void {
    
    this.indexFortnightsStart = this.getGetIndex(this.fortnightsStart, this.forma.controls['fortnightStart'].value);
    if(this.loan.amount !== 0) {
      console.log(`numero de quincenas quincena inicial cambio ${ this.numberFortnights } quincena inicial ${ this.indexFortnightsStart }`)
      this.forma.controls['fortnightEnd'].patchValue( this.fortnightsEnd[this.numberFortnights + this.indexFortnightsStart] );
    }
  }

  

  getGetIndex(array: any[], value: string) : number {
    const BreakException = { };
    var index = 0;
    try
    {
      array.forEach(element => {
        
        if(element === value) {
          throw BreakException;
        }
        index += 1;
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }    
    console.log(index);
    return index;
  }

}
