<div class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Configuración</li>
            <li class="breadcrumb-item"><a [routerLink]="[returnPath, searchValue]" routerLinkActive="router-link-active">Usuarios</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">{{ title }}</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="loadingData" class="alert alert-warning" role="alert">
        <i class="fas fa-sync-alt fa-spin"></i> Obteniendo información...
    </div>

    <div class="card card-block animated fadeIn fast">
        <form [formGroup]="forma" (ngSubmit)="sendForm(  )">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['paternal'].valid && forma.controls['paternal'].touched, 'has-success': forma.controls['paternal'].valid && forma.controls['paternal'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Paterno</label>
                        <input type="text" maxlength="50" class="form-control underlined" maxlength="150" formControlName="paternal">
                        <span *ngIf="forma.controls['paternal'].touched && forma.controls['paternal'].errors?.required" class="has-error ">
                            El campo nombre es requerido.
                        </span>
                        <span *ngIf="forma.controls['paternal'].touched && forma.controls['paternal'].errors?.maxlength" class="has-error ">
                            El campo nombre debe tener un maximo de {{ forma.controls['paternal'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                        <span *ngIf="forma.controls['paternal'].touched && forma.controls['paternal'].errors?.minlength" class="has-error ">
                            El campo nombre debe tener almenos {{ forma.controls['paternal'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['maternal'].valid && forma.controls['maternal'].touched, 'has-success': forma.controls['maternal'].valid && forma.controls['maternal'].touched }">
                        <label class="control-label">Materno</label>
                        <input type="text" class="form-control underlined" maxlength="150" formControlName="maternal">
                        <span *ngIf="forma.controls['maternal'].touched && forma.controls['maternal'].errors?.required" class="has-error ">
                            El campo nombre es requerido.
                        </span>
                        <span *ngIf="forma.controls['maternal'].touched && forma.controls['maternal'].errors?.maxlength" class="has-error ">
                            El campo nombre debe tener un maximo de {{ forma.controls['maternal'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                        <span *ngIf="forma.controls['maternal'].touched && forma.controls['maternal'].errors?.minlength" class="has-error ">
                            El campo nombre debe tener almenos {{ forma.controls['maternal'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['name'].valid && forma.controls['name'].touched, 'has-success': forma.controls['name'].valid && forma.controls['name'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Nombre</label>
                        <input type="text" class="form-control underlined" maxlength="150" formControlName="name">
                        <span *ngIf="forma.controls['name'].touched && forma.controls['name'].errors?.required" class="has-error ">
                            El campo nombre es requerido.
                        </span>
                        <span *ngIf="forma.controls['name'].touched && forma.controls['name'].errors?.maxlength" class="has-error ">
                            El campo nombre debe tener un maximo de {{ forma.controls['name'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                        <span *ngIf="forma.controls['name'].touched && forma.controls['name'].errors?.minlength" class="has-error ">
                            El campo nombre debe tener almenos {{ forma.controls['name'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>
            </div>

            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['username'].valid && forma.controls['username'].touched, 'has-success': forma.controls['username'].valid && forma.controls['username'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Usuario</label>
                        <input type="text" class="form-control underlined" maxlength="20" formControlName="username">
                        <span *ngIf="forma.controls['username'].touched && forma.controls['username'].errors?.required" class="has-error ">
                                El campo nombre es requerido.
                            </span>
                        <span *ngIf="forma.controls['username'].touched && forma.controls['username'].errors?.maxlength" class="has-error ">
                                El campo nombre debe tener un maximo de {{ forma.controls['username'].errors.minlength.requiredLength }}
                                caracteres.
                            </span>
                        <span *ngIf="forma.controls['username'].touched && forma.controls['username'].errors?.minlength" class="has-error ">
                                El campo nombre debe tener almenos {{ forma.controls['username'].errors.minlength.requiredLength }}
                                caracteres.
                            </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['password'].valid && forma.controls['password'].touched, 'has-success': forma.controls['password'].valid && forma.controls['password'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Contraseña</label>
                        <input type="password" class="form-control underlined" maxlength="150" formControlName="password">
                        <span *ngIf="forma.controls[ 'password'].touched && forma.controls[ 'password'].errors?.required " class="has-error ">
                            El campo nombre es requerido.
                        </span>
                        <span *ngIf="forma.controls[ 'password'].touched && forma.controls[ 'password'].errors?.minlength " class="has-error ">
                            El campo nombre debe tener almenos {{ forma.controls['password'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ">
                    <div class="form-group " [ngClass]="{ 'has-error': !forma.controls[ 'name'].valid && forma.controls[ 'name'].touched, 'has-success': forma.controls[ 'name'].valid && forma.controls[ 'name'].touched } ">
                        <label class="control-label "><i class="fas fa-asterisk required "></i> Perfil</label>
                        <select class="custom-select " formControlName="profileId">
                            <option value="" >Seleccionar...</option>
                            <option [value]="profile.id " *ngFor="let profile of profiles " >{{ profile.name }}</option>
                        </select>

                        <span *ngIf="forma.controls[ 'name'].touched && forma.controls[ 'name'].errors?.required " class="has-error ">
                            El campo nombre es requerido.
                        </span>
                    </div>
                </div>
            </div>

            <div class="form-group text-center ">
                <button [disabled]="!forma.valid ||sendData " class="btn " [ngClass]="{ 'btn-outline-success': title=='Agregar' , 'btn-outline-warning': title=='Editar' } ">
                    <i [ngClass]="{ 'far fa-save': !sendData, 'fas fa-sync-alt fa-spin': sendData } "></i>
                    <span *ngIf="!sendData ">Guardar</span>
                    <span *ngIf="sendData ">Guardando...</span>
                </button>
            </div>

            <div class="alert alert-danger " role="alert " *ngIf="message ">
                {{ message }}
            </div>
        </form>

        <div class="form-group ">
            <a class="btn btn-outline-info " [routerLink]="[returnPath, searchValue] "><i class="fas fa-arrow-left "></i> Regresar</a>
        </div>
    </div>
</div>