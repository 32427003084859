import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ContributionInterface } from '../interfaces/contribution.interface';

@Injectable()
export class ContributionService {
  private apiURL = 'api/Catalogs/Contributions';

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  create(phoneType: ContributionInterface): Observable<any> {
    return this.http.post<any>(this.apiURL + '/Create', phoneType);
  }

  get(searchValue: string) {
    return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
  }

  getById(id: string) {
    return this.http.get<any>(this.apiURL + '/' + id);
  }

  update(id: string, user: ContributionInterface) {
    return this.http.put<any>(this.apiURL + '/' + id, user);
  }

  delete(id: string) {
    return this.http.delete<any>(this.apiURL + '/' + id);
  }

}
