<article class="content items-list-page">
    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Calcula tu prestamo</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <app-not-data *ngIf="!loadingData && !data.length"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast" *ngIf="!loadingData">
        <div class="container">
            <div class="row text-center">
                <div class="col-md-12">
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                            <label for="">Selecciona el tipo de prestamo:</label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                            <select class="custom-select " (change)="onChangeType( $event.target.value )">
                                    <option [value]="item" *ngFor="let item of loanTypes;"  >{{ item }}</option>
                                </select>

                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                            <label for="">Selecciona la cantidad:</label>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                            <select class="custom-select " (change)="onChange( $event.target.value )">
                                    <option [value]="item" *ngFor="let item of amounts;"  >{{ item | currency: "MXN" }}</option>
                                </select>
                        </div>
                    </div>
                    <br>
                    <div class="row ">
                        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                            <button class="btn btn-primary" (click)="showData()" [disabled]="selected === 0">Calcular</button>
                        </div>
                    </div>
                </div>
            </div>
            <br><br>
            <div class="row container" *ngIf="showTable">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Plazo</th>
                            <th>Prestamo</th>
                            <th>Descuento Quincenal</th>
                            <th>Interes Anual</th>
                            <th>Alcance Neto</th>
                            <th>Ahorro</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="selectedLoan.length">
                        <tr *ngFor="let ordi of selectedLoan">
                            <td>{{ ordi.term }}</td>
                            <td>{{ ordi.loan | currency: "MXN" }}</td>
                            <td>{{ ordi.discount | currency: "MXN" }}</td>
                            <td>{{ ordi.annualInterest}}%</td>
                            <td>{{ ordi.scope | currency: "MXN" }}</td>
                            <td>{{ ordi.saving | currency: "MXN" }}</td>
                            <!-- <td><button class="btn btn-link" (click)="apply(ordi.id)">Aplicar</button></td> -->
                        </tr>
                    </tbody>
                    <!--<tbody *ngIf="selectExtra.length">
                        <tr *ngFor="let extra of selectExtra">
                            <td>{{ extra.term }}</td>
                            <td>{{ extra.loan | currency: "MXN" }}</td>
                            <td>{{ extra.discount | currency: "MXN" }}</td>
                            <td>{{ extra.annualInterest}}%</td>
                            <td>{{ extra.scope | currency: "MXN" }}</td>
                            <td>{{ extra.saving | currency:"MXN"}}</td>
                             <td><button class="btn btn-link" (click)="apply(extra.id)">Aplicar</button></td> 
                        </tr>
                    </tbody>-->
                </table>
            </div>
        </div>
    </div>
</article>