import { Routes } from '@angular/router';
import { ApplicationComponent } from './application.component';
import { ApplicationListComponent } from './application-list.component';

export const APPLICATIONS_ROUTES: Routes = [
    { path: 'add', component: ApplicationComponent },
    { path: 'add/:id/:searchValue', component: ApplicationComponent },
    { path: 'Edit/:id', component: ApplicationComponent },
    { path: 'Edit/:id/:searchValue', component: ApplicationComponent },
    { path: '', component: ApplicationListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
