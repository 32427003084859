<div class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
            <li class="breadcrumb-item"><a [routerLink]="[returnPath, searchValue]" routerLinkActive="router-link-active">Prestamos</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">{{ title }}</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="LoadingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <form [formGroup]="forma" (ngSubmit)="sendForm()">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h4 class="info"><b>{{ loan.teacher.name | uppercase }} <small>({{ loan.teacher.rfc | uppercase }})</small></b></h4>
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['loanNumber'].valid && forma.controls['loanNumber'].touched, 'has-success': forma.controls['loanNumber'].valid && forma.controls['loanNumber'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> # Hoja</label>
                        <input type="number" [readonly]="editing" class="form-control underlined" formControlName="loanNumber">
                        <span *ngIf="forma.controls['loanNumber'].touched && forma.controls['loanNumber'].errors?.required" class="has-error ">
                                El campo es requerido.
                            </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['individualAccount'].valid && forma.controls['individualAccount'].touched, 'has-success': forma.controls['individualAccount'].valid && forma.controls['individualAccount'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Cuenta Individual</label>
                        <input type="number" [readonly]="editing" class="form-control underlined" formControlName="individualAccount">
                        <span *ngIf="forma.controls['individualAccount'].touched && forma.controls['individualAccount'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="row">
                        <label class="control-label">Sorteo</label>
                    </div>
                    <div class="row">
                        <h3 class="control-label">{{ loan.draw.name }}</h3>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="row">
                        <label class="control-label">Estatus</label>
                    </div>
                    <div class="row">
                        <h3 class="control-label" [ngClass]="{'text-danger': loan.status == 'Cancelado', 'text-success': loan.status == 'Activo'}">{{ loan.status }}</h3>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Monto / Quincenas</label>
                        <select *ngIf="editing" disabled="disabled" class="custom-select" name="LoanParameterId" (change)="changeParameter(  )" formControlName="LoanParameterId">
                            <option value="">Seleccionar...</option>
                            <option [value]="parameter.id" *ngFor="let parameter of loanParameters ">{{ parameter.amount | currency }} / {{
                                parameter.fortnight.numberFortnight }}</option>
                        </select>
                        <select *ngIf="!editing" class="custom-select" name="LoanParameterId" (change)="changeParameter(  )" formControlName="LoanParameterId">
                            <option value="">Seleccionar...</option>
                            <option [value]="parameter.id" *ngFor="let parameter of loanParameters ">{{ parameter.amount | currency }} / {{
                                parameter.fortnight.numberFortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="row">
                        <label class="control-label">Interes</label>
                    </div>
                    <div class="row">
                        <h3 class="control-label text-danger">{{ loan.interests }} %</h3>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="row">
                        <label class="control-label">Descuento</label>
                    </div>
                    <div class="row">
                        <h3 class="control-label text-danger">{{ loan.discount | currency }}</h3>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Región</label>
                        <select *ngIf="editing" disabled="disabled" class="custom-select" name="regionId" formControlName="regionId">
                            <option [value]="region.id" *ngFor="let region of regions ">{{ region.name }}</option>
                        </select>
                        <select *ngIf="!editing" class="custom-select" name="regionId" formControlName="regionId">
                            <option [value]="region.id" *ngFor="let region of regions ">{{ region.name }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. Inicial</label>
                        <h3 *ngIf="editing" class="control-label">{{ loan.fortnightStart }}</h3>
                        <select *ngIf="!editing" class="custom-select" name="fortnightStart" (change)="changeFortnightStart()" formControlName="fortnightStart">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnightsStart ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. Final</label>
                        <select class="custom-select" name="fortnightEnd" formControlName="fortnightEnd">
                            <option value="">Seleccionar...</option>
                            <option [value]="fortnight" *ngFor="let fortnight of fortnightsEnd ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label class="control-label">Cumple con la liquidez</label>
                        <select class="custom-select" name="haveFinancialLiquidity" (change)="changeParameter(  )" formControlName="haveFinancialLiquidity">
                            <option value="false" selected="selected" >No</option>
                            <option value="true">Si</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3" *ngIf="!haveAdmission">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['teacherAdmission'].valid && forma.controls['teacherAdmission'].touched, 'has-success': forma.controls['teacherAdmission'].valid && forma.controls['teacherAdmission'].touched }">
                        <label class="control-label">Fecha Admisión del maestro</label>
                        <input type="date" #admission class="form-control underlined" formControlName="teacherAdmission" (blur)="blurAdmission( admission.value )">
                        <span *ngIf="forma.controls['teacherAdmission'].touched && forma.controls['teacherAdmission'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="form-group" [ngClass]="{ 'has-success': forma.controls['comment'].touched }">
                        <label class="control-label">Comentario</label>
                        <input type="text" class="form-control underlined" maxlength="200" formControlName="comment">
                        <span *ngIf="forma.controls['comment'].touched && forma.controls['comment'].errors?.minlength" class="has-error">
                            El campo comentario debe tener {{ forma.controls['comment'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>
            </div>

            <div class="form-group text-center ">
                <button [disabled]="!forma.valid || sendingData " class="btn " [ngClass]="{ 'btn-outline-success': title=='Agregar' , 'btn-outline-warning': title=='Editar' } ">
                    <i [ngClass]="{ 'far fa-save': !sendingData, 'fas fa-sync-alt fa-spin': sendingData } "></i>
                    <span *ngIf="!sendingData ">Guardar</span>
                    <span *ngIf="sendingData ">Guardando...</span>
                </button>
            </div>

            <div class="alert alert-danger " role="alert " *ngIf="message ">
                {{ message }}
            </div>
        </form>

        <table *ngIf="financialLiquidity.length > 0" class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Año</th>
                    <th scope="col">Qna 1<br />Qna 13</th>
                    <th scope="col">Qna 2<br />Qna 14</th>
                    <th scope="col">Qna 3<br />Qna 15</th>
                    <th scope="col">Qna 4<br />Qna 16</th>
                    <th scope="col">Qna 5<br />Qna 17</th>
                    <th scope="col">Qna 6<br />Qna 18</th>
                    <th scope="col">Qna 7<br />Qna 19</th>
                    <th scope="col">Qna 8<br />Qna 20</th>
                    <th scope="col">Qna 9<br />Qna 21</th>
                    <th scope="col">Qna 10<br />Qna 22</th>
                    <th scope="col">Qna 11<br />Qna 23</th>
                    <th scope="col">Qna 12<br />Qna 24</th>
                    <th scope="col">Subtotal</th>
                </tr>
            </thead>

            <tbody>
                <tr class="text-right" *ngFor="let item of financialLiquidity; let i = index">
                    <td>{{ item.year }}</td>
                    <td>{{ item.qna_1 | currency }}<br />{{ item.qna_13 | currency }}</td>
                    <td>{{ item.qna_2 | currency }}<br />{{ item.qna_14 | currency }}</td>
                    <td>{{ item.qna_3 | currency }}<br />{{ item.qna_15 | currency }}</td>
                    <td>{{ item.qna_4 | currency }}<br />{{ item.qna_16 | currency }}</td>
                    <td>{{ item.qna_5 | currency }}<br />{{ item.qna_17 | currency }}</td>
                    <td>{{ item.qna_6 | currency }}<br />{{ item.qna_18 | currency }}</td>
                    <td>{{ item.qna_7 | currency }}<br />{{ item.qna_19 | currency }}</td>
                    <td>{{ item.qna_8 | currency }}<br />{{ item.qna_20 | currency }}</td>
                    <td>{{ item.qna_9 | currency }}<br />{{ item.qna_21 | currency }}</td>
                    <td>{{ item.qna_10 | currency }}<br />{{ item.qna_22 | currency }}</td>
                    <td>{{ item.qna_11 | currency }}<br />{{ item.qna_23 | currency }}</td>
                    <td>{{ item.qna_12 | currency }}<br />{{ item.qna_24 | currency }}</td>
                    <td>{{ item.total | currency }}</td>
                </tr>
            </tbody>
        </table>

        <div class="row">
            <a class="btn btn-outline-info " [routerLink]="[returnPath, searchValue] "><i class="fas fa-arrow-left "></i> Regresar</a>
        </div>
    </div>
</div>