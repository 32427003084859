import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AccountService } from '../../account/login/services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SavingInterface } from './interfeces/saving.interface';
import { SavingService } from './services/saving.service';
import { TeacherService } from '../teachers/services/teacher.service';
import { TeacherInterface } from '../teachers/interface/teacher.interface';
import { TeacherStatusInterface } from '../../catalogs/teacher-status/interfaces/teacher-status.interface';

@Component({
  selector: 'app-saving',
  templateUrl: './saving.component.html',
  styles: []
})
export class SavingComponent implements OnInit {
  title = 'Agregar';
  teacherName = '';
  returnPath = '../../';
  forma: UntypedFormGroup;
  message: string;
  gettingData = false;
  sendingData = false;
  searchValue = '';
  amount = 0;
  saving: SavingInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    teacherId: '',
    year: new Date().getFullYear(),
    lastUpdated : new Date(),
    userIdLastUpdated: this.accountService.getUserId(),
    fortnight1: 0,
    fortnight2: 0,
    fortnight3: 0,
    fortnight4: 0,
    fortnight5: 0,
    fortnight6: 0,
    fortnight7: 0,
    fortnight8: 0,
    fortnight9: 0,
    fortnight10: 0,
    fortnight11: 0,
    fortnight12: 0,
    fortnight13: 0,
    fortnight14: 0,
    fortnight15: 0,
    fortnight16: 0,
    fortnight17: 0,
    fortnight18: 0,
    fortnight19: 0,
    fortnight20: 0,
    fortnight21: 0,
    fortnight22: 0,
    fortnight23: 0,
    fortnight24: 0,
    type: 'Aportación',
    movementDate : `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`,
    teacherStatusId: '00000000-0000-0000-0000-000000000000',
    teacherStatus: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date,
      active: true,
      name: 'Activo',
      percentageSaving: 0,
      lastUpdated: new Date,
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000'
    },
    teacher: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      lastUpdated: new Date(),
      userIdLastUpdated: this.accountService.getUserId(),
      regionId: '00000000-0000-0000-0000-000000000000',
      rfc: '',
      name: '',
      street: '',
      number: '',
      colony: '',
      zipCode: 0,
      locality: '',
      lastStatus: 'Activo',
      email: '',
      admission: '',
      birthdate: '',
      contributionId: '00000000-0000-0000-0000-000000000000',
      gender: '',
      identifier: 0,
      amountSavings: 0,
      credit: 0,
      type: '',
      teacherStatus: [],
      beneficiaries: [],
      teacherPhones: [],
      loans: [],
      savings: []
    }
  };

  constructor( private accountService: AccountService, private formBuilder: UntypedFormBuilder,
    private router: Router, private activatedRoute: ActivatedRoute,
    private savingService: SavingService, private teacherService: TeacherService ) { }

  ngOnInit() {
    this.gettingData = true;
    this.buildForm();

    this.activatedRoute.params.subscribe(params => {
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }

      if (params['id']) {
        this.title = 'Editar';
        this.LoadSaving(params['id']);
      } else if (params['teacher']) {
        this.saving.teacherId = params['teacher'];
        this.teacherService.getById(params['teacher'])
          .subscribe(teacher => {
            this.teacherName = teacher.name;
            this.saving.teacherStatusId = this.findStatusActive(teacher.teacherStatus);
            this.saving.teacher = teacher;
            this.forma.setValue(this.saving);
            this.gettingData = false;
          }, error => this.setErrorMessage( error ) );
      }
    });
  }

  findStatusActive( status: TeacherStatusInterface[] ): string {
    let statusId = '';
    status.forEach(item => {
      if ( item.active === true ) {
        statusId = item.id;
      }
    });
    return statusId;
  }

  LoadSaving(id: string): void {
    this.savingService.getById(id)
      .subscribe(data => {
        this.teacherName = data.teacher.name;
        this.saving = data;
        this.saving.movementDate = data.movementDate.substring(0, 10);
        this.forma.setValue(this.saving);
        this.calculateAmount();
        this.gettingData = false;
      }, error => this.setErrorMessage(error));
  }

  haveSavingThisYear( teacher: TeacherInterface ): boolean {
    const BreakException = { };
    try {
      teacher.savings.forEach(saving => {
        if ( saving.year === new Date().getFullYear() ) {
          this.LoadSaving(saving.id);
          throw BreakException;
        }
      });
      return false;
    } catch ( ex ) {
      if ( ex === BreakException ) {
        return true;
      }
    }
  }

  buildForm(): void {
    this.forma = this.formBuilder.group({
      id: [''],
      userId: [''],
      date: [''],
      active: [''],
      teacherId: [''],
      lastUpdated: [''],
      userIdLastUpdated: [''],
      year: ['', [Validators.required]],
      fortnight1: ['', [Validators.required]],
      fortnight2: ['', [Validators.required]],
      fortnight3: ['', [Validators.required]],
      fortnight4: ['', [Validators.required]],
      fortnight5: ['', [Validators.required]],
      fortnight6: ['', [Validators.required]],
      fortnight7: ['', [Validators.required]],
      fortnight8: ['', [Validators.required]],
      fortnight9: ['', [Validators.required]],
      fortnight10: ['', [Validators.required]],
      fortnight11: ['', [Validators.required]],
      fortnight12: ['', [Validators.required]],
      fortnight13: ['', [Validators.required]],
      fortnight14: ['', [Validators.required]],
      fortnight15: ['', [Validators.required]],
      fortnight16: ['', [Validators.required]],
      fortnight17: ['', [Validators.required]],
      fortnight18: ['', [Validators.required]],
      fortnight19: ['', [Validators.required]],
      fortnight20: ['', [Validators.required]],
      fortnight21: ['', [Validators.required]],
      fortnight22: ['', [Validators.required]],
      fortnight23: ['', [Validators.required]],
      fortnight24: ['', [Validators.required]],
      type: [''],
      movementDate: ['', [Validators.required]],
      teacherStatusId: [''],
      teacherStatus: [''],
      teacher: ['']
    });
  }

  setErrorMessage( error: any ) {
    if (error && error.error) {
      this.message = error.message;
      this.gettingData = false;
      this.sendingData = false;
    }
  }

  sendForm(): void {
    this.sendingData = true;
    this.saving = Object.assign({}, this.forma.value);
    this.saving.date = new Date();
    if ( this.title === 'Agregar' ) {
      this.savingService.create( this.saving )
        .subscribe(success => {
          this.router.navigate(['administration/savings', this.searchValue]);
        }, error => this.setErrorMessage(error));
    } else {
      this.saving.userIdLastUpdated = this.accountService.getUserId();
      this.savingService.update( this.saving.id, this.saving )
      .subscribe(success => {
        this.router.navigate(['administration/savings', this.searchValue]);
      }, error => this.setErrorMessage(error));
    }
  }

  calculateAmount(): void {
    let amount = 0;
    for (let index = 1; index <= 24; index++) {
      amount += this.forma.controls['fortnight' + index].value;
    }
    this.amount = amount;
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }
}
