import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LoanReportService {
  private apiURL = 'api/Loans';

  constructor(  private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string  ) { }

    get( rfc: string) {
      return this.http.get<any>(this.apiURL + '/GetDataReport/' + rfc);
    }
}
