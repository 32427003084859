import { Component, OnInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { OpenDirective } from '../../modals/open.directive';
import { ActivatedRoute, Router } from '@angular/router';
import { DrawService } from './services/draw.service';
import { DrawInterface } from './interfaces/draw.interface';
import { AlertComponent } from '../../modals/alert.component';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AccountService } from '../../account/login/services/account.service';

@Component({
  selector: 'app-draw-list',
  templateUrl: './draw-list.component.html',
  styles: []
})
export class DrawListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];
  applications: ApplicationInterface[];
  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private drawsService: DrawService,
    public accountService: AccountService
  ) {}

  ngOnInit() {
    this.accountService.getApplications('administration', 'draws').subscribe(
      applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if (params['searchValue']) {
            this.searchValue = params['searchValue'];
            this.loadData(this.searchValue);
          } else {
            this.loadData('null');
          }
        });
      },
      error => this.errorMessage(error)
    );
  }

  errorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.message);
      this.loadingData = false;
    }
  }

  loadData(value: string) {
    this.loadingData = true;
    this.drawsService.get(value).subscribe(
      data => {
        this.jsonArray = data;
        this.loadingData = false;
      },
      error => this.errorMessage(error)
    );
  }

  search() {
    let search = this.searchValue;
    if (search === '') {
      search = 'null';
    }
    this.loadData(search);
  }

  deleteItem(id: string, draw: DrawInterface) {
    draw.active = false;
    this.drawsService.update(id, draw).subscribe(
      success => {
        this.loadData('null');
      },
      error => this.errorMessage(error)
    );
  }

  showConfirm(id: string, draw: DrawInterface) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      AlertComponent
    );

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<AlertComponent>componentRef.instance).title = 'Alerta';
    (<AlertComponent>(
      componentRef.instance
    )).message = `Seguro que desea eliminar el sorteo ${draw.name}.`;
    (<AlertComponent>componentRef.instance).open();
    (<AlertComponent>componentRef.instance).alertOutput.subscribe(
      success => {
        this.deleteItem(id, draw);
      },
      error => this.errorMessage(error)
    );
  }

  agregar() {
    const text = this.searchValue;
    this.router.navigate(['administration/draws/add', '', text]);
  }
}
