<appAngularPaginator [id]="id" [boundaryLinks]="boundaryLinks" [directionLinks]="directionLinks" [maxSize]="maxSize" [rotate]="rotate" [boundaryLinkNumbers]="boundaryLinkNumbers" [forceEllipses]="forceEllipses" #paginator="angularPaginator" (pageChange)="pageChange.emit($event)">

    <!-- ======================================== BOOTSTRAP 4 TEMPLATE ======================================== -->
    <ng-container *ngIf="!(autoHide && paginator.pages['length'] <= 1)">
        <nav class="angular-bs4-pagination" [attr.aria-label]="screenReaderPaginationLabel">

            <ng-container *ngIf="paginator.pages['length'] !== 0">
                <ul class="pagination" [ngClass]="className">

                    <!-- boundary link -->
                    <li class="page-item" *ngIf="boundaryLinks" (click)="paginator.toFirstPage()" [ngClass]="{'disabled': (paginator.currentPage === paginator.firstPage) || disabled}">
                        <a class="page-link" href="javascript:void(0)">
                            <span aria-hidden="true" [innerHTML]="firstText"></span>
                            <span class="sr-only">{{ screenReaderFirstText }}</span>
                        </a>
                    </li>

                    <!-- direction link -->
                    <li class="page-item" *ngIf="directionLinks" (click)="paginator.toPreviousPage()" [ngClass]="{'disabled': (paginator.currentPage === paginator.firstPage) || disabled}">
                        <a class="page-link" href="javascript:void(0)">
                            <span aria-hidden="true" [innerHTML]="previousText"></span>
                            <span class="sr-only">{{ screenReaderPreviousText }}</span>
                        </a>
                    </li>

                    <!-- pagination array -->
                    <li class="page-item" *ngFor="let page of paginator.pages" [ngClass]="{'active': page['active'], 'disabled': disabled}" (click)="!page['active'] && paginator.setCurrentPage(page['number'])">
                        <a href="javascript:void(0)" class="page-link">
              {{ page['text'] }}
              <span class="sr-only" *ngIf="!page['active']">{{ screenReaderPageText + ' ' }}{{ page['text'] }}</span>
              <span class="sr-only" *ngIf="page['active']">{{ screenReaderCurrentPageText + ' ' }}{{ page['text'] }}</span>
            </a>
                    </li>

                    <!-- direction link -->
                    <li class="page-item" *ngIf="directionLinks" (click)="paginator.toNextPage()" [ngClass]="{'disabled': (paginator.currentPage === paginator.lastPage) || disabled}">
                        <a class="page-link" href="javascript:void(0)">
                            <span aria-hidden="true" [innerHTML]="nextText"></span>
                            <span class="sr-only">{{ screenReaderNextText }}</span>
                        </a>
                    </li>

                    <!-- boundary link -->
                    <li class="page-item" *ngIf="boundaryLinks" (click)="paginator.toLastPage()" [ngClass]="{'disabled': (paginator.currentPage === paginator.lastPage) || disabled}">
                        <a class="page-link" href="javascript:void(0)">
                            <span aria-hidden="true" [innerHTML]="lastText"></span>
                            <span class="sr-only">{{ screenReaderLastText }}</span>
                        </a>
                    </li>

                </ul>
            </ng-container>

        </nav>
    </ng-container>

</appAngularPaginator>