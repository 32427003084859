<article class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
            <li class="breadcrumb-item"><a [routerLink]="[returnPath, searchValue]" routerLinkActive="router-link-active">Sorteos</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">{{ title }}</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="gettingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <form [formGroup]="forma" (ngSubmit)="sendForm()">
            <div class="form-group" [ngClass]="{'has-error': !forma.controls['name'].valid && forma.controls['name'].touched, 'has-success': forma.controls['name'].valid }">
                <label class="control-label"><i class="fas fa-asterisk required"></i> Nombre</label>
                <input type="text" maxlength="150" class="form-control underlined" formControlName="name">
                <span *ngIf="forma.controls['name'].touched && forma.controls['name'].errors?.required" class="has-error ">
                    El campo es requerido.
                </span>
                <span *ngIf="forma.controls['name'].touched && forma.controls['name'].errors?.minlength" class="has-error ">
                    El campo debe tener almenos {{ forma.controls['name'].errors.minlength.requiredLength }}
                    caracteres.
                </span>
            </div>

            <div class="form-group text-center">
                <button [disabled]="!forma.valid ||sendingData" class="btn" [ngClass]="{'btn-outline-success': title=='Agregar', 'btn-outline-warning': title=='Editar' }">
                    <i [ngClass]="{ 'far fa-save': !sendingData, 'fas fa-sync-alt fa-spin': sendingData }"></i>
                    <span *ngIf="!sendingData">Guardar</span>
                    <span *ngIf="sendingData">Guardando...</span>
                </button>

                <button type="button" *ngIf="draw.status === 'Pendiente'" (click)="openDraw()" [disabled]="!forma.valid ||sendingData" class="btn btn-outline-primary">
                    <i [ngClass]="{ 'far fa-folder-open': !sendingData, 'fas fa-sync-alt fa-spin': sendingData }"></i>
                    <span *ngIf="!sendingData">Abrir</span>
                    <span *ngIf="sendingData">Abriendo...</span>
                </button>

                <button type="button" *ngIf="draw.status === 'Abierto'" (click)="closeDraw()" [disabled]="!forma.valid ||sendingData" class="btn btn-outline-danger">
                    <i [ngClass]="{ 'fas fa-folder-minus': !sendingData }"></i>
                    <span *ngIf="!sendingData">Cerrar</span>
                    <span *ngIf="sendingData">Cerrando...</span>
                </button>
            </div>

            <div class="alert alert-danger" role="alert" *ngIf="message">
                {{ message }}
            </div>
        </form>

        <div class="form-group">
            <a class="btn btn-outline-info" [routerLink]="[returnPath, searchValue]"><i class="fas fa-arrow-left"></i>
                Regresar</a>
        </div>
    </div>
</article>