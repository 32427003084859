<div class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
            <li class="breadcrumb-item"><a [routerLink]="[returnPath, searchValue]" routerLinkActive="router-link-active">Maestros</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Movimiento</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                <div>
                    <label class="control-label">Número</label>
                </div>
                <div>
                    <h3 class="control-label">{{ teacher.identifier }}</h3>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <div>
                    <label class="control-label">RFC</label>
                </div>
                <div>
                    <h3 class="control-label">{{ teacher.rfc }}</h3>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                <div>
                    <label class="control-label">Nombre</label>
                </div>
                <div>
                    <h3 class="control-label">{{ teacher.name }}</h3>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                <label class="control-label">Estatus</label>
                <select #statusPercentage class="custom-select" (change)="statusChanged( statusPercentage.value )">
                    <option [value]="item.percentage" *ngFor="let item of status">{{ item.name }}</option>
                </select>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <div>
                    <label class="control-label">Ahorro</label>
                </div>
                <div>
                    <h3 class="control-label success">{{ amountSavings | currency }}</h3>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <div>
                    <label class="control-label">Interes</label>
                </div>
                <div>
                    <h3 class="control-label success">{{ amountInterests | currency }}</h3>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <div>
                    <label class="control-label">Total</label>
                </div>
                <div>
                    <h3 class="control-label success">{{ amountSavings + amountInterests | currency }}</h3>
                </div>
            </div>
        </div>

        <div class="form-group text-center">
            <button [disabled]="working" class="btn btn-outline-success" (click)="GetCheck()">
                    <i [ngClass]="{ 'far fa-save': !working, 'fas fa-sync-alt fa-spin': working } "></i>
                    <span *ngIf="!working">Guardar</span>
                    <span *ngIf="working">Guardando...</span>
                </button>
        </div>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>
    </div>
</div>