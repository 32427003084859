import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoanInterface } from '../interfaces/loan.interface';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class LoanService {
  private apiURL = 'api/Loans';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    create( loan: LoanInterface ): Observable<any> {
      return this.http.post<any>(this.apiURL + '/Create', loan);
    }

    getByRfc( rfc: string) {
      return this.http.get<any>(this.apiURL + '/GetDataReport/' + rfc);
    }
    getInfo(teacher: string) {
      return this.http.get<any>(this.apiURL + '/GetInfo/' + teacher);
    }
    getInfoById( id: string) {
      return this.http.get<any>(this.apiURL + '/GetInfo/' + id);
    }
    get( searchValue: string, drawId: string ) {
      return this.http.get<any>(this.apiURL + '/Search/' + drawId + '/' + searchValue);
    }

    getById( id: string ) {
      return this.http.get<any>( this.apiURL + '/' + id );
    }

    update( id: string, loan: LoanInterface ) {
      return this.http.put<any>( this.apiURL + '/' + id, loan );
    }

    delete( id: string ) {
      return this.http.delete<any>( this.apiURL + '/' + id );
    }

    havePending( drawId: string ) {
      return this.http.get<any>( this.apiURL + '/HavePending/' + drawId );
    }
    applyLoan(id, rfc){
      return this.http.post<any>( this.apiURL + '/Applyloan/',{loanInfo: id,rfc: rfc} );
    }
    apply( drawId: string, userId: string ) {
      return this.http.get<any>( this.apiURL + '/Apply/' + drawId + '/' + userId );
    }
}
