import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IProfile } from './interfaces/iprofile';
import { ProfilesService } from './services/profiles.service';
import { AccountService } from '../../account/login/services/account.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styles: []
})

export class ProfileComponent {
  titulo = 'Agregar';
  returnPath = '../../../';
  forma: UntypedFormGroup;
  message: string;
  loadingData = false;
  sendData = false;
  searchValue = '';
  profile: IProfile = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    name: '',
    lastUpdated: new Date(),
    userIdLastUpdated: this.accountService.getUserId()
  };

  constructor( private profileService: ProfilesService,
    private accountService: AccountService,
    private router: Router, private activatedRoute: ActivatedRoute ) {
      this.loadingData = true;
      this.forma = new UntypedFormGroup({
        'id' : new UntypedFormControl(''),
        'userId' : new UntypedFormControl(''),
        'date' : new UntypedFormControl(''),
        'active' : new UntypedFormControl(''),
        'name' : new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
        'lastUpdated': new UntypedFormControl(''),
        'userIdLastUpdated': new UntypedFormControl('')
        });

      this.activatedRoute.params
        .subscribe( params => {
          if ( params['searchValue'] ) {
            this.searchValue = params['searchValue'];
          }

          if ( params['id'] ) {
            this.titulo = 'Editar';
              this.profile.id = params['id'];
              this.profileService.getById( params['id'] )
                .subscribe(data => {
                  this.returnPath = '../../';
                  this.profile = Object.assign({}, data );
                  this.forma.setValue(this.profile);
                },
                error => this.errorMessage(error) );
          } else {
            this.forma.setValue(this.profile);
          }
          this.loadingData = false;
        });
    }

  sendForm() {
    this.sendData = true;
    this.profile = Object.assign({}, this.forma.value );
    this.profile.date = new Date();
    if ( this.titulo === 'Agregar' ) {
      this.profileService.create(this.profile)
        .subscribe(success => this.router.navigate(['/configuration/profiles', this.searchValue])
        , error => this.errorMessage(error) );
    } else {
      this.profile.userIdLastUpdated = this.accountService.getUserId();
      this.profileService.update(this.profile.id, this.profile)
        .subscribe(success => this.router.navigate(['/configuration/profiles',  this.searchValue])
        , error => this.errorMessage(error) );
    }
  }

  errorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.message = error.error[''][0];
      this.sendData = false;
    }
  }

}
