import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BalanceService {
  private apiURL = 'api/Reports/Balance';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    get( inicio: string, fin: string ) {
      return this.http.get<any>(`${ this.apiURL }/${ inicio }/${ fin }`);
    }
}
