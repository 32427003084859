import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModulesInterface } from '../interfaces/modules.interface';

@Injectable()
export class ModulesService {

  private apiURL = 'api/Configuration/Modules';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    create( module: ModulesInterface ) {
      return this.http.post<any>(this.apiURL + '/Create', module);
    }

    get( searchValue: string ) {
      return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
    }

    getIncludeApplication( searchValue: string ) {
      return this.http.get<any>(this.apiURL + '/Include/' + searchValue);
    }

    getById( id: string ) {
      return this.http.get<any>( this.apiURL + '/' + id );
    }

    update( id: string, module: ModulesInterface ) {
      return this.http.put<any>( this.apiURL + '/' + id, module );
    }

    delete( id: string ) {
      return this.http.delete<any>( this.apiURL + '/' + id );
    }

}
