import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SavingInterface } from '../interfeces/saving.interface';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class SavingService {
  private apiURL = 'api/Administration/Savings';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    create( saving: SavingInterface ): Observable<any> {
      return this.http.post<any>(this.apiURL + '/Create', saving);
    }

    get( searchValue: string, year: number ) {
      return this.http.get<any>(this.apiURL + '/Search/' + year + '/' + searchValue);
    }

    getById( id: string ) {
      return this.http.get<any>( this.apiURL + '/' + id );
    }

    update( id: string, saving: SavingInterface ) {
      return this.http.put<any>( this.apiURL + '/' + id, saving );
    }

    delete( id: string ) {
      return this.http.delete<any>( this.apiURL + '/' + id );
    }

    getYears() {
      return this.http.get<any>(this.apiURL + '/Years');
    }
}
