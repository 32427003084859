import { Component, OnInit } from '@angular/core';
import { LoanReportService } from './services/loan-report.service';
import { DrawService } from '../../administration/draws/services/draw.service';
import { DrawInterface } from '../../administration/draws/interfaces/draw.interface';
import { AccountService } from '../../account/login/services/account.service';


@Component({
  selector: 'app-loan-report',
  templateUrl: './loan-report.component.html',
  styles: []
})
export class LoanReportComponent implements OnInit {
  loan: any;
  detailed = false;
  errorMessage = '';
  loadingData = false;
  drawSelected = 'null';
  amountOfPayments = 0;
  fullPayments = 0;
  incompletePayments = 0;
  amountIncompletePayments = 0;
  draws: DrawInterface[] = [];
  dateNow = `${this.formatDate(new Date().getDate())}-${this.formatDate( new Date().getMonth() + 1 )}-${new Date().getFullYear()}`

  constructor( private reportService: LoanReportService,
    private drawService: DrawService, private account: AccountService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.detailed = true;
    let rfc = this.account.getRfc();
    // this.drawService.get('null')
    //   .subscribe(data => {
    //     this.draws = data;
        this.loadData( rfc);
      // }, error => this.setErrorMessage(error));
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error.msg;
      this.loadingData = false;
    }
  }

  loadData(value: string) {
    this.reportService.get( value )
      .subscribe( (data) => {
        console.log(data);
        this.loan = data;

        this.loan.payments.forEach(year => {
          const arrayYear = Object.keys(year).map(function (key) {
            return [String(key), year[key]];
          });
    
          for (let index = 0; index < arrayYear.length; index++) {
            if ( arrayYear[index][0].slice(0, 9).toUpperCase() === 'FORTNIGHT' ) {
              if ( arrayYear[index][1] !== 0 ) {
                if ( arrayYear[index][1] === this.loan.discount ) {
                  this.fullPayments++;
                  this.amountOfPayments += arrayYear[index][1];
                } else {
                  this.incompletePayments++;
                  this.amountIncompletePayments += arrayYear[index][1];
                }
              }
            }
          }
        });
        this.loadingData = false;
      }, error => this.setErrorMessage(error));
  }

  searchData() {
    this.loadingData = true;
    if ( this.drawSelected === 'null' ) {
      this.detailed = false;
    } else {
      this.detailed = true;
    }
    this.loadData( this.drawSelected );
  }

  sumPayments(payments){
    console.log(payments);
    var sum = 0
    payments.forEach(b=> {

      sum += (b.fortnight1 +
              b.fortnight2 +
             b.fortnight3 +
             b.fortnight4 +
             b.fortnight5 +
             b.fortnight6 +
             b.fortnight7 +
             b.fortnight8 +
             b.fortnight9 +
             b.fortnight10 +
             b.fortnight11 +
             b.fortnight12 +
             b.fortnight13 +
             b.fortnight14 +
             b.fortnight15 +
             b.fortnight16 +
             b.fortnight17 +
             b.fortnight18 +
             b.fortnight19 +
             b.fortnight20 +
             b.fortnight11 +
             b.fortnight22 +
             b.fortnight23 +
             b.fortnight24)
    });
    console.log(sum);
    return sum;
  }
  
  getInterests(amount, interests){
    return amount * (interests / 100);
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return `0${value}`;
    }
    return value.toString();
  }

  getPayments( payments: any[] ): any[] {
    return payments.sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 );
  }
}
