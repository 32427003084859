import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpEvent } from '@angular/common/http';
import { AccountService } from '../components/account/login/services/account.service';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.accountService.getToken();
    req = req.clone({
      setHeaders: { Authorization: 'bearer ' + token }
    });
    return next.handle(req);
  }
  constructor(private accountService: AccountService) { }

}
