<article class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Carga Masiva</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-block animated fadeIn fast">
        <app-loading-data *ngIf="gettingData"></app-loading-data>

        <form [formGroup]="forma">
            <div class="row form-group">

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Año</label>
                        <select class="custom-select" formControlName="year">
                            <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Quincena</label>
                        <select class="custom-select" formControlName="fortnight">
                            <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group ">
                        <label class="control-label ">Documento</label>
                        <select class="custom-select" formControlName="document">
                            <option value="Ahorros">Ahorros</option>
                            <option value="Abonos">Abonos</option>
                            <option value="Liquidez">Liquidez</option>
                        </select>
                    </div>
                </div>

                <div *ngIf="allowReport" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right">
                    <button type="button" class="btn btn-outline-info" (click)="getPDF()"><i class="far fa-file-pdf"></i> PDF</button>
                </div>
            </div>
        </form>

        <div class="row form-group">
            <div class="col-12">
                <div class="custom-file">
                    <input #fileUpload type="file" (change)="fileChangeEvent($event)" class="custom-file-input" id="customFileLang" lang="es" accept=".xls, .xlsx">
                    <label class="custom-file-label" for="customFileLang">Seleccionar Archivo...</label>
                </div>
            </div>
        </div>

        <div *ngIf="selectedFileNames.length > 0" class="row form-group">
            <div class="col-12">
                <ul class="list-group">
                    <li class="list-group-item" *ngFor="let fileName of selectedFileNames; let i = index">
                        {{fileName}}
                    </li>
                </ul>
            </div>
        </div>

        <div *ngIf="uploadResult" class="row form-group">
            <div class="col-12" [innerHtml]="uploadResult">
            </div>
        </div>

        <div class="form-group row">
            <div class="col-12 text-center">
                <button [disabled]="isLoadingData || selectedFileNames.length === 0" type="button" class="btn btn-outline-success" (click)="upload()">
                    <i [ngClass]="{ 'far fa-save': !isLoadingData, 'fas fa-sync-alt fa-spin': isLoadingData }"></i>
                    <span *ngIf="!isLoadingData">Procesar</span>
                    <span *ngIf="isLoadingData" >
                        Procesando...
                    </span>
                </button>
                <button type="button" class="btn btn-outline-danger" (click)="reserForm()"><i class="fas fa-broom"></i> Limpiar</button>
            </div>
        </div>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>
    </div>
</article>