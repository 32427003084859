<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Reportes</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Abonos</h3>
                    </h3>
                </div>
                <div *ngIf="dataReport.length !== 0" class="col-md-6 text-right">
                    <button type="button" class="btn btn-outline-info" (click)="getPDF()"><i class="far fa-file-pdf"></i> PDF</button>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <div class="row">
            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <form role="search">
                    <div class="input-box">
                        <i class="fas fa-search"></i>
                        <input type="search" [(ngModel)]="searchValue" name="searchValue" autocomplete="off" placeholder="Buscar RFC ..." (keyup.enter)="searchData()">
                        <div class="underline"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && dataReport.length > 0">
        <div *ngIf="teacherName" class="row">
            <div class="col-12 text-center">
                <h2 class="m-1">{{ teacherName }}</h2>
            </div>
        </div>

        <table *ngIf="!detailed && dataReport.length > 0" class="table table-striped">
            <thead class="text-center">
                <tr>
                    <th scope="col">Año</th>
                    <th scope="col">Subtotal</th>
                </tr>
            </thead>

            <tbody>
                <tr class="text-right" *ngFor="let item of dataReport; let i = index">
                    <td>{{ item.year }}</td>
                    <td>{{ item.total | currency }}</td>
                </tr>
            </tbody>
        </table>

        <table *ngIf="detailed && dataReport.length > 0" class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Año</th>
                    <th scope="col">Qna 1<br />Qna 13</th>
                    <th scope="col">Qna 2<br />Qna 14</th>
                    <th scope="col">Qna 3<br />Qna 15</th>
                    <th scope="col">Qna 4<br />Qna 16</th>
                    <th scope="col">Qna 5<br />Qna 17</th>
                    <th scope="col">Qna 6<br />Qna 18</th>
                    <th scope="col">Qna 7<br />Qna 19</th>
                    <th scope="col">Qna 8<br />Qna 20</th>
                    <th scope="col">Qna 9<br />Qna 21</th>
                    <th scope="col">Qna 10<br />Qna 22</th>
                    <th scope="col">Qna 11<br />Qna 23</th>
                    <th scope="col">Qna 12<br />Qna 24</th>
                    <th scope="col">Subtotal</th>
                </tr>
            </thead>

            <tbody>
                <tr class="text-right" *ngFor="let item of dataReport; let i = index">
                    <td>{{ item.year }}</td>
                    <td>{{ item.qna_1 | currency }}<br />{{ item.qna_13 | currency }}</td>
                    <td>{{ item.qna_2 | currency }}<br />{{ item.qna_14 | currency }}</td>
                    <td>{{ item.qna_3 | currency }}<br />{{ item.qna_15 | currency }}</td>
                    <td>{{ item.qna_4 | currency }}<br />{{ item.qna_16 | currency }}</td>
                    <td>{{ item.qna_5 | currency }}<br />{{ item.qna_17 | currency }}</td>
                    <td>{{ item.qna_6 | currency }}<br />{{ item.qna_18 | currency }}</td>
                    <td>{{ item.qna_7 | currency }}<br />{{ item.qna_19 | currency }}</td>
                    <td>{{ item.qna_8 | currency }}<br />{{ item.qna_20 | currency }}</td>
                    <td>{{ item.qna_9 | currency }}<br />{{ item.qna_21 | currency }}</td>
                    <td>{{ item.qna_10 | currency }}<br />{{ item.qna_22 | currency }}</td>
                    <td>{{ item.qna_11 | currency }}<br />{{ item.qna_23 | currency }}</td>
                    <td>{{ item.qna_12 | currency }}<br />{{ item.qna_24 | currency }}</td>
                    <td>{{ item.total | currency }}</td>
                </tr>
            </tbody>
        </table>

        <div class="row">
            <div class="col-12 text-right">
                <h3 class="m-3 info">Total {{ amount | currency }}</h3>
            </div>
        </div>
    </div>
</article>