import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SavingReportService {

  private apiURL = 'api/Reports/Savings';;

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) {
      console.log(this.baseUrl);
    }

    get( rfc: string ) {
      return this.http.get<any>(this.apiURL + '/' + rfc);
    }
}
