import { Component, OnInit } from '@angular/core';
import { ConfigurationsService } from './services/configurations.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ConfigurationsInterface } from './interfaces/configurations.interface';
import { AccountService } from '../../account/login/services/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styles: []
})
export class ConfigurationsComponent implements OnInit {
  forma: UntypedFormGroup;
  imageBase64 = 'data:image/jpeg;base64,';
  working = false;
  sendData = false;
  errorMessage: string;
  configurations: ConfigurationsInterface =  {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    imageFOCAPS: '',
    imageSNTE: '',
    messagePDFSavings: '',
    messagePDF: '',
    nextCheck: 0,
    nextCheckLowStaff: 0
  };

  constructor( private configurationsService: ConfigurationsService,
    private accountService: AccountService, private router: Router ) { }

  ngOnInit() {
    this.working = true;
    this.createForm();
    this.configurationsService.get()
      .subscribe( data => {
        this.configurations = Object.assign({}, data);
        this.forma.setValue(this.configurations);
        this.working = false;
      }, err => this.setErrorMessage(err));
  }

  calculateAmount() {}

  sendForm(): void {
    this.sendData = true;
    this.configurationsService.update(this.configurations.id, this.configurations)
      .subscribe(data => this.sendData = false
        , err => this.setErrorMessage(err));
  }

  changeFOCAPSListener($event): void {
    if ( $event.target.files[0] ) {
      if ( this.validImageFile($event.target.files[0].name) ) {
        this.encodingImageBase64FOCAPS($event.target.files[0]);
      } else {
        this.errorMessage = `El formato no es correcto favor de verificarlo.`;
      }
    }
  }

  changeSNTEListener( $event ): void {
    if ($event.target.files[0]) {
      if (this.validImageFile($event.target.files[0].name)) {
        this.encodingImageBase64SNTE($event.target.files[0]);
      } else {
        this.errorMessage = `El formato no es correcto favor de verificarlo.`;
      }
    }
  }

  validImageFile( fileName: string ): boolean {
    if ( fileName.slice(-3).toUpperCase() === 'JPG' || fileName.slice(-3).toUpperCase() === 'PNG' ) {
      return true;
    } else {
      return false;
    }
  }

  createForm(): void {
    this.forma = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      userId: new UntypedFormControl(''),
      date: new UntypedFormControl(''),
      active: new UntypedFormControl(''),
      imageFOCAPS: new UntypedFormControl(''),
      imageSNTE: new UntypedFormControl(''),
      messagePDFSavings: new UntypedFormControl('', [Validators.minLength(6)]),
      messagePDF: new UntypedFormControl('', [Validators.minLength(6)]),
      lastUpdated: new UntypedFormControl(''),
      userIdLastUpdated: new UntypedFormControl(''),
      nextCheck: new UntypedFormControl('0', [Validators.required]),
      nextCheckLowStaff: new UntypedFormControl('0', [Validators.required])
    });
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.working = false;
      this.sendData = false;
    }
  }

  encodingImageBase64FOCAPS(file: File) {
    const reader = new FileReader();
    reader.onload = this._handleReaderLoadedFOCAPS.bind(this);
    reader.readAsBinaryString(file);
  }

  _handleReaderLoadedFOCAPS(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.configurations.imageFOCAPS = `${ this.imageBase64 }${ btoa(binaryString) }`;
  }

  encodingImageBase64SNTE(file: File) {
    const reader = new FileReader();
    reader.onload = this._handleReaderLoadedSNTE.bind(this);
    reader.readAsBinaryString(file);
  }

  _handleReaderLoadedSNTE(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.configurations.imageSNTE = `${ this.imageBase64 }${ btoa(binaryString) }`;
  }
}
