import { Component, OnInit } from '@angular/core';
import { LoanInfo } from './interface/LoanInfo';
import { LoanService } from '../administration/loans/services/loan.service';
import { AccountService } from '../account/login/services/account.service';

@Component({
  selector: 'app-loan-calculator',
  templateUrl: './loan-calculator.component.html',
  styleUrls: ['./loan-calculator.component.css']
})
export class LoanCalculatorComponent implements OnInit {
  data: Array<LoanInfo>;
  loanTypes: Array<string>;
  selected = 0;
  selectedType = '';
  loadingData = true;
  amounts: Array<number>;
  showTable =  false;
  selectedLoan: Array<LoanInfo>;
  teacherType: '';

  constructor(
    private loanService: LoanService,
    private accountService: AccountService) {
    this.data = [];
    this.loanTypes = [];
  }

  async ngOnInit() {
    this.teacherType = this.accountService.getType();
    this.data = await this.loanService.getInfo(this.teacherType).toPromise();
    const flags = [], output = [], l = this.data.length;
    for (let i = 0; i < l; i++) {
      if (flags[this.data[i].type]) { continue; }
      flags[this.data[i].type] = true;
      output.push(this.data[i].type);
    }
    this.loanTypes = output;
    this.selectedType = this.loanTypes[0];
    this.amounts = Array.from(new Set(this.data.filter(d => d.type === this.loanTypes[0]).map(s => s.loan)));
    this.selected = this.amounts[0];
    this.loadingData = !this.loadingData;
  }

  onChange( amount: number ) {
    this.selected = amount;
  }

  onChangeType( type: string) {
    this.amounts = Array.from(new Set(this.data.filter(d => d.type === type).map(s => s.loan)));
    this.selected = this.amounts[0];
  }

  showData() {

    this.showTable = true;
    this.selectedLoan = this.data.filter(d => d.loan == this.selected && d.teachers === this.teacherType);

  }
}
