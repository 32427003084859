import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { ProfilesService } from './services/profiles.service';
import { OpenDirective } from '../../modals/open.directive';
import { AlertComponent } from '../../modals/alert.component';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styles: []
})
export class ProfileListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];
  @ViewChild( OpenDirective, { static: true } ) adHost: OpenDirective;

  constructor( private componentFactoryResolver: ComponentFactoryResolver,
    private profileService: ProfilesService,
    public activatedRoute: ActivatedRoute, private router: Router ) {
      this.activatedRoute.params
      .subscribe(params => {
        if ( params['searchValue'] ) {
          this.searchValue = params['searchValue'];
          this.loadData(this.searchValue);
        } else {
          this.loadData('null');
        }
      });
  }

  search(): void {
  }

  ngOnInit() { }

  errorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.error[''][0]);
      this.loadingData = false;
    }
  }

  showConfirm( id: string, name: string ) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory( AlertComponent );

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<AlertComponent>componentRef.instance).title = 'Alerta';
    (<AlertComponent>componentRef.instance).message = `Seguro que desea eliminar el perfil ${ name }`;
    (<AlertComponent>componentRef.instance).open();
    (<AlertComponent>componentRef.instance).alertOutput
      .subscribe(success => {
        this.deleteItem( id );
      }, error => this.errorMessage(error) );
  }

  deleteItem( id: string ) {
    this.profileService.delete( id )
        .subscribe(success => {
          this.loadData('null');
        }
        , error => this.errorMessage(error));
  }

  loadData(value: string) {
    this.loadingData = true;
    this.profileService.get(value)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
      console.clear();
    },
    error => this.errorMessage(error));
  }

  searchProfile() {
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search );
  }

  agregarProfile() {
    const text = this.searchValue;
    this.router.navigate( ['configuration/profiles/profile', '', text] );
  }

}
