<div class="content">
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">Administración</li>
          <li class="breadcrumb-item"><a [routerLink]="['administration/loans']" routerLinkActive="router-link-active">Prestamos</a></li>
      </ol>
  </nav>

  <div class="title-search-block">
      <div class="title-block">
          <div class="row">
              <div class="col-md-6">
                  <h3 class="title">
                      <h3 class="title">Agregar</h3>
                  </h3>
              </div>
          </div>
      </div>
  </div>

  <app-loading-data *ngIf="gettingData"></app-loading-data>

  <div class="card card-block animated fadeIn fast">
      <form [formGroup]="forma" (ngSubmit)="sendForm()">
          <div class="row">
              <div class="col-md-12 text-center">
                  <h4 class="info"><b>{{ payment.teacher.name | uppercase }}</b> <small>({{ amount | currency }})</small></h4>
                  <hr>
              </div>
          </div>

          <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                  <label class="control-label"> Tipo</label>
                  <select class="custom-select" formControlName="type">
                      <option value="Abono">Abono</option>
                      <option value="Liquidación">Liquidación</option>
                  </select>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2" >
                  <div class="form-group"
                      [ngClass]="{'has-error': !forma.controls['movementDate'].valid && forma.controls['movementDate'].touched, 'has-success': forma.controls['movementDate'].valid && forma.controls['movementDate'].touched }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Fecha</label>
                      <input type="date" class="form-control underlined" formControlName="movementDate" >
                      <span *ngIf="forma.controls['movementDate'].touched && forma.controls['movementDate'].errors?.required"
                          class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>
          </div>

          <div class="row">
              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight1'].valid && forma.controls['fortnight1'].touched, 'has-success': forma.controls['fortnight1'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 1</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight1" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight1'].touched && forma.controls['fortnight1'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight2'].valid && forma.controls['fortnight2'].touched, 'has-success': forma.controls['fortnight2'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 2</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight2" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight2'].touched && forma.controls['fortnight2'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight3'].valid && forma.controls['fortnight3'].touched, 'has-success': forma.controls['fortnight3'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 3</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight3" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight3'].touched && forma.controls['fortnight3'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight4'].valid && forma.controls['fortnight4'].touched, 'has-success': forma.controls['fortnight4'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 4</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight4" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight4'].touched && forma.controls['fortnight4'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight5'].valid && forma.controls['fortnight5'].touched, 'has-success': forma.controls['fortnight5'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 5</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight5" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight5'].touched && forma.controls['fortnight5'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight6'].valid && forma.controls['fortnight6'].touched, 'has-success': forma.controls['fortnight6'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 6</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight6" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight6'].touched && forma.controls['fortnight6'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>
          </div>

          <div class="row">
              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight7'].valid && forma.controls['fortnight7'].touched, 'has-success': forma.controls['fortnight7'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 7</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight7" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight7'].touched && forma.controls['fortnight7'].errors?.required" class="has-error ">
                              El campo es requerido.
                          </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight8'].valid && forma.controls['fortnight8'].touched, 'has-success': forma.controls['fortnight8'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 8</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight8" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight8'].touched && forma.controls['fortnight8'].errors?.required" class="has-error ">
                              El campo es requerido.
                          </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight9'].valid && forma.controls['fortnight9'].touched, 'has-success': forma.controls['fortnight9'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 9</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight9" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight9'].touched && forma.controls['fortnight9'].errors?.required" class="has-error ">
                              El campo es requerido.
                          </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight10'].valid && forma.controls['fortnight10'].touched, 'has-success': forma.controls['fortnight10'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 10</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight10" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight10'].touched && forma.controls['fortnight10'].errors?.required" class="has-error ">
                              El campo es requerido.
                          </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight11'].valid && forma.controls['fortnight11'].touched, 'has-success': forma.controls['fortnight11'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 11</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight11" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight11'].touched && forma.controls['fortnight11'].errors?.required" class="has-error ">
                              El campo es requerido.
                          </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight12'].valid && forma.controls['fortnight12'].touched, 'has-success': forma.controls['fortnight12'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 12</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight12" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight12'].touched && forma.controls['fortnight12'].errors?.required" class="has-error ">
                              El campo es requerido.
                          </span>
                  </div>
              </div>
          </div>

          <div class="row">
              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight13'].valid && forma.controls['fortnight13'].touched, 'has-success': forma.controls['fortnight13'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 13</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight13" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight13'].touched && forma.controls['fortnight13'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight14'].valid && forma.controls['fortnight14'].touched, 'has-success': forma.controls['fortnight14'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 14</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight14" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight14'].touched && forma.controls['fortnight14'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight15'].valid && forma.controls['fortnight15'].touched, 'has-success': forma.controls['fortnight15'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 15</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight15" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight15'].touched && forma.controls['fortnight15'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight16'].valid && forma.controls['fortnight16'].touched, 'has-success': forma.controls['fortnight16'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 16</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight16" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight16'].touched && forma.controls['fortnight16'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight17'].valid && forma.controls['fortnight17'].touched, 'has-success': forma.controls['fortnight17'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 17</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight17" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight17'].touched && forma.controls['fortnight17'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight18'].valid && forma.controls['fortnight18'].touched, 'has-success': forma.controls['fortnight18'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 18</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight18" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight18'].touched && forma.controls['fortnight18'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>
          </div>

          <div class="row">
              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight19'].valid && forma.controls['fortnight19'].touched, 'has-success': forma.controls['fortnight19'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 19</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight19" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight19'].touched && forma.controls['fortnight19'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight20'].valid && forma.controls['fortnight20'].touched, 'has-success': forma.controls['fortnight20'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 20</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight20" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight20'].touched && forma.controls['fortnight20'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight21'].valid && forma.controls['fortnight21'].touched, 'has-success': forma.controls['fortnight21'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 21</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight21" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight21'].touched && forma.controls['fortnight21'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight22'].valid && forma.controls['fortnight22'].touched, 'has-success': forma.controls['fortnight22'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 22</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight22" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight22'].touched && forma.controls['fortnight22'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight23'].valid && forma.controls['fortnight23'].touched, 'has-success': forma.controls['fortnight23'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 23</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight23" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight23'].touched && forma.controls['fortnight23'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <div class="form-group" [ngClass]="{'has-error': !forma.controls['fortnight24'].valid && forma.controls['fortnight24'].touched, 'has-success': forma.controls['fortnight24'].valid }">
                      <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. # 24</label>
                      <input type="number" class="form-control underlined" formControlName="fortnight24" (change)="calculateAmount()">
                      <span *ngIf="forma.controls['fortnight24'].touched && forma.controls['fortnight24'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                  </div>
              </div>
          </div>

          <div class="form-group text-center ">
              <button [disabled]="!forma.valid || sendingData || amount == 0" class="btn btn-outline-success" >
                  <i [ngClass]="{ 'far fa-save': !sendingData, 'fas fa-sync-alt fa-spin': sendingData } "></i>
                  <span *ngIf="!sendingData ">Guardar</span>
                  <span *ngIf="sendingData ">Guardando...</span>
              </button>
          </div>

          <app-error-message *ngIf="message" [message]="message"></app-error-message>
      </form>

      <div class="row">
          <a class="btn btn-outline-info " [routerLink]="['administration/loans']" ><i class="fas fa-arrow-left "></i> Regresar</a>
      </div>

  </div>
</div>