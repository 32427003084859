<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Reportes</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Ahorros</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <div class="row">
            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="input-box">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Inicio</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <input type="date" name="inicio" [value]="inicio" [(ngModel)]="inicio" (keyup.enter)="loadData()">
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1">
                            <label>Al</label>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <input type="date" name="fin" [value]="fin" [(ngModel)]="fin" (keyup.enter)="loadData()">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && dataReport.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Acción</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Total</span>
                        </div>
                    </div>
                </div>
            </li>

            <li class="item" *ngFor="let item of dataReport; let i = index">
                <div class="item-row">
                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Acción</div>
                        <div>
                            <span>{{ item.tramite }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Total</div>
                        <div>
                            <span>{{ item.total | currency }}</span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <div class="row">
            <div class="col-12 text-center">
                <h3 [ngClass]="{'info': balancePositive, 'required': !balancePositive }" class="m-3">Balance {{ amountBalance | currency }}</h3>
            </div>
        </div>
    </div>
</article>