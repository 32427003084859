import { Component, Output, EventEmitter } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-alert',
  template: `
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"><i class="fas fa-exclamation-triangle"></i> {{ title }}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body"><p>{{ message }}</p></div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="ok()" >
              Si
            </button>
            <button type="button" class="btn btn-secondary" (click)="cancel()" >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  `
})
export class AlertComponent {
  public title: string;
  public message: string;

  @Output() public alertOutput = new EventEmitter<any>();
  constructor() {}

  open() {
    $('#myModal').modal();
  }

  ok() {
    $('#myModal').modal('hide');
    this.alertOutput.emit(true);
  }

  cancel() {
    $('#myModal').modal('hide');
  }
}
