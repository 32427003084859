import { Component, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { OpenDirective } from '../../modals/open.directive';
import { ApplicationService } from './services/application.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent } from '../../modals/alert.component';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styles: []
})
export class ApplicationListComponent {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];
  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;

  constructor( private componentFactoryResolver: ComponentFactoryResolver,
    private applicationService: ApplicationService,
    public activatedRoute: ActivatedRoute,
    private router: Router ) {
    this.activatedRoute.params.subscribe(params => {
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
        this.loadData(this.searchValue);
      } else {
        this.loadData('null');
      }
    });
  }

  errorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.error[''][0]);
      this.loadingData = false;
    }
  }

  loadData(value: string) {
    this.loadingData = true;
    this.applicationService.get(value)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
    },
    error => this.errorMessage(error));
  }

  search() {
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search );
  }

  agregar() {
    const text = this.searchValue;
    this.router.navigate( ['configuration/applications/add', '', text] );
  }

  deleteItem(id: string) {
    this.applicationService.delete(id).subscribe(
      success => {
        this.loadData('null');
      },
      error => this.errorMessage(error)
    );
  }

  showConfirm(id: string, name: string) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      AlertComponent
    );

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<AlertComponent>componentRef.instance).title = 'Alerta';
    (<AlertComponent>(componentRef.instance)).message = `Seguro que desea eliminar la aplicación ${name}.`;
    (<AlertComponent>componentRef.instance).open();
    (<AlertComponent>componentRef.instance).alertOutput.subscribe(
      success => {
        this.deleteItem(id);
      },
      error => this.errorMessage(error)
    );
  }

}
