import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UsersService } from './services/users.service';
import { AccountService } from '../../account/login/services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfilesService } from '../profiles/services/profiles.service';
import { IProfile } from '../profiles/interfaces/iprofile';
import { UsersInterface } from './interfeces/users.interface';
import { ApplicationInterface } from '../applications/interface/application.interface';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styles: []
})
export class UserComponent {
  title = 'Agregar';
  returnPath = '../../../';
  forma: UntypedFormGroup;
  message: string;
  loadingData = false;
  sendData = false;
  searchValue = '';
  profiles: IProfile[];
  applications: ApplicationInterface[];
  user: UsersInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    profileId: '00000000-0000-0000-0000-000000000000',
    username: '',
    password: '',
    paternal: '',
    maternal: '',
    name: '',
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    profile: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: true,
      lastUpdated: new Date,
      userIdLastUpdated: this.accountService.getUserId(),
      name: 'N/A'
    }
  };

  constructor( private usersService: UsersService, private accountService: AccountService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private profilesService: ProfilesService ) {
    this.loadingData = true;
    this.forma = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      userId: new UntypedFormControl(''),
      date: new UntypedFormControl(''),
      active: new UntypedFormControl(''),
      profileId: new UntypedFormControl('', [Validators.required]),
      username: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      paternal: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
      maternal: new UntypedFormControl('', [Validators.minLength(3)]),
      name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
      lastUpdated: new UntypedFormControl(''),
      userIdLastUpdated: new UntypedFormControl(''),
      profile: new UntypedFormControl('')
    });

    this.profilesService.get('null')
    .subscribe(data => this.profiles = data,
      error => this.errorMessage(error));

    this.activatedRoute.params.subscribe(params => {
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }

      if (params['id']) {
        this.title = 'Editar';
        this.user.id = params['id'];
        this.usersService.getById(params['id']).subscribe(
          data => {
            this.returnPath = '../../';
            this.user = Object.assign({}, data);
            this.forma.setValue(this.user);
          },
          error => this.errorMessage(error)
        );
      } else {
        this.forma.setValue(this.user);
      }
      this.loadingData = false;
    });
  }

  sendForm( ) {
    this.sendData = true;
    this.user = Object.assign({}, this.forma.value);
    this.user.date = new Date();
    if (this.title === 'Agregar') {
      this.usersService.create(this.user)
        .subscribe(
          success => this.router.navigate(['/configuration/users', this.searchValue]),
          error => this.errorMessage(error)
        );
    } else {
      this.user.userIdLastUpdated = this.accountService.getUserId();
      this.usersService
        .update(this.user.id, this.user)
        .subscribe(
          success => this.router.navigate(['/configuration/users', this.searchValue]),
          error => this.errorMessage(error)
        );
    }
  }

  errorMessage( error: any ) {
    console.log(error);
    if (error && error.error) {
      this.message = error.error;
      this.sendData = false;
    }
  }
}
