import { Component, OnInit } from '@angular/core';
import { AccountService } from '../account/login/services/account.service';
import { ModulesInterface } from '../configuration/modules/interfaces/modules.interface';
import { ConfigurationsService } from '../configuration/configurations/services/configurations.service';
import { ConfigurationsInterface } from '../configuration/configurations/interfaces/configurations.interface';
import { ApplicationInterface } from '../configuration/applications/interface/application.interface';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html'
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  loadingData = false;
  menu: ModulesInterface[] = [];
  configurations: ConfigurationsInterface =  {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    imageFOCAPS: '',
    imageSNTE: '',
    messagePDFSavings: '',
    messagePDF: '',
    nextCheck: 0,
    nextCheckLowStaff: 0
  };

  constructor( private accountService: AccountService,
    private configurationsService: ConfigurationsService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.configurationsService.get()
      .subscribe(configurations => {
        this.configurations = configurations;
          // this.menu = [
          //     {name: 'Inicio', applications: [
          //       {path: 'usuarios/ahorros', name: 'Ahorros'},
          //       {path: 'usuarios/prestamos', name: 'prestamos'}
          //     ]
          //   }
          // ];
            this.loadingData = false;
        // this.accountService.getMenu().subscribe(
        //   data => {
        //   },
        //   error => console.error(error)
        // );
    }, err => console.error(err));
  }

  removeApplicationsNoVisibles(apps: ApplicationInterface[]): ApplicationInterface[] {
    let index = 0;
    apps.forEach(app => {
      if ( app.visible === false ) {
        apps.splice(index, 1);
      }
      index += 1;
    });

    return apps;
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
