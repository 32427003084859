import { Routes } from '@angular/router';
import { TeacherListComponent } from './teacher-list.component';
import { TeacherComponent } from './teacher.component';
import { UnsubscribeComponent } from './unsubscribe.component';

export const TEACHERS_ROUTES: Routes = [
    { path: 'add', component: TeacherComponent },
    { path: 'add/:id/:searchValue', component: TeacherComponent },
    { path: 'edit/:id', component: TeacherComponent },
    { path: 'edit/:id/:searchValue', component: TeacherComponent },
    { path: 'unsubscribe/:id', component: UnsubscribeComponent },
    { path: 'unsubscribe/:id/:searchValue', component: UnsubscribeComponent },
    { path: '', component: TeacherListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
