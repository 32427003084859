import { Component, OnInit } from '@angular/core';
import { SavingReportService } from './service/saving-report.service';
import { CurrencyPipe } from '@angular/common';
import { ConfigurationsService } from '../../configuration/configurations/services/configurations.service';
import { ConfigurationsInterface } from '../../configuration/configurations/interfaces/configurations.interface';
import { AccountService } from '../../account/login/services/account.service';
declare let jsPDF;

@Component({
  selector: 'app-saving-report',
  templateUrl: './saving-report.component.html',
  styles: []
})
export class SavingReportComponent implements OnInit {
  dataReport: any[] = [];
  amount = 0;
  amountInterests = 0;
  detailed = false;
  searchValue = '';
  errorMessage = '';
  loadingData = false;
  teacherName = '';
  configurations: ConfigurationsInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    imageFOCAPS: '',
    imageSNTE: '',
    messagePDFSavings: '',
    messagePDF: '',
    nextCheck: 0,
    nextCheckLowStaff: 0
  };

  constructor(private reportService: SavingReportService, private accountService: AccountService,
    private currencyPipe: CurrencyPipe, private configurationsService: ConfigurationsService) { }

  ngOnInit() {
    this.loadingData = true;
    this.detailed = true;
    const rfc = this.accountService.getRfc();
    this.configurationsService.get()
      .subscribe(configurations => {
        this.configurations = configurations;
        this.loadData(rfc);
      }, err => this.setErrorMessage(err));
  }

  setErrorMessage(error) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  loadData(value: string) {
    this.reportService.get(value)
      .subscribe((data) => {
        console.log(data);
        this.dataReport = data.reduce((a,b) => a.concat(b),[]);
        if (this.dataReport.length > 0) {
          this.calculateAmount(this.dataReport);
          if (this.detailed) {
            this.teacherName = this.dataReport[0].name;
          } else {
            this.teacherName = '';
          }
        }
        this.loadingData = false;
      }, error => this.setErrorMessage(error));
  }

  calculateAmount(data: any[]) {
    this.amount = 0;
    var contador = 1;
    this.amountInterests = 0;
    var interestWithImport = 0;
    var teacherRFC = '';
    data.forEach(year => {
      if (year.rfc !== teacherRFC) {
        teacherRFC = year.rfc;
        interestWithImport = 0;
      }
      var interestPeritem = 0;
      if (this.detailed) {
        const arrayYear = Object.keys(year).map(function (key) {
          return [String(key), year[key]];
        });

        for (let index = 0; index < arrayYear.length; index++) {
          if (arrayYear[index][0].slice(0, 3).toUpperCase() === 'QNA') {
            if (arrayYear[index][1] !== 0) {
              var interestFortnight = (interestWithImport + arrayYear[index][1]) * 0.00208;
              interestPeritem += interestFortnight;
              interestWithImport += arrayYear[index][1] + interestFortnight;
            }
          }
        }
        year.interests = parseFloat(interestPeritem.toFixed(2));
        this.amountInterests += year.interests
      }
      this.amount += year.total;
    });
  }

  getPDF(): void {
    const totalPagesExp = '{total_pages_count_string}';
    const head = this.getHeader();
    const body = this.setBodyData(this.dataReport);
    if (this.detailed) {
      this.generateDetailedDocument(head, body, totalPagesExp, this.teacherName,
        this.searchValue, this.configurations.imageSNTE, this.configurations.imageFOCAPS);
    } else {
      this.generateGlobalDocument(head, body, totalPagesExp, this.configurations.imageSNTE,
        this.configurations.imageFOCAPS);
    }
  }

  generateDetailedDocument(head: any[], body: any[], totalPagesExp: string, teacherName: string,
    RFC: string, imageSNTE: string, imageFOCAPS: string): void {
    const doc = new jsPDF('l');
    const pageContent = function (data) {
      // HEADER
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      if (imageSNTE) {
        doc.addImage(imageSNTE, 'JPEG', data.settings.margin.left, 15, 30, 20);
      }

      if (imageFOCAPS) {
        doc.addImage(imageFOCAPS, 'JPEG', doc.internal.pageSize.width - 45, 15, 30, 20);
      }

      doc.text('FONDO DE CAPAPITALIZACION Y AHORRO', data.settings.margin.left + 95, 20);
      doc.text('PARA APOYO A PRESTACIONES SOCIALES', data.settings.margin.left + 95, 24);
      doc.text('ESTADO DE CUENTA INDIVIDUAL', data.settings.margin.left + 105, 31);
      doc.text(`NOMBRE: ${teacherName}`, data.settings.margin.left + 5, 40);
      doc.text(`RFC: ${RFC}`, data.settings.margin.left + 5, 44);

      // FOOTER
      let str = 'Página ' + data.pageCount;
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }

      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
    };

    doc.autoTable({
      head: head,
      body: body,
      didDrawPage: pageContent,
      margin: { top: 47 },
      headStyles: {
        halign: 'center'
      },
      columnStyles: {
        year: {
          halign: 'right',
          valign: 'middle'
        },
        qna_1_13: {
          halign: 'right'
        },
        qna_2_14: {
          halign: 'right'
        },
        qna_3_15: {
          halign: 'right'
        },
        qna_4_16: {
          halign: 'right'
        },
        qna_5_17: {
          halign: 'right'
        },
        qna_6_18: {
          halign: 'right'
        },
        qna_7_19: {
          halign: 'right'
        },
        qna_8_20: {
          halign: 'right'
        },
        qna_9_21: {
          halign: 'right'
        },
        qna_10_22: {
          halign: 'right'
        },
        qna_11_23: {
          halign: 'right'
        },
        qna_12_24: {
          halign: 'right'
        },
        subtotal: {
          halign: 'right',
          valign: 'middle'
        }
      },
      theme: 'grid'
    });
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    doc.save(`${this.dataReport[0].rfc}.pdf`);
  }

  getTeacherName(): string {
    return this.teacherName;
  }

  generateGlobalDocument(head: any[], body: any[], totalPagesExp: string, imageSNTE: string, imageFOCAPS: string): void {
    const doc = new jsPDF();
    const pageContent = function (data) {
      // HEADER
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      if (imageSNTE) {
        doc.addImage(imageSNTE, 'JPEG', data.settings.margin.left, 15, 30, 20);
      }

      if (imageFOCAPS) {
        doc.addImage(imageFOCAPS, 'JPEG', doc.internal.pageSize.width - 45, 15, 30, 20);
      }
      doc.text('FONDO DE CAPAPITALIZACION Y AHORRO', data.settings.margin.left + 55, 20);
      doc.text('PARA APOYO A PRESTACIONES SOCIALES', data.settings.margin.left + 55, 24);

      // FOOTER
      let str = 'Página ' + data.pageCount;
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }

      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
    };

    doc.autoTable({
      head: head,
      body: body,
      didDrawPage: pageContent,
      margin: { top: 40 },
      headStyles: {
        halign: 'center'
      },
      columnStyles: {
        subtotal: {
          halign: 'right'
        },
        year: {
          halign: 'right'
        }
      },
      theme: 'grid'
    });
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    doc.save(`Global.pdf`);
  }

  getHeader(): any[] {
    if (this.detailed) {
      return [
        {
          year: 'Año', qna_1_13: 'QNA 1\nQNA 13', qna_2_14: 'QNA 2\nQNA 14', qna_3_15: 'QNA 3\nQNA 14', qna_4_16: 'QNA 4\nQNA 16',
          qna_5_17: 'QNA 5\nQNA 17', qna_6_18: 'QNA 6\nQNA 18', qna_7_19: 'QNA 7\nQNA 19', qna_8_20: 'QNA 8\nQNA 20',
          qna_9_21: 'QNA 9\nQNA 21', qna_10_22: 'QNA 10\nQNA 22', qna_11_23: 'QNA 11\nQNA 23', qna_12_24: 'QNA 12\nQNA 24',
          subtotal: 'Subtotal\nInteres'
        }
      ];
    } else {
      return [
        { year: 'Año', subtotal: 'Subtotal' }
      ];
    }
  }

  setBodyData(data: any[]): any[] {
    const body = [];
    if (this.detailed) {
      data.forEach(item => {
        body.push({
          year: item.year,
          qna_1_13: `${this.currencyPipe.transform(item.qna_1)}\n${this.currencyPipe.transform(item.qna_13)}`,
          qna_2_14: `${this.currencyPipe.transform(item.qna_2)}\n${this.currencyPipe.transform(item.qna_14)}`,
          qna_3_15: `${this.currencyPipe.transform(item.qna_3)}\n${this.currencyPipe.transform(item.qna_15)}`,
          qna_4_16: `${this.currencyPipe.transform(item.qna_4)}\n${this.currencyPipe.transform(item.qna_16)}`,
          qna_5_17: `${this.currencyPipe.transform(item.qna_5)}\n${this.currencyPipe.transform(item.qna_17)}`,
          qna_6_18: `${this.currencyPipe.transform(item.qna_6)}\n${this.currencyPipe.transform(item.qna_18)}`,
          qna_7_19: `${this.currencyPipe.transform(item.qna_7)}\n${this.currencyPipe.transform(item.qna_19)}`,
          qna_8_20: `${this.currencyPipe.transform(item.qna_8)}\n${this.currencyPipe.transform(item.qna_20)}`,
          qna_9_21: `${this.currencyPipe.transform(item.qna_9)}\n${this.currencyPipe.transform(item.qna_21)}`,
          qna_10_22: `${this.currencyPipe.transform(item.qna_10)}\n${this.currencyPipe.transform(item.qna_22)}`,
          qna_11_23: `${this.currencyPipe.transform(item.qna_11)}\n${this.currencyPipe.transform(item.qna_23)}`,
          qna_12_24: `${this.currencyPipe.transform(item.qna_12)}\n${this.currencyPipe.transform(item.qna_24)}`,
          subtotal: `${this.currencyPipe.transform(item.total)}\n${this.currencyPipe.transform(item.interests)}`
        });
      });
      body.push({
        year: `SUBTOTAL
        INTERES
        TOTAL`,
        subtotal: `${this.currencyPipe.transform(this.amount)}
        ${ this.currencyPipe.transform(this.amountInterests)}
        ${ this.currencyPipe.transform(this.amount)}`
      });
      body[body.length - 1].year = {
        content: `SUBTOTAL
        INTERES
        TOTAL`,
        colSpan: 13, styles: { halign: 'right', fontStyle: 'bold' }
      };
      body[body.length - 1].subtotal = {
        content: `${this.currencyPipe.transform(this.amount)}
      ${ this.currencyPipe.transform(this.amountInterests)}
        ${ this.currencyPipe.transform(this.amount + this.amountInterests)}`, styles: { halign: 'right', fontStyle: 'bold' }
      };
    } else {
      data.forEach(item => {
        body.push({
          year: item.year,
          subtotal: this.currencyPipe.transform(item.total)
        });
      });

      body.push({ year: `Subtotal :${this.currencyPipe.transform(this.amount)}` });
      body[body.length - 1].year = {
        content: `Subtotal :${this.currencyPipe.transform(this.amount)}`,
        colSpan: 2, styles: { halign: 'right', fontStyle: 'bold' }
      };
    }
    return body;
  }

  searchData() {
    this.detailed = true;
    this.loadingData = true;
    let search = this.searchValue;
    if (search === '') {
      this.detailed = false;
      search = 'null';
    }
    this.loadData(search);
  }

}
