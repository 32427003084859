import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaymentInterface } from '../interfaces/payment.interface';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class PaymentService {
  private apiURL = 'api/Administration/Payments';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

    create( saving: PaymentInterface ): Observable<any> {
      return this.http.post<any>(this.apiURL + '/Create', saving);
    }

    get( searchValue: string, year: number ) {
      return this.http.get<any>(this.apiURL + '/Search/' + year + '/' + searchValue);
    }

    getYears() {
      return this.http.get<any>(this.apiURL + '/Years');
    }

    getById( id: string ) {
      return this.http.get<any>( this.apiURL + '/' + id );
    }
}
