import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IUserInfo } from '../interfaces/iUserInfo';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApplicationInterface } from '../../../configuration/applications/interface/application.interface';
import { API_ENDPOINT } from '../../../../../config/config';

@Injectable()
export class AccountService {
  private apiURL = this.baseUrl + 'Account';
  constructor( private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
              private route: Router ) {}

  login(userInfo: IUserInfo): Observable<any> {
    return this.http.post<any>(this.apiURL + '/Auth', userInfo);
  }

  register(userInfo: IUserInfo): Observable<any> {
    return this.http.post<any>(this.apiURL + '/CreateUser', userInfo);
  }

  recovery(request){
    return this.http.post<any>(this.apiURL + '/RecoveryPassword', request);
  }

  updatePassword(request){
    return this.http.post<any>(this.apiURL + '/UPdatePassword', request);
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  setRecovery(){
    localStorage.setItem('recovery', 'true');
  }
  removeRecovery(){
    localStorage.removeItem('recovery');
  }
  isRecovery(){
    const item = localStorage.getItem('recovery');
    return JSON.parse(item);
  }
  getExpirationToken(): string {
    const item = localStorage.getItem('tokenExpiration');
    return item;
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
  }
  isLogged(): boolean {
    const expiration = this.getExpirationToken();
    if ( !expiration ) {
      return false;
    }
    const now = new Date().getTime();
    const dateExpiration = new Date(expiration);

    if ( now >= dateExpiration.getTime() ) {
      this.logout();
      return false;
    } else {
      return true;
    }
  }

  getUserId() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.getToken());
    return decodedToken.user_id;
  }

  getUserName() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.getToken());
    return decodedToken.name;
  }
  getRfc() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.getToken());
    return decodedToken.rfc;
  }

  getType() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.getToken());
    return decodedToken.type;
  }

  getUserProfile() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.getToken());
    return decodedToken.profile;
  }

  getUserProfileId() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.getToken());
    return decodedToken.profileID;
  }

  getMenu() {
    const profileId = this.getUserProfileId();
    return this.http.get<any>(this.apiURL + '/' + profileId);
  }

  getApplicationsMenu() {
    const profileId = this.getUserProfileId();
    return this.http.get<any>(this.apiURL + '/applications/' + profileId);
  }

  getApplications(module: string, app: string) {
    const profileId = this.getUserProfileId();
    return this.http.get<any>(this.apiURL + '/' + profileId + '/' + module + '/' + app);
  }

  havePermission(apps: ApplicationInterface[], appName: string): boolean {
    let permission = false;
    if ( apps ) {
      apps.forEach(app => {
        if ( app.name === appName ) {
          permission = true;
        }
      });
    }
    return permission;
  }
}
