import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ProfilesComponent } from './components/configuration/profiles/profiles.component';
import { PROFILES_ROUTES } from './components/configuration/profiles/profiles.routes';
import { AuthGuardService } from './services/auth-guard.service';
import { ModulesComponent } from './components/configuration/modules/modules.component';
import { MODULES_ROUTES } from './components/configuration/modules/modules.routes';
import { ApplicationsComponent } from './components/configuration/applications/applications.component';
import { APPLICATIONS_ROUTES } from './components/configuration/applications/applications.routes';
import { PermissionsComponent } from './components/configuration/permissions/permissions.component';
import { UsersComponent } from './components/configuration/users/users.component';
import { USERS_ROUTES } from './components/configuration/users/users.routes';
import { TeachersComponent } from './components/administration/teachers/teachers.component';
import { TEACHERS_ROUTES } from './components/administration/teachers/teachers.routes';
import { LOANS_ROUTES } from './components/administration/loans/loans.routes';
import { LoansComponent } from './components/administration/loans/loans.component';
import { SavingsComponent } from './components/administration/savings/savings.component';
import { SAVINGS_ROUTES } from './components/administration/savings/savings.routes';
import { DrawsComponent } from './components/administration/draws/draws.component';
import { DRAWS_ROUTES } from './components/administration/draws/draws.routes';
import { BulkLoadComponent } from './components/administration/bulk-load/bulk-load.component';
import { SavingReportComponent } from './components/reports/savings/saving-report.component';
import { PaymentReportComponent } from './components/reports/payments/payment-report.component';
import { LoanReportComponent } from './components/reports/loans/loan-report.component';
import { BalanceComponent } from './components/reports/balance/balance.component';
import { ConfigurationsComponent } from './components/configuration/configurations/configurations.component';
import { LOWSTAFF_ROUTES } from './components/administration/low-staffing/low-staff.routes';
import { LowStaffingComponent } from './components/administration/low-staffing/low-staffing.component';
import { PaymentsComponent } from './components/administration/payments/payments.component';
import { PAYMENTS_ROUTES } from './components/administration/payments/payments.routes';
import { RegisterComponent } from './register/register.component';
import { LoanComponent } from './components/administration/loans/loan.component';
import { LoanCalculatorComponent } from './components/loan-calculator/loan-calculator.component';

const APP_ROUTES: Routes = [
    { path: 'home', component: SavingReportComponent },
    { path: 'password/recovery/:token', component: RegisterComponent },
    { path: 'configuration/profiles', component: ProfilesComponent,
        children: PROFILES_ROUTES, canActivate: [AuthGuardService] },
    { path: 'configuration/profiles/:searchValue', component: ProfilesComponent,
        children: PROFILES_ROUTES, canActivate: [AuthGuardService] },
    { path: 'configuration/modules', component: ModulesComponent,
        children: MODULES_ROUTES, canActivate: [AuthGuardService] },
    { path: 'configuration/modules/:searchValue', component: ModulesComponent,
        children: MODULES_ROUTES, canActivate: [AuthGuardService] },
    { path: 'configuration/applications', component: ApplicationsComponent,
        children: APPLICATIONS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'configuration/applications/:searchValue', component: ApplicationsComponent,
        children: APPLICATIONS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'configuration/permissions', component: PermissionsComponent,
        canActivate: [AuthGuardService] },
    { path: 'configuration/users', component: UsersComponent,
        children: USERS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'configuration/users/:searchValue', component: UsersComponent,
        children: USERS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/teachers', component: TeachersComponent,
        children: TEACHERS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/loans', component: LoansComponent,
        children: LOANS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/loans/:searchValue', component: LoansComponent,
        children: LOANS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/savings', component: SavingsComponent,
        children: SAVINGS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/savings/:searchValue', component: SavingsComponent,
        children: SAVINGS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/draws', component: DrawsComponent,
        children: DRAWS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/draws/:searchValue', component: DrawsComponent,
        children: DRAWS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/lowstaffing', component: LowStaffingComponent,
        children: LOWSTAFF_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/lowstaffing/:searchValue', component: LowStaffingComponent,
        children: LOWSTAFF_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/payments', component: PaymentsComponent,
        children: PAYMENTS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/payments/:searchValue', component: PaymentsComponent,
        children: PAYMENTS_ROUTES, canActivate: [AuthGuardService] },
    { path: 'administration/bulkload', component: BulkLoadComponent, canActivate: [AuthGuardService] },
    { path: 'usuarios/ahorros', component: SavingReportComponent, canActivate: [AuthGuardService] },
    { path: 'reports/payments', component: PaymentReportComponent, canActivate: [AuthGuardService] },
    { path: 'usuarios/prestamos', component: LoanReportComponent, canActivate: [AuthGuardService] },
    { path: 'usuarios/prestamos/detalle/:id', component: LoanComponent, canActivate: [AuthGuardService] },
    { path: 'usuarios/caculador', component: LoanCalculatorComponent, canActivate: [AuthGuardService] },
    { path: 'configuration/configurations', component: ConfigurationsComponent, canActivate: [AuthGuardService] },
    { path: 'account/register', component: RegisterComponent},
    { path: '**', pathMatch: 'full', redirectTo: 'home' }
];
//export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true });
export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, {});
