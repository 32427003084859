<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3 class="title">
                        Ahorros <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="box-search">
        <div class="row">
            <div class="box-block box-block-search col-xs-12 col-sm-12 col-md-9 col-lg-9">
                <form role="search">
                    <div class="input-box">
                        <i class="fas fa-search"></i>
                        <input type="search" [(ngModel)]="searchValue" name="searchValue" autocomplete="off" placeholder="Buscar..." (keyup.enter)="search()">
                        <div class="underline"></div>
                    </div>
                </form>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 row">
                <label class="col-xs-3 col-sm-3 col-md-3 col-lg-3">Año</label>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                    <select class="custom-select" [(ngModel)]="yearSelected" (change)="search()">
                        <option [value]="year" *ngFor="let year of arrayYears ">{{ year }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-header">
                        <div class="no-overflow">
                            <span>Nombre</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>R.F.C.</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Tipo</span>
                        </div>
                    </div>

                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>Importe</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header fixed item-col-actions-dropdown"></div>
                </div>
            </li>

            <li class="item" *ngFor="let item of jsonArray | angularPaginator: {itemsPerPage: this.itemsPerPage, currentPage: currentPage }; let i = index">
                <div class="item-row">
                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Nombre</div>
                        <div>
                            <span>{{ item.teacher.name }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">R.F.C.</div>
                        <div>
                            <span>{{ item.teacher.rfc }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Tipo</div>
                        <div class="text-right">
                            <span>{{ item.type }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Importe</div>
                        <div class="text-right">
                            <span>{{ calculateAamount( item ) | currency }}</span>
                        </div>
                    </div>

                    <div class="item-col fixed item-col-actions-dropdown">
                        <div class="item-list-actions text-center">
                            <span *ngIf="accountService.havePermission(applications, 'Ahorros Editar')">
                                <a class="edit" [routerLink]="['/administration/savings/edit', item.id ]">
                                    <i class="far fa-edit"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <app-angular-paginator *ngIf="!loadingData && jsonArray.length > 0" className="pagination-sm" forceEllipses="true" (pageChange)="currentPage = $event"></app-angular-paginator>

    <ng-template appOpen></ng-template>

</article>