import { Routes } from '@angular/router';
import { ModuleComponent } from './module.component';
import { ModuleListComponent } from './module-list.component';

export const MODULES_ROUTES: Routes = [
    { path: 'module', component: ModuleComponent },
    { path: 'module/:id', component: ModuleComponent },
    { path: 'module/:id/:searchValue', component: ModuleComponent },
    { path: '', component: ModuleListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
