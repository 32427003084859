<aside class="sidebar">
    <div class="sidebar-container">
        <div class="sidebar-header">
            <div class="brand">
                <div class="logo">
                    <img [src]="configurations.imageFOCAPS" alt="FOCAPS" class="img-thumbnail">
                </div> Administración
            </div>
        </div>

        <nav class="menu">
            <ul class="sidebar-menu metismenu" id="sidebar-menu">
                <li routerLinkActive="active">
                    <a [routerLink]="['/home']">
                        <i class="fas fa-home"></i> Ahorros
                    </a>
                </li>
                <li *ngIf="loadingData">
                    <a>
                        <i class="fas fa-sync-alt fa-spin"></i> Cargando Menú
                    </a>
                </li>
                <li routerLinkActive="active">
                  <a [routerLink]="['/usuarios/prestamos']">
                    <i class="fas fa-money-bill-alt"></i> Prestamos
                  </a>
                </li>
                <li routerLinkActive="active">
                    <a [routerLink]="['/usuarios/caculador']">
                        <i class="fas fa-money-bill-alt"></i> Simulador
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</aside>
