import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RelationshipInterface } from '../interfaces/relationship.interface';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class RelationshipService {
  private apiURL = 'api/Catalogs/Relationships';

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  create(relationship: RelationshipInterface): Observable<any> {
    return this.http.post<any>(this.apiURL + '/Create', relationship);
  }

  get(searchValue: string) {
    return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
  }

  getById(id: string) {
    return this.http.get<any>(this.apiURL + '/' + id);
  }

  update(id: string, relationship: RelationshipInterface) {
    return this.http.put<any>(this.apiURL + '/' + id, relationship);
  }

  delete(id: string) {
    return this.http.delete<any>(this.apiURL + '/' + id);
  }
}
