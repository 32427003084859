import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AccountService } from '../../account/login/services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DrawInterface } from './interfaces/draw.interface';
import { DrawService } from './services/draw.service';

@Component({
  selector: 'app-draw',
  templateUrl: './draw.component.html',
  styles: []
})
export class DrawComponent implements OnInit {
  title = 'Agregar';
  returnPath = '../../../';
  forma: UntypedFormGroup;
  message: string;
  gettingData = false;
  sendingData = false;
  searchValue = '';
  draw: DrawInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    lastUpdated: new Date(),
    userIdLastUpdated: this.accountService.getUserId(),
    name: '',
    openDate: new Date(),
    closeDate: new Date(),
    status: 'Pendiente',
    loans: []
  };

  constructor( private accountService: AccountService, private formBuilder: UntypedFormBuilder,
    private router: Router, private activatedRoute: ActivatedRoute,
    private drawService: DrawService ) { }

  ngOnInit() {
    this.gettingData = true;
    this.buildForm();

    this.activatedRoute.params.subscribe(params => {
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }

      if (params['id']) {
        this.title = 'Editar';
        this.draw.id = params['id'];
        this.drawService.getById(params['id'])
          .subscribe(data => {
            this.returnPath = '../../';
            this.draw = Object.assign({}, data);
            this.forma.setValue(this.draw);
          },
            error => this.errorMessage(error));
      } else {
        this.forma.setValue(this.draw);
      }
      this.gettingData = false;
    });
  }

  openDraw(): void {
    this.sendingData = true;
    this.draw = Object.assign({}, this.forma.value);
    this.draw.date = new Date();
    this.draw.status = 'Abierto';
    if ( this.title === 'Agregar' ) {
      this.drawService.create( this.draw )
        .subscribe(success => {
          this.router.navigate(['administration/draws', this.searchValue]);
        }, error => this.errorMessage(error));
    } else {
      this.draw.userIdLastUpdated = this.accountService.getUserId();
      this.drawService.update( this.draw.id, this.draw )
      .subscribe(success => {
        this.router.navigate(['administration/draws', this.searchValue]);
      }, error => this.errorMessage(error));
    }
  }

  closeDraw() {
    this.draw.userIdLastUpdated = this.accountService.getUserId();
    this.draw.status = 'Cerrado';
    this.drawService.update(this.draw.id, this.draw)
      .subscribe(success => {
        this.router.navigate(['administration/draws', this.searchValue]);
      }, error => this.errorMessage(error));
  }

  sendForm(): void {
    this.sendingData = true;
    this.draw = Object.assign({}, this.forma.value);
    this.draw.date = new Date();
    if ( this.title === 'Agregar' ) {
      this.drawService.create( this.draw )
        .subscribe(success => {
          this.router.navigate(['administration/draws', this.searchValue]);
        }, error => this.errorMessage(error));
    } else {
      this.draw.userIdLastUpdated = this.accountService.getUserId();
      this.drawService.update( this.draw.id, this.draw )
      .subscribe(success => {
        this.router.navigate(['administration/draws', this.searchValue]);
      }, error => this.errorMessage(error));
    }
  }

  buildForm(): void {
    this.forma = this.formBuilder.group({
      id: [''],
      userId: [''],
      date: [''],
      active: [''],
      name: ['', [Validators.required, Validators.minLength(3)]],
      openDate: [''],
      closeDate: [''],
      status: [''],
      lastUpdated: [''],
      userIdLastUpdated: [''],
      loans: ['']
    });
  }

  errorMessage( error: any ) {
    if (error && error.error) {
      console.log(error);
      this.message = error.error;
      this.sendingData = false;
    }
  }

}
