import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ModulesInterface } from '../modules/interfaces/modules.interface';
import { ApplicationInterface } from './interface/application.interface';
import { ApplicationService } from './services/application.service';
import { AccountService } from '../../account/login/services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModulesService } from '../modules/services/modules.service';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styles: []
})
export class ApplicationComponent {
  title = 'Agregar';
  returnPath = '../../../';
  forma: UntypedFormGroup;
  message: string;
  loadingData = false;
  sendData = false;
  searchValue = '';
  modules: ModulesInterface[];
  application: ApplicationInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    moduleId: '00000000-0000-0000-0000-000000000000',
    name: '',
    path: '',
    visible: false,
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    module: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: true,
      name: '',
      lastUpdated: new Date,
      userIdLastUpdated: this.accountService.getUserId(),
    },
    profileApplications: []
  };

  constructor(private applicationService: ApplicationService, private accountService: AccountService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private modulesService: ModulesService) {
    this.loadingData = true;
    this.forma = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      userId: new UntypedFormControl(''),
      date: new UntypedFormControl(''),
      active: new UntypedFormControl(''),
      moduleId: new UntypedFormControl('', [Validators.required]),
      name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
      path: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      visible: new UntypedFormControl('', [Validators.required]),
      module: new UntypedFormControl(''),
      profileApplications: new UntypedFormControl(''),
      lastUpdated: new UntypedFormControl(''),
      userIdLastUpdated: new UntypedFormControl('')
    });

    this.modulesService.get('null')
      .subscribe(data => this.modules = data,
        error => this.errorMessage(error));

    this.activatedRoute.params.subscribe(params => {
      if (params['searchValue']) {
        this.searchValue = params['searchValue'];
      }

      if (params['id']) {
        this.title = 'Editar';
        this.application.id = params['id'];
        this.applicationService.getById(params['id']).subscribe(
          data => {
            this.returnPath = '../../';
            this.application = Object.assign({}, data);
            this.forma.setValue(this.application);
          },
          error => this.errorMessage(error)
        );
      } else {
        this.forma.setValue(this.application);
      }
      this.loadingData = false;
    });
  }

  sendForm( ) {
    this.sendData = true;
    this.application = Object.assign({}, this.forma.value);
    this.application.date = new Date();
    this.application.module = this.modules
      .find(module => module.id === this.application.moduleId );
    if (this.title === 'Agregar') {
      this.applicationService.create(this.application)
        .subscribe(
          success => this.router.navigate(['/configuration/applications', this.searchValue]),
          error => this.errorMessage(error)
        );
    } else {
      this.application.userIdLastUpdated = this.accountService.getUserId();
      this.applicationService
        .update(this.application.id, this.application)
        .subscribe(
          success => this.router.navigate(['/configuration/applications', this.searchValue]),
          error => this.errorMessage(error)
        );
    }
  }

  errorMessage(error) {
    if (error && error.error) {
      this.message = error.error[''][0];
      this.sendData = false;
      console.clear();
    }
  }
}
