import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PhoneTypeInterface } from '../interfaces/phone-type.interface';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class PhoneTypeService {
  private apiURL = 'api/Catalogs/PhoneTypes';
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  create(phoneType: PhoneTypeInterface): Observable<any> {
    return this.http.post<any>(this.apiURL + '/Create', phoneType);
  }

  get(searchValue: string) {
    return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
  }

  getById(id: string) {
    return this.http.get<any>(this.apiURL + '/' + id);
  }

  update(id: string, user: PhoneTypeInterface) {
    return this.http.put<any>(this.apiURL + '/' + id, user);
  }

  delete(id: string) {
    return this.http.delete<any>(this.apiURL + '/' + id);
  }
}
