import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LowStaffService } from './services/low-staff.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AccountService } from '../../account/login/services/account.service';

@Component({
  selector: 'app-low-staff-list',
  templateUrl: './low-staff-list.component.html',
  styles: []
})
export class LowStaffListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray: any[] = [];
  applications: ApplicationInterface[];
  constructor( private activatedRoute: ActivatedRoute,
    private lowstaffService: LowStaffService, private router: Router,
    public accountService: AccountService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.accountService.getApplications('administration', 'lowstaffing')
      .subscribe(applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if (params['searchValue']) {
            this.searchValue = params['searchValue'];
            this.loadData(this.searchValue);
          } else {
            this.loadData('null');
          }
        });
      }, error => console.error(error));
  }

  search() {}

  loadData(value: string) {
    this.lowstaffService.get(value)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
    },
    error => this.setErrorMessage(error));
  }

  setErrorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.message);
      this.loadingData = false;
    }
  }

  generateChecks() {
    if ( this.searchValue !== '') {
      this.router.navigate( ['administration/lowstaffing/pending', this.searchValue] );
    } else {
      this.router.navigate( ['administration/lowstaffing/pending'] );
    }
  }

}
