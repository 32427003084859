import { Component, OnInit, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoanService } from './services/loan.service';
import { AccountService } from '../../account/login/services/account.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { LoanInterface } from './interfaces/loan.interface';
import { OpenDirective } from '../../modals/open.directive';
import { AlertComponent } from '../../modals/alert.component';
declare let jsPDF;
declare var require: any;

@Component({
  selector: 'app-loans-pending',
  templateUrl: './loans-pending.component.html',
  styles: []
})
export class LoansPendingComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  returnPath = '';
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  drawSelected = '';
  errorMessage = '';
  applications: ApplicationInterface[];
  jsonArray: any[] = [];
  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;

  constructor( private componentFactoryResolver: ComponentFactoryResolver, private activatedRoute: ActivatedRoute, 
    private accountService: AccountService, private loanService: LoanService, private router: Router) { }

  ngOnInit() {
    this.loadingData = true;
    this.accountService.getApplications('administration', 'loans')
      .subscribe(applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if (params['drawId']) {
            this.drawSelected = params['drawId'];
          }
    
          if (params['searchValue']) {
            this.searchValue = params['searchValue'];
          }
          this.loadData();
        });
      },
      error => this.setErrorMessage(error));
  }

  loadData() {
    this.loanService.havePending( this.drawSelected )
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
    }, 
    error => this.setErrorMessage(error));
  }

  setErrorMessage( error ) {
    if (error && error.error) {
      this.errorMessage = error.message;
      this.loadingData = false;
    }
  }

  apply() {
    this.loanService.apply(this.drawSelected, this.accountService.getUserId())
      .subscribe(response => {
        this.generateDocument(response);
        this.router.navigate( ['administration/loans', this.searchValue] );
    }, err => this.setErrorMessage(err));
  }

  generateDocument(data: any[]): void {
    const doc = new jsPDF();
    doc.setFontSize(9);
    const num2Let = require('numalet')();
    data.forEach(element => {
      //doc.addPage();  
      var amount = element.amount - (element.openingBonus + element.warranty );
      doc.text(`${ this.formatDate(new Date().getDate()) }/${ this.formatDate(new Date().getMonth()) }/${ new Date().getFullYear() }`,
      doc.internal.pageSize.width - 70, 15);
      doc.text(element.teacher.name, 30, 33);
      doc.text(amount.toString(), 175 , 33);
      doc.text(num2Let(amount), 30, 42);
      //DETALLE DE LA LIQUIDACIÓN
      doc.text(element.amount.toString(), 100, 100);
      doc.text(element.openingBonus.toString(), 70, 110);
      doc.text(element.warranty.toString(), 70, 115);
      doc.text((element.warranty + element.openingBonus).toString(), 70, 143);
      doc.text(amount.toString(), 100, 165);

      //DATOS DEL PRESTAMO
    });

    doc.save(`Prestamos.pdf`);
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  deleteItem(loan: LoanInterface) {
    loan.status = 'Cancelado';
    //loan.active = false;
    this.loanService.update(loan.id, loan).subscribe(
      success => {
        this.loadData();
      },
      error => this.setErrorMessage(error)
    );
  }

  showConfirm(loan: LoanInterface) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      AlertComponent
    );

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<AlertComponent>componentRef.instance).title = 'Alerta';
    (<AlertComponent>(componentRef.instance)).message = `Seguro que desea eliminar el prestamo ${ loan.loanNumber } ( ${ loan.teacher.name } ).`;
    (<AlertComponent>componentRef.instance).open();
    (<AlertComponent>componentRef.instance).alertOutput.subscribe(
      success => {
        this.deleteItem(loan);
      },
      error => this.setErrorMessage(error)
    );
  }

}
