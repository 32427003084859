<div class="auth">
    <div class="auth-container">
        <div class="card">
            <!-- <ng-flash-message></ng-flash-message> -->
            <header class="auth-header">
                <h1 class="auth-title">
                    FOCAPS
                </h1>
            </header>
            <div *ngIf="!isRegister && !isRecovery && !isRecoveryPassword" class="auth-content">
                <p class="text-center">INGRESE SUS CREDENCIALES</p>
                <form role="form" [formGroup]="forma" (ngSubmit)="validateCredentials()" novalidate="">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['email'].valid }">
                        <label for="email ">E-mail</label>
                        <input type="text" class="form-control underlined " placeholder="Su e-mail" id="email" formControlName="email" autocomplete="off">
                        <span *ngIf="forma.controls['email'].touched && forma.controls['email'].errors?.required" class="has-error ">El campo e-mail es requerido.</span>
                        <span *ngIf="forma.controls['email'].touched && forma.controls['email'].errors?.minlength" class="has-error ">El campo e-mail debe tener almenos {{ forma.controls['email'].errors.minlength.requiredLength }} caracteres.</span>
                    </div>
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['password'].valid }">
                        <label for="password">Contraseña</label>
                        <input type="password" class="form-control underlined" placeholder="Su contraseña" id="password" formControlName="password">
                        <span *ngIf="forma.controls['password'].touched && forma.controls['password'].errors?.required" class="has-error ">El campo contraseña es requerido.</span>
                        <span *ngIf="forma.controls['password'].touched && forma.controls['password'].errors?.minlength" class="has-error ">El campo contraseña debe tener almenos {{ forma.controls['password'].errors.minlength.requiredLength }} caracteres.</span>
                    </div>
                    <div class="form-group">
                        <button [disabled]="!forma.valid || loading" class="btn btn-block btn-primary">
                        <i class="fas" [ngClass]="{ 'fa-user-check': !loading, 'fa-sync-alt fa-spin': loading }" ></i>
                        <span *ngIf="!loading" >Accesar</span>
                        <span *ngIf="loading" >Validando...</span>
                      </button>
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="message">
                        {{ message }}
                    </div>
                </form>
                <div>
                    <button class="btn btn-block btn-info text-white" (click)="register()">Registrarse</button>
                </div>
                <div><button class="btn btn-link" (click)="goToRecovery()">¿Olvidaste tu contraseña?</button></div>
            </div>
            <div *ngIf="isRegister" class="auth-content">
                <p class="text-center">INGRESE SUS DATOS</p>
                <form role="form" [formGroup]="registerForm" (ngSubmit)="validateCredentials()" novalidate="">
                    <div class="form-group" [ngClass]="{'has-error': !registerForm.controls['email'].valid }">
                        <label for="email">Email</label>
                        <input type="text" class="form-control underlined " placeholder="Su e-mail" id="email" formControlName="email" autocomplete="off">
                        <span *ngIf="registerForm.controls['email'].touched && registerForm.controls['email'].errors?.required" class="has-error ">El email es requerido.</span>
                        <span *ngIf="registerForm.controls['email'].touched && registerForm.controls['email'].errors?.minlength" class="has-error ">El email debe tener almenos {{ registerForm.controls['email'].errors.minlength.requiredLength }} caracteres.</span>
                    </div>
                    <div class="form-group" [ngClass]="{'has-error': !registerForm.controls['password'].valid }">
                        <label for="password">Contraseña</label>
                        <input type="password" class="form-control underlined" placeholder="Su contraseña" id="password" formControlName="password">
                        <span *ngIf="registerForm.controls['password'].touched && registerForm.controls['password'].errors?.required" class="has-error ">El campo contraseña es requerido.</span>
                        <span *ngIf="registerForm.controls['password'].touched && registerForm.controls['password'].errors?.minlength" class="has-error ">El campo contraseña debe tener almenos {{ registerForm.controls['password'].errors.minlength.requiredLength }} caracteres.</span>
                    </div>
                    <div class="form-group">
                        <button [disabled]="!registerForm.valid || loading" class="btn btn-block btn-primary">
                        <i class="fas" [ngClass]="{ 'fa-user-check': !loading, 'fa-sync-alt fa-spin': loading }" ></i>
                        <span *ngIf="!loading" >Registrar</span>
                        <span *ngIf="loading" >Validando...</span>
                      </button>
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="message">
                        {{ message }}
                    </div>
                </form>
                <div>
                    <button class="btn btn-block btn-info text-white" (click)="Acceder()">Acceder</button>
                </div>
            </div>
            <div *ngIf="isRecovery" class="auth-content">
                <p class="text-center">RECUPERAR CONTRASEÑA</p>
                <form role="form" [formGroup]="recoveryForm" (ngSubmit)="recovery()" novalidate="">
                    <div class="form-group" [ngClass]="{'has-error': !recoveryForm.controls['email'].valid }">
                        <label for="email">Email</label>
                        <input type="text" class="form-control underlined " placeholder="Su e-mail" id="email" formControlName="email" autocomplete="off">
                        <span *ngIf="recoveryForm.controls['email'].touched && recoveryForm.controls['email'].errors?.required" class="has-error ">El email es requerido.</span>
                        <span *ngIf="recoveryForm.controls['email'].touched && recoveryForm.controls['email'].errors?.minlength" class="has-error ">El email debe tener almenos {{ registerForm.controls['email'].errors.minlength.requiredLength }} caracteres.</span>
                    </div>
                    <div class="form-group">
                        <button [disabled]="!recoveryForm.valid || loading" class="btn btn-block btn-primary">
                      <i class="fas" [ngClass]="{ 'fa-user-check': !loading, 'fa-sync-alt fa-spin': loading }" ></i>
                      <span *ngIf="!loading" >Recuperar</span>
                      <span *ngIf="loading" >Validando...</span>
                    </button>
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="message">
                        {{ message }}
                    </div>
                </form>
                <div>
                    <button class="btn btn-block btn-info text-white" (click)="cancelar()">Cancelar</button>
                </div>
            </div>
            <div *ngIf="isRecoveryPassword" class="auth-content">
                <p class="text-center">RECUPERAR CONTRASEÑA</p>
                <form role="form" [formGroup]="passwordRecoveryForm" (ngSubmit)="validateCredentials()" novalidate="">
                    <div class="form-group" [ngClass]="{'has-error': !passwordRecoveryForm.controls['password'].valid }">
                        <label for="password">Contraseña</label>
                        <input type="password" class="form-control underlined" placeholder="Nueva contraseña" id="password" formControlName="password">
                        <span *ngIf="passwordRecoveryForm.controls['password'].touched && passwordRecoveryForm.controls['password'].errors?.required" class="has-error ">El campo contraseña es requerido.</span>
                        <span *ngIf="passwordRecoveryForm.controls['password'].touched && passwordRecoveryForm.controls['password'].errors?.minlength" class="has-error ">El campo contraseña debe tener almenos {{ passwordRecoveryForm.controls['password'].errors.minlength.requiredLength }} caracteres.</span>
                    </div>
                    <div class="form-group" [ngClass]="{'has-error': !passwordRecoveryForm.controls['confirmPassword'].valid }">
                        <label for="confirmPassword">Confirmar contraseña</label>
                        <input type="password" class="form-control underlined" placeholder="Confirmar contraseña" id="confirmPassword" formControlName="confirmPassword">
                        <span *ngIf="passwordRecoveryForm.controls['confirmPassword'].touched && passwordRecoveryForm.controls['confirmPassword'].errors?.required" class="has-error ">El campo contraseña es requerido.</span>
                        <span *ngIf="passwordRecoveryForm.controls['confirmPassword'].touched && passwordRecoveryForm.controls['confirmPassword'].errors?.minlength" class="has-error ">El campo contraseña debe tener almenos {{ passwordRecoveryForm.controls['confirmPassword'].errors.minlength.requiredLength }} caracteres.</span>
                        <!-- <span *ngIf="passwordRecoveryForm.errors?.mismatch && (passwordRecoveryForm.controls['confirmPassword'].dirty || passwordRecoveryForm.controls['confirmPassword'].touched)" class="has-error ">
                      Las Contraseñas no coinciden
                    </span> -->
                    </div>
                    <div class="form-group">
                        <button [disabled]=" loading" class="btn btn-block btn-primary">
                    <i class="fas" [ngClass]="{ 'fa-user-check': !loading, 'fa-sync-alt fa-spin': loading }" ></i>
                    <span *ngIf="!loading" >Actualizar</span>
                    <span *ngIf="loading" >Validando...</span>
                  </button>
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="message">
                        {{ message }}
                    </div>
                </form>
                <div>
                    <button class="btn btn-block btn-info text-white" (click)="Acceder()">Acceder</button>
                </div>
            </div>
        </div>
    </div>
</div>
