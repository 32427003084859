import { Component, OnInit } from '@angular/core';
import { BalanceService } from './service/balance.service';


@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styles: []
})
export class BalanceComponent implements OnInit {
  dataReport: any[] = [];
  amountBalance = 0;
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-01`;
  fin = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  errorMessage = '';
  loadingData = false;
  balancePositive = true;

  constructor( private reportService: BalanceService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.loadData();
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  loadData() {
    this.errorMessage = '';
    // if ( isDate(new Date(this.inicio)) && this.inicio.length === 10 ) {
    //   if ( isDate(new Date(this.fin)) && this.inicio.length === 10 ) {
    //     this.reportService.get( this.inicio, this.fin )
    //       .subscribe( (data) => {
    //         this.dataReport = data;
    //         this.calculateBalance(data);
    //         this.loadingData = false;
    //       }, error => this.setErrorMessage(error));
    //   } else {
    //     this.errorMessage = 'La fecha de fin no cuenta con el formato correcto.';
    //   }
    // } else {
    //   this.errorMessage = 'La fecha de inicio no cuenta con el formato correcto.';
    // }
    this.loadingData = false;
  }

  calculateBalance(data: any[]): void {
    this.amountBalance = 0;
    this.balancePositive = true;
    let amountSavings = 0;
    let amountPayments = 0;
    let amountLoans = 0;

    data.forEach(item => {
      switch (item.tramite) {
        case 'Ahorros':
          amountSavings += item.total;
          break;
        case 'Pagos':
          amountPayments += item.total;
          break;
        case 'Prestamos':
          amountLoans += item.total;
          break;
        default:
          break;
      }
    });

    this.amountBalance = amountSavings + amountPayments - amountLoans;
    if ( this.amountBalance < 0 ) {
      this.balancePositive = false;
    }
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

}
