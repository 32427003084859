import { Routes } from '@angular/router';
import { UserComponent } from './user.component';
import { UserListComponent } from './user-list.component';

export const USERS_ROUTES: Routes = [
    { path: 'user', component: UserComponent },
    { path: 'user/:id', component: UserComponent },
    { path: 'user/:id/:searchValue', component: UserComponent },
    { path: '', component: UserListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
