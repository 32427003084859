import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//    ROUTING
import { APP_ROUTING } from './app.routes';
//    PIPES
import { CurrencyPipe } from '@angular/common';
//    COMPONENTS
import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/account/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { ApplicationsComponent } from './components/configuration/applications/applications.component';
import { ApplicationListComponent } from './components/configuration/applications/application-list.component';
import { ApplicationComponent } from './components/configuration/applications/application.component';
import { ModulesComponent } from './components/configuration/modules/modules.component';
import { ModuleComponent } from './components/configuration/modules/module.component';
import { ModuleListComponent } from './components/configuration/modules/module-list.component';
import { AlertComponent } from './components/modals/alert.component';
import { AngularPaginatorComponent } from './components/angular-paginator/angular-paginator.component';
import { PermissionsComponent } from './components/configuration/permissions/permissions.component';
import { ProfilesComponent } from './components/configuration/profiles/profiles.component';
import { ProfileComponent } from './components/configuration/profiles/profile.component';
import { ProfileListComponent } from './components/configuration/profiles/profile-list.component';
import { UsersComponent } from './components/configuration/users/users.component';
import { UserComponent } from './components/configuration/users/user.component';
import { UserListComponent } from './components/configuration/users/user-list.component';
import { TeachersComponent } from './components/administration/teachers/teachers.component';
import { TeacherListComponent } from './components/administration/teachers/teacher-list.component';
import { TeacherComponent } from './components/administration/teachers/teacher.component';
import { LoanComponent } from './components/administration/loans/loan.component';
import { LoanListComponent } from './components/administration/loans/loan-list.component';
import { LoansComponent } from './components/administration/loans/loans.component';
import { NotDataComponent } from './components/not-data/not-data.component';
import { SavingComponent } from './components/administration/savings/saving.component';
import { SavingsComponent } from './components/administration/savings/savings.component';
import { SavingListComponent } from './components/administration/savings/saving-list.component';
import { LoadingDataComponent } from './components/loading-data/loading-data.component';
import { DrawListComponent } from './components/administration/draws/draw-list.component';
import { DrawComponent } from './components/administration/draws/draw.component';
import { DrawsComponent } from './components/administration/draws/draws.component';
import { BulkLoadComponent } from './components/administration/bulk-load/bulk-load.component';
import { UploadComponent } from './components/administration/bulk-load/upload.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { SavingReportComponent } from './components/reports/savings/saving-report.component';
import { PaymentReportComponent } from './components/reports/payments/payment-report.component';
import { LoanReportComponent } from './components/reports/loans/loan-report.component';
import { BalanceComponent } from './components/reports/balance/balance.component';
import { AddUnsubscribeComponent } from './components/administration/teachers/add-unsubscribe.component';
import { ConfigurationsComponent } from './components/configuration/configurations/configurations.component';
import { UnsubscribeComponent } from './components/administration/teachers/unsubscribe.component';
import { LowStaffListComponent } from './components/administration/low-staffing/low-staff-list.component';
import { LowStaffComponent } from './components/administration/low-staffing/low-staff.component';
import { LowStaffingComponent } from './components/administration/low-staffing/low-staffing.component';
import { PaymentListComponent } from './components/administration/payments/payment-list.component';
import { PaymentsComponent } from './components/administration/payments/payments.component';
import { PaymentDetailComponent } from './components/administration/payments/payment-detail.component';
import { LoansPendingComponent } from './components/administration/loans/loans-pending.component';
import { ReentryComponent } from './components/administration/teachers/reentry.component';
//    GRAFICS
import { ChartsModule } from 'ng2-charts';
//    DIRECTIVES
import { OpenDirective } from './components/modals/open.directive';
import { AngularPaginatorDirective } from './components/angular-paginator/directives/angular-paginator.directive';
//    PIPES
import { AngularPaginatorPipe } from './components/angular-paginator/pipes/angular-paginator.pipe';
//    SERVICES
import { AccountService } from './components/account/login/services/account.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { AngularPaginatorService } from './components/angular-paginator/services/angular-paginator.service';
import { ModulesService } from './components/configuration/modules/services/modules.service';
import { ApplicationService } from './components/configuration/applications/services/application.service';
import { ProfilesService } from './components/configuration/profiles/services/profiles.service';
import { UsersService } from './components/configuration/users/services/users.service';
import { TeacherService } from './components/administration/teachers/services/teacher.service';
import { PhoneTypeService } from './components/catalogs/phone-types/services/phone-type.service';
import { DrawService } from './components/administration/draws/services/draw.service';
import { FortnightService } from './components/catalogs/fortnights/services/fortnight.service';
import { LoanService } from './components/administration/loans/services/loan.service';
import { SavingService } from './components/administration/savings/services/saving.service';
import { RegionService } from './components/catalogs/regions/services/region.service';
import { RelationshipService } from './components/catalogs/relationships/services/relationship.service';
import { LoanParameterService } from './components/catalogs/loan-parameter/services/loan-parameter.service';
import { BulkLoadService } from './components/administration/bulk-load/services/bulk-load.service';
import { SavingReportService } from './components/reports/savings/service/saving-report.service';
import { PaymentReportService } from './components/reports/payments/services/payment-report.service';
import { LoanReportService } from './components/reports/loans/services/loan-report.service';
import { BalanceService } from './components/reports/balance/service/balance.service';
import { ConfigurationsService } from './components/configuration/configurations/services/configurations.service';
import { TeacherStatusService } from './components/catalogs/teacher-status/services/teacher-status.service';
import { LowStaffService } from './components/administration/low-staffing/services/low-staff.service';
import { ContributionService } from './components/catalogs/contributions/services/contribution.service';
import { PaymentService } from './components/administration/payments/services/payment.service';
import { FinancialLiquidityService } from './components/administration/financial-liquidity/services/financial-liquidity.service';
import { PaymentComponent } from './components/administration/payments/payment.component';
import { RegisterComponent } from './register/register.component';
import { LoanCalculatorComponent } from './components/loan-calculator/loan-calculator.component';

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        FooterComponent,
        LoginComponent,
        HeaderComponent,
        OpenDirective,
        AlertComponent,
        AngularPaginatorComponent,
        AngularPaginatorDirective,
        AngularPaginatorPipe,
        ModulesComponent,
        ModuleComponent,
        ModuleListComponent,
        ApplicationsComponent,
        ApplicationListComponent,
        ApplicationComponent,
        PermissionsComponent,
        ProfilesComponent,
        ProfileComponent,
        ProfileListComponent,
        TeachersComponent,
        TeacherListComponent,
        TeacherComponent,
        UsersComponent,
        UserComponent,
        UserListComponent,
        LoanComponent,
        LoanListComponent,
        LoansComponent,
        LoadingDataComponent,
        NotDataComponent,
        SavingComponent,
        SavingsComponent,
        SavingListComponent,
        DrawListComponent,
        DrawComponent,
        DrawsComponent,
        BulkLoadComponent,
        UploadComponent,
        ErrorMessageComponent,
        SavingReportComponent,
        PaymentReportComponent,
        LoanReportComponent,
        BalanceComponent,
        ConfigurationsComponent,
        UnsubscribeComponent,
        AddUnsubscribeComponent,
        ReentryComponent,
        LowStaffListComponent,
        LowStaffComponent,
        LowStaffingComponent,
        PaymentsComponent,
        PaymentListComponent,
        PaymentDetailComponent,
        LoansPendingComponent,
        PaymentComponent,
        RegisterComponent,
        LoanCalculatorComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        APP_ROUTING,
        ReactiveFormsModule
    ],
    providers: [
        AccountService,
        AngularPaginatorService,
        AuthGuardService,
        ModulesService,
        ApplicationService,
        ProfilesService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        UsersService,
        TeacherService,
        PhoneTypeService,
        DrawService,
        FortnightService,
        LoanService,
        SavingService,
        RegionService,
        RelationshipService,
        LoanParameterService,
        BulkLoadService,
        SavingReportService,
        PaymentReportService,
        LoanReportService,
        BalanceService,
        CurrencyPipe,
        ConfigurationsService,
        TeacherStatusService,
        LowStaffService,
        ContributionService,
        PaymentService,
        FinancialLiquidityService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
