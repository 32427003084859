<div class="main-wrapper" *ngIf="isLogged()">
    <div class="app sidebar-fixed" id="app">

        <app-header></app-header>

        <app-nav-menu></app-nav-menu>

        <div class="sidebar-overlay" id="sidebar-overlay"></div>
        <div class="sidebar-mobile-menu-handle" id="sidebar-mobile-menu-handle"></div>
        <div class="mobile-menu-handle"></div>

        <router-outlet></router-outlet>

        <app-footer></app-footer>
    </div>
</div>

<app-login *ngIf="!isLogged() && !isRecovery()"></app-login>
<app-register *ngIf="!isLogged() && isRecovery()"></app-register>
<!-- Reference block for JS -->
<div class="ref" id="ref">
    <div class="color-primary"></div>
    <div class="chart">
        <div class="color-primary"></div>
        <div class="color-secondary"></div>
    </div>
</div>
