<article class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Configuración</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Configuraciones</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="working"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <form [formGroup]="forma" (ngSubmit)="sendForm()">

            <div class="row">
                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['nextCheck'].valid && forma.controls['nextCheck'].touched, 'has-success': forma.controls['nextCheck'].valid }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> # cheque prestamos</label>
                        <input type="number" class="form-control underlined" formControlName="nextCheck" (change)="calculateAmount()">
                        <span *ngIf="forma.controls['nextCheck'].touched && forma.controls['nextCheck'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['nextCheckLowStaff'].valid && forma.controls['nextCheckLowStaff'].touched, 'has-success': forma.controls['nextCheckLowStaff'].valid }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> # cheque bajas</label>
                        <input type="number" class="form-control underlined" formControlName="nextCheckLowStaff" (change)="calculateAmount()">
                        <span *ngIf="forma.controls['nextCheckLowStaff'].touched && forma.controls['nextCheckLowStaff'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                    </div>
                </div>
            </div>

            <div class="row form-group">
                <div class="col-12">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Mensaje PDFs</span>
                        </div>
                        <textarea class="form-control" aria-label="Mensaje PDFs" formControlName="messagePDF"></textarea>
                    </div>
                    <span class="required" *ngIf="forma.controls['messagePDF'].touched && forma.controls['messagePDF'].errors?.minlength" class="has-error ">
                        El campo nombre debe tener almenos {{ forma.controls['messagePDF'].errors.minlength.requiredLength }}
                        caracteres.
                    </span>
                </div>
            </div>

            <div class="row form-group">
                <div class="col-12">
                    <div class="input-group" [ngClass]="{'has-error': !forma.controls['messagePDFSavings'].valid && forma.controls['messagePDFSavings'].touched, 'has-success': forma.controls['messagePDFSavings'].valid && forma.controls['messagePDFSavings'].touched }">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Mensaje Ahorros</span>
                        </div>
                        <textarea class="form-control" aria-label="Mensaje Ahorros" formControlName="messagePDFSavings"></textarea>
                    </div>
                    <span class="required" *ngIf="forma.controls['messagePDFSavings'].touched && forma.controls['messagePDFSavings'].errors?.minlength" class="has-error ">
                        El campo nombre debe tener almenos
                        {{ forma.controls['messagePDFSavings'].errors.minlength.requiredLength }}
                        caracteres.
                    </span>
                </div>
            </div>

            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <div class="form-group">
                        <label class="control-label">Imagen FOCAPS</label>
                        <input type="file" accept="image/*" class="form-control underlined" (change)="changeFOCAPSListener($event)">
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <img [src]="configurations.imageFOCAPS" alt="FOCAPS" class="img-thumbnail" height="100" width="150">
                </div>
            </div>

            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <div class="form-group">
                        <label class="control-label">Imagen SNTE</label>
                        <input type="file" accept="image/*" class="form-control underlined" (change)="changeSNTEListener($event)">
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <img [src]="configurations.imageSNTE" alt="SNTE" class="img-thumbnail" height="100" width="150">
                </div>
            </div>

            <div class="form-group text-center">
                <button [disabled]="!forma.valid || sendData" class="btn btn-outline-warning">
                    <i [ngClass]="{ 'far fa-save': !sendData, 'fas fa-sync-alt fa-spin': sendData }"></i>
                    <span *ngIf="!sendData">Guardar</span>
                    <span *ngIf="sendData">Guardando...</span>
                </button>
            </div>
        </form>

        <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>
    </div>
</article>