<footer class="footer">
    <div class="footer-block buttons">

    </div>
    <div class="footer-block author">
        <ul>
            <li>&copy; BIRSA
            </li>
        </ul>
    </div>
</footer>