import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DrawInterface } from '../interfaces/draw.interface';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class DrawService {
  private apiURL = 'api/Administration/Draws';

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  create(phoneType: DrawInterface): Observable<any> {
    return this.http.post<any>(this.apiURL + '/Create', phoneType);
  }

  get(searchValue: string) {
    return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
  }

  getOpen() {
    return this.http.get<any>(this.apiURL + '/Open');
  }

  getById(id: string) {
    return this.http.get<any>(this.apiURL + '/' + id);
  }

  update(id: string, user: DrawInterface) {
    return this.http.put<any>(this.apiURL + '/' + id, user);
  }

  delete(id: string) {
    return this.http.delete<any>(this.apiURL + '/' + id);
  }
}
