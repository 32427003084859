import { Routes } from '@angular/router';
import { LowStaffComponent } from './low-staff.component';
import { TeacherListComponent } from '../teachers/teacher-list.component';
import { LowStaffListComponent } from './low-staff-list.component';

export const LOWSTAFF_ROUTES: Routes = [
    { path: 'pending', component: LowStaffComponent },
    { path: 'pending/:searchValue', component: LowStaffComponent },
    { path: '', component: LowStaffListComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];
