import { Component, OnInit, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { ModulesService } from './services/modules.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenDirective } from '../../modals/open.directive';
import { AlertComponent } from '../../modals/alert.component';

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styles: []
})
export class ModuleListComponent {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray = [];
  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private moduleService: ModulesService,
    public activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.params
      .subscribe(params => {
        if (params['searchValue']) {
          this.searchValue = params['searchValue'];
          this.loadData(this.searchValue);
        } else {
          this.loadData('null');
        }
      });
  }

  search(): void {
  }
  errorMessage(error) {
    if (error && error.error) {
      console.log(error);
      console.log(error.error[''][0]);
      this.loadingData = false;
    }
  }

  loadData(value: string) {
    this.loadingData = true;
    this.moduleService.get(value)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
      console.clear();
    },
    error => this.errorMessage(error));
  }

  deleteItem( id: string ) {
    this.moduleService.delete( id )
        .subscribe(success => {
          this.loadData('null');
        }
        , error => this.errorMessage(error));
  }

  searchModule() {
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search );
  }

  agregarModule() {
    const text = this.searchValue;
    this.router.navigate( ['configuration/modules/module', '', text] );
  }

  showConfirm( id: string, name: string ) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory( AlertComponent );

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<AlertComponent>componentRef.instance).title = 'Alerta';
    (<AlertComponent>componentRef.instance).message = `Seguro que desea eliminar el módulo ${ name }`;
    (<AlertComponent>componentRef.instance).open();
    (<AlertComponent>componentRef.instance).alertOutput
      .subscribe(success => {
        this.deleteItem( id );
      }, error => this.errorMessage(error) );
  }

}
