import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TeacherStatusInterface } from '../interfaces/teacher-status.interface';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class TeacherStatusService {
  private apiURL = 'api/Catalogs/TeacherStatus';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

  create(status: TeacherStatusInterface): Observable<any> {
    return this.http.post<any>(this.apiURL + '/Create', status);
  }

  get(searchValue: string) {
    return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
  }

  getById(id: string) {
    return this.http.get<any>(this.apiURL + '/' + id);
  }

  update(id: string, status: TeacherStatusInterface) {
    return this.http.put<any>(this.apiURL + '/' + id, status);
  }

  delete(id: string) {
    return this.http.delete<any>(this.apiURL + '/' + id);
  }
}
