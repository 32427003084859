<article class="content items-list-page">
  <div class="title-search-block">
    <div class="title-block">
      <div class="row">
        <div class="col-md-6">
          <h3 class="title">
            <h3 class="title">Ahorros</h3>
          </h3>
        </div>
        <!-- <div *ngIf="dataReport.length !== 0" class="col-md-6 text-right">
                    <button type="button" class="btn btn-outline-info" (click)="getPDF()"><i class="far fa-file-pdf"></i> PDF</button>
                </div> -->
      </div>
    </div>
  </div>

  <app-not-data *ngIf="!loadingData && dataReport.length === 0"></app-not-data>

  <app-loading-data *ngIf="loadingData"></app-loading-data>

  <app-error-message
    *ngIf="errorMessage"
    [message]="errorMessage"
  ></app-error-message>

  <div
    class="card items animated fadeIn fast"
    *ngIf="!loadingData && dataReport.length"
  >
    <div *ngIf="teacherName" class="row">
      <div class="col-12 text-center">
        <h2 class="m-1">{{ teacherName }}</h2>
      </div>
    </div>

    <di class="row">
      <div class="col-12">
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
          <p>El interés está calculado al 5% anual para el caso de jubilación o defunción; para el caso de baja voluntaria se recalcula el interés al 4% de acuerdo a lo establecido en la cláusula sexta del capítulo II del CONTRATO DE ADHESIÓN.</p>
          <p>Para aclaraciones favor de comunicarse a los teléfonos (614) 4364512, (614) 4364513 y (614) 4432507 o al correo electrónico: focaps2016@hotmail.com y/o pasar a nuestras oficinas ubicadas en ave. Homero #344 col. Revolución 3er piso.</p>
          <!-- <strong>Holy guacamole!</strong> You should check in on some of those fields below. -->
          <!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
        </div>
      </div>
    </di>

    <div class="title-search-block">
      <div class="title-block">
        <div class="row">
          <div class="col-md-6">
            <h3 class="title">
              <h3 class="title">Detalle de Ahorros</h3>
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div
    class="card items animated fadeIn fast"
    *ngIf="!loadingData && dataReport.length > 0"
    >
    <table *ngIf="detailed && dataReport.length" class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Año</th>
          <th scope="col">Qna 1<br />Qna 13</th>
          <th scope="col">Qna 2<br />Qna 14</th>
          <th scope="col">Qna 3<br />Qna 15</th>
          <th scope="col">Qna 4<br />Qna 16</th>
          <th scope="col">Qna 5<br />Qna 17</th>
          <th scope="col">Qna 6<br />Qna 18</th>
          <th scope="col">Qna 7<br />Qna 19</th>
          <th scope="col">Qna 8<br />Qna 20</th>
          <th scope="col">Qna 9<br />Qna 21</th>
          <th scope="col">Qna 10<br />Qna 22</th>
          <th scope="col">Qna 11<br />Qna 23</th>
          <th scope="col">Qna 12<br />Qna 24</th>
          <th scope="col">Subtotal<br />Interes</th>
        </tr>
      </thead>

      <tbody>
        <tr class="text-right" *ngFor="let item of dataReport; let i = index">
          <td>{{ item.year }}</td>
          <td>{{ item.qna_1 | currency }}<br />{{ item.qna_13 | currency }}</td>
          <td>{{ item.qna_2 | currency }}<br />{{ item.qna_14 | currency }}</td>
          <td>{{ item.qna_3 | currency }}<br />{{ item.qna_15 | currency }}</td>
          <td>{{ item.qna_4 | currency }}<br />{{ item.qna_16 | currency }}</td>
          <td>{{ item.qna_5 | currency }}<br />{{ item.qna_17 | currency }}</td>
          <td>{{ item.qna_6 | currency }}<br />{{ item.qna_18 | currency }}</td>
          <td>{{ item.qna_7 | currency }}<br />{{ item.qna_19 | currency }}</td>
          <td>{{ item.qna_8 | currency }}<br />{{ item.qna_20 | currency }}</td>
          <td>{{ item.qna_9 | currency }}<br />{{ item.qna_21 | currency }}</td>
          <td>
            {{ item.qna_10 | currency }}<br />{{ item.qna_22 | currency }}
          </td>
          <td>
            {{ item.qna_11 | currency }}<br />{{ item.qna_23 | currency }}
          </td>
          <td>
            {{ item.qna_12 | currency }}<br />{{ item.qna_24 | currency }}
          </td>
          <td>
            {{ item.total | currency }}<br />{{ item.interests | currency }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row">
    <div class="col-12 text-right">
      <h3 class="m-3 info" *ngIf="!detailed">
        Total {{ amount | currency }}
      </h3>
      <h3 class="m-3 info" *ngIf="detailed">
        Subtotal {{ amount | currency }}
      </h3>
      <h3 class="m-3 info" *ngIf="detailed">
        Interes {{ amountInterests | currency }}
      </h3>
      <h3 class="m-3 info" *ngIf="detailed">
        Total {{ amount + amountInterests | currency }}
      </h3>
    </div>
  </div>
</div>
</article>
