<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3 class="title">
                        Pendientes <small *ngIf="!loadingData">( {{ jsonArray.length }} )</small>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!loadingData && jsonArray.length === 0"></app-not-data>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card items animated fadeIn fast" *ngIf="!loadingData && jsonArray.length > 0">
        <ul class="item-list striped">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-header">
                        <div class="no-overflow">
                            <span>Nombre</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header item-col-header">
                        <div>
                            <span>R.F.C.</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Ahorros</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Interes</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Prestamos</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header">
                        <div>
                            <span>Monto</span>
                        </div>
                    </div>
                    <div class="item-col item-col-header fixed item-col-actions-dropdown"></div>
                </div>
            </li>

            <li class="item" *ngFor="let item of jsonArray | angularPaginator: {itemsPerPage: itemsPerPage, currentPage: currentPage }; let i = index">
                <div class="item-row">
                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Nombre</div>
                        <div>
                            <span>{{ item.teacher.name }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">R.F.C.</div>
                        <div>
                            <span>{{ item.teacher.rfc }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Ahorro</div>
                        <div>
                            <span>{{ item.amountSavings | currency }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Interes</div>
                        <div>
                            <span>{{ item.amountInterests | currency }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Prestamos</div>
                        <div>
                            <span>{{ item.teacher.credit | currency }}</span>
                        </div>
                    </div>

                    <div class="item-col item-col-item">
                        <div class="item-heading">Monto</div>
                        <div>
                            <span>{{ item.amountSavings + item.amountInterests - item.teacher.credit | currency }}</span>
                        </div>
                    </div>

                    <div class="item-col fixed item-col-actions-dropdown">
                        <div class="item-list-actions text-center">
                            <a class="required" (click)="showConfirm( item.id, item )" href="" data-toggle="modal">
                                <i class="far fa-trash-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <div class="item-list">
            <div class="item">
                <div class="form-group text-center m-t-10">
                    <button [disabled]="working" class="btn btn-outline-success" (click)="generateCheck()">
                        <i [ngClass]="{ 'far fa-save': !working, 'fas fa-sync-alt fa-spin': working }"></i>
                        <span *ngIf="!working">Guardar</span>
                        <span *ngIf="working">Guardando...</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="item-list" *ngIf="errorMessage">
            <div class="item">
                <div class="form-group text-center m-t-10">
                    <app-error-message></app-error-message>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <a class="btn btn-outline-info " [routerLink]="['administration/lowstaffing', searchValue] "><i class="fas fa-arrow-left "></i> Regresar</a>
    </div>

    <app-error-message *ngIf="errorMessage"></app-error-message>

    <ng-template appOpen></ng-template>

</article>