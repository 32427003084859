import { Component, OnInit, ViewChild } from '@angular/core';
import { BulkLoadService } from './services/bulk-load.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AccountService } from '../../account/login/services/account.service';
declare let jsPDF;

@Component({
  selector: 'app-bulk-load',
  templateUrl: './bulk-load.component.html',
  styles: []
})
export class BulkLoadComponent implements OnInit {
  forma: UntypedFormGroup;
  errorMessage: string;
  gettingData = false;
  allowReport = false;
  filesToUpload: Array<File>;
  selectedFileNames: string[] = [];
  years: number[] = [];
  fortnights: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  isLoadingData = false;
  @ViewChild('fileUpload', { static: true }) fileUploadVar: any;

  uploadResult: any;
  res: Array<string>;

  constructor( private accountService: AccountService, private bulkLoadService: BulkLoadService,
    private formBuilder: UntypedFormBuilder ) {}

  ngOnInit() {
    this.gettingData = true;
    this.getYears();
    this.forma = this.formBuilder.group({
      year: [new Date().getFullYear()],
      fortnight: [this.getNumberFortnight()],
      document: ['Ahorros'],
    });
    this.bulkLoadService.allowReport()
      .subscribe(allow => {
        this.allowReport = allow;
        this.gettingData = false;
      }, error => this.errorMessage = error.error);
  }

  getPDF(): void {
    this.bulkLoadService.getDataReport()
      .subscribe(dataReport => {
        const totalPagesExp = '{total_pages_count_string}';
        const doc = new jsPDF();
        const head = [
          { fileName: 'Archivo', rfc: 'RFC', message: 'Mensaje' }
        ];
        const body = this.setBodyData(dataReport);

        const pageContent = function (data) {
          // HEADER
          doc.setFontSize(20);
          doc.setTextColor(40);
          doc.setFontStyle('normal');
          // if (base64Img) {
          //    doc.addImage(base64Img, 'JPEG', data.settings.margin.left, 15, 10, 10);
          // }
          doc.text('Reporte de carga masiva', data.settings.margin.left + 15, 22);

          // FOOTER
          let str = 'Página ' + data.pageCount;
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' de ' + totalPagesExp;
          }
          doc.setFontSize(10);
          doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
        };
        doc.autoTable({
          head: head,
          body: body,
          didDrawPage: pageContent,
          margin: { top: 30 }
        });

        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }

        doc.save('ReporteCargaMasiva.pdf');
      }, error => this.errorMessage = error.error);
  }

  setBodyData(data: any[]): any[] {
    const body = [];
    data.forEach(item => {
      body.push({
        fileName: item.fileName,
        rfc: item.rfc,
        message: item.message
      });
    });
    return body;
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while ( findingFortnight === true );
    return numberFortnight.toString();
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
    } else {
      date.setDate( date.getDate() + 14 );
    }
  }

  fileChangeEvent(fileInput: any) {
    this.uploadResult = '';
    this.filesToUpload = <Array<File>>fileInput.target.files;
    for (let i = 0; i < this.filesToUpload.length; i++) {
      this.selectedFileNames.push(this.filesToUpload[i].name);
    }
  }

  validExcelFile( fileName: string ): boolean {
    if ( fileName.slice(-3).toUpperCase() === 'XLS' || fileName.slice(-4).toUpperCase() === 'XLSX' ) {
      return true;
    } else {
      return false;
    }
  }

  reserForm() {
    this.filesToUpload = [];
    this.fileUploadVar.nativeElement.value = '';
    this.selectedFileNames = [];
    this.uploadResult = '';
    this.errorMessage = '';
    this.isLoadingData = false;
  }

  getYears(): void {
    for (let index = new Date().getFullYear() - 5; index < new Date().getFullYear() + 5; index++) {
      this.years.push(index);
    }
  }

  upload() {
    if (this.filesToUpload.length > 3) {
      alert('Por favor solo seleccione un máximo de 3 archivos de excel.');
    } else {
      if (this.validateExcelSelectedOnly(this.selectedFileNames)) {
        this.uploadFiles();
      }
    }
  }

  validateExcelSelectedOnly(filesSelected: string[]) {
    for (let i = 0; i < filesSelected.length; i++) {
      if (!this.validExcelFile(filesSelected[i])) {
        alert('Por favor seleccione solo archivos de excel.');
        return false;
      } else {
        return true;
      }
    }
  }

  uploadFiles() {
    this.isLoadingData = true;
    this.uploadResult = '';

    if (this.filesToUpload.length > 0) {
      const formData: FormData = new FormData();
      formData.append(
        'year',
        this.forma.controls['year'].value
      );

      formData.append(
        'fortnight',
        this.forma.controls['fortnight'].value
      );

      formData.append(
        'userId',
        this.accountService.getUserId()
      );

      formData.append(
        'document',
        this.forma.controls['document'].value
      );

      for (let i = 0; i < this.filesToUpload.length; i++) {
        formData.append(
          'uploadedFiles',
          this.filesToUpload[i],
          this.filesToUpload[i].name
        );
      }

      this.bulkLoadService
        .uploadFiles(formData)
        .subscribe(
          event => {
            this.selectedFileNames = [];
            this.uploadResult = event.result.m_StringValue;
            this.isLoadingData = false;
            this.bulkLoadService.allowReport()
            .subscribe(allow => {
              this.allowReport = allow;
            }, error => this.errorMessage = error.error);
          },
          error => {
            console.error(error);
            this.errorMessage = error.error;
            this.isLoadingData = false;
          }
        );
    }
  }
}
