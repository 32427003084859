import { Injectable, Inject } from '@angular/core';
import { LowStaffInterfaces } from '../interfaces/low-staff.interfaces';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LowStaffService {
  private apiURL = 'api/Administration/LowStaffing';

  constructor( private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string ) { }

  get( searchValue: string ) {
    return this.http.get<any>(this.apiURL + '/Search/' + searchValue);
  }

  getPending(  ) {
    return this.http.get<any>(this.apiURL + '/Pending');
  }

  create(lowStaff: LowStaffInterfaces): Observable<any> {
    return this.http.post<any>(this.apiURL + '/Create', lowStaff);
  }

  apply() {
    return this.http.get<any>(this.apiURL + '/Apply');
  }

  delete( id: string ) {
    return this.http.delete<any>( this.apiURL + '/' + id );
  }

  deactivate(id: string) {
    // return this.http.put<any>(this.apiURL + '/' + id);
  }

}
